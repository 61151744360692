import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Row, Col } from "antd";

import contactUs from "../../assets/Doll Web/contactus.webp.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Modal from "../Modal/Modal";
import Form from "../ContactForm/Form";
import googlePlay from "../../assets/googlePLayBlack.webp";
import applePlay from "../../assets/appStoreBlack.webp";
// Import for Language translater
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
export default function Contact() {
  let navigate = useNavigate();
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  const [isOpen, setisOpen] = useState(false);
  const [isOpenForm, setisOpenForm] = useState(false);
  const [isOpenForm2, setisOpenForm2] = useState(false);
  const handleClose = () => {
    setisOpen(false);
  };
  const handleClose2 = () => {
    setisOpen(false);
  };
  const onCloseForm = () => {
    setisOpenForm(false);
  };
  const onCloseForm2 = () => {
    setisOpenForm2(false);
  };
  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, []);
  return (
    <>
      <div style={{ marginTop: "64px" }} className="bannerImg">
        <Row className="align-items-center justify-content-around px-5 width-100">
          <Col sm={12} lg={12}>
            <h3
              class="font-55 font-700 sansita-black mb-1"
              style={{ color: "#0066CC" }}
            >
              {t("KnowMoreTitle")}
            </h3>
            <br />
            <p className="mb-0">
              {t("QueryLink")}:&nbsp;
              <u>
                <i>
                  <a href="https://ezygo.no/contact" className="text-black">
                    {t("SubmitButton")}
                  </a>{" "}
                </i>
              </u>
            </p>
            <br />
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <img
                  src={googlePlay}
                  width="100%"
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.omvadata.treedriveuser&pli=1",
                      "_blank"
                    )
                  }
                  style={{ cursor: "pointer" }}
                  alt="Google Play Store"
                />
              </Col>
              <Col span={8}>
                <img
                  src={applePlay}
                  width="100%"
                  onClick={() =>
                    window.open(
                      "https://apps.apple.com/in/app/ai-ezygo/id6444897318",
                      "_blank"
                    )
                  }
                  style={{ cursor: "pointer" }}
                  alt="Apple App Store"
                />
              </Col>
              <Col span={24}>
                {" "}
                <p className="mb-0">{t("DownloadOurApp")}</p>
              </Col>
            </Row>
            <br />
            <br />
            <div>
              <a href="/privacy" className="footerLink text-black font-20">
                {t("PrivacyPolicyText")}
              </a>{" "}
              |{" "}
              <a href="/terms" className="footerLink text-black font-20">
                {t("GeneralText")}
              </a>
            </div>
            <div>
              <a
                href="/salesAndCancellation"
                className="footerLink text-black font-20"
              >
                {t("SaleText")}
              </a>{" "}
              |{" "}
              <a href="/scamAlert" className="footerLink text-black font-20">
                {t("FraudAlertText")}
              </a>{" "}
              |{" "}
              <a href="/disclaimer" className="footerLink text-black font-20">
                {t("DisclaimerText")}
              </a>
            </div>
            <br />
            <p className="mb-0">
              {t("VisitText")}:{" "}
              <u>
                <i>
                  {" "}
                  <a href="https://ezygo.no/" className="text-black">
                    {t("EzyGoShopLink")}
                  </a>
                </i>
              </u>
            </p>
          </Col>{" "}
          <Col sm={12} lg={8}>
            <img src={contactUs} alt="contactUs" width={"100%"} />
          </Col>
        </Row>
      </div>
      <Modal isOpen={isOpen} handleClose={handleClose} />{" "}
      {isOpenForm && <Form onCloseForm={onCloseForm} />}
      {isOpenForm2 && <Form onCloseForm2={onCloseForm2} />}
    </>
  );
}
