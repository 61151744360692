import {
  loginStart,
  loginSuccess,
  loginFailure
} from "../slices/managerslices";
import { apiUrl } from "../../helper/endpoint";
import { message } from "antd";
import {
  fetchCategoriesStart,
  fetchCategoriesSuccess,
  fetchCategoriesFailure
} from "../slices/aBoardProductSlice";

export const managerLogin = (email, password) => {
  return async (dispatch) => {
    dispatch(loginStart());

    try {
      const response = await fetch(`${apiUrl}/api/v1/employee/auth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username: email, password })
      });

      const res = await response.json();
      console.log("res in managerAction", res.data);
      if (!response.ok || !res.data?.token) {
        throw new Error(res.message || "Login failed");
      }

      const { token, info } = res.data;

      dispatch(loginSuccess({ token, info }));
      return Promise.resolve({ token, info });
    } catch (error) {
      dispatch(loginFailure(error.message || "Something went wrong"));
      return Promise.reject(error);
    }
  };
};

export const fetchManagerDetails = (token) => async (dispatch) => {
  try {
    const response = await fetch(`${apiUrl}/api/v1/employee/profile/details`, {
      method: "GET",
      headers: { "X-Auth-Token": token, "Content-Type": "application/json" }
    });
    const res = await response.json();
    console.log("API Response - Branch List:", res.data.branchList);

    dispatch({
      type: "FETCH_MANAGER_DETAILS_SUCCESS",
      payload: res.data.branchList
    });
    localStorage.setItem("fullBranchList", JSON.stringify(res.data.branchList));
  } catch (error) {
    console.error("Error fetching details:", error);
  }
};

export const switchManagerBranch =
  (branchId, managerId, token) => async (dispatch) => {
    try {
      const response = await fetch(`${apiUrl}/api/v1/employee/profile/switch`, {
        method: "POST",
        headers: {
          "X-Auth-Token": token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          branchId,
          id: branchId // both will be same
        })
      });
      const res = await response.json();
      message.success(res);
      dispatch({ type: "SWITCH_BRANCH_SUCCESS", payload: res.data.branch });
    } catch (error) {
      message.warning(error);
    }
  };

export const fetchCategories = (token) => async (dispatch) => {
  dispatch(fetchCategoriesStart()); // Start loading

  try {
    const response = await fetch(`${apiUrl}/api/v1/employee/menu/category`, {
      method: "GET",
      headers: {
        "X-Auth-Token": token,
        "Content-Type": "application/json"
      }
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch categories. Status: ${response.status}`);
    }

    const data = await response.json();

    localStorage.setItem("resProduct", JSON.stringify(data.data));
    console.log("Fetched categories data:", data); // <-- Log the response here
    dispatch(fetchCategoriesSuccess(data.data)); // Dispatch success action with fetched data
  } catch (error) {
    console.error("Error fetching categories:", error);
    dispatch(fetchCategoriesFailure(error.message || "Something went wrong")); // Dispatch failure action
  }
};

export const logoutManager = () => (dispatch) => {
  dispatch({ type: "LOGOUT_MANAGER" });
};
