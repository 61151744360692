import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Button, Collapse, Image, Spin, Row } from "antd";
import { useDispatch, useSelector } from "react-redux"; // Import Redux hooks
import { fetchCategories } from "../../../store/actions/managerAction"; // Correct path to your action
import { LeftCircleOutlined } from "@ant-design/icons";
import BottomFooter from "../../Footer/BottomFooter";

const { Content } = Layout;
const { Panel } = Collapse;

const ProductImageUpload = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const managerData = useSelector((state) => state.managerReducer);

  const [storedResponse, setStoredResponse] = useState(null);

  // Fetch categories from Redux store with fallback values
  const { categories = [], loading = false, error = null } = useSelector(
    (state) => state.categoryReducer || {}
  );

  const userToken = managerData?.token;
  const resInfo = managerData?.resInfo;

  // Fetch categories and read from localStorage only once when component mounts
  useEffect(() => {
    let response = localStorage.getItem('resProduct');
    if (response) {
      setStoredResponse(JSON.parse(response));  // Parse the stored response
    }
    console.log("Fetched categories from Redux:", categories); // Logging categories to debug

    // Only dispatch if categories are empty and userToken is available
    if (!categories.length && userToken) {
      console.log("Dispatching fetchCategories action");
      dispatch(fetchCategories(userToken));
    }
  }, [userToken, dispatch]);  // Trigger only on component mount or token change

  // Fetch categories when button is clicked
  const fetchCategoriesHandler = () => {
    if (userToken && !loading) {
      console.log("Dispatching fetchCategories action");
      dispatch(fetchCategories(userToken));
    }
  };

  // Logout functionality
  const handleLogout = () => {
    navigate("/login");
  };

  return (
    <> <Layout>
      <Row className="justify-content-between  my-3 px-3">
        <h3 className="font-24 font-700 mb-0">{resInfo?.name} Categories's List</h3>
        <LeftCircleOutlined
          onClick={() => navigate("/aBoardDashboard")}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "30px"
          }}
        />
      </Row>
      <Content className="px-3" style={{ height: "70vh", overflow: "hidden scroll" }}>
        {/* <h1 className="text-center">{resInfo?.name}</h1>

     <Button
       type="primary"
       className="width-100"
       block
       onClick={fetchCategoriesHandler}
     >
       All Categories
     </Button> */}

        {loading ? (
          <Spin
            size="large"
            style={{ display: "block", textAlign: "center", marginTop: "20px" }}
          />
        ) : error ? (
          <div style={{ textAlign: "center", color: "red" }}>Error: {error}</div>
        ) : (
          <Collapse accordion>
            {storedResponse && storedResponse.map((item) => (
              <Panel
                key={item._id}
                header={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Image
                      src={`https://pos-stockholm-bucket.s3.eu-north-1.amazonaws.com/${item.image}`}
                      width={40}
                    />
                    <span style={{ marginLeft: "20px", fontWeight: "bold" }}>
                      {item.name}
                    </span>
                  </div>
                }
              >
                <p>
                  <b>Gruppert Kode:</b> {item?.superCategory?.name || "N/A"}
                </p>
                <p>
                  <b>MVA:</b> Tax 25
                </p>
                <Button
                  className="width-100"
                  type="primary"
                  block
                  onClick={() =>
                    navigate(`/ViewProducts?catId=${item._id}&catName=${item.name}`)
                  }
                >
                  View Products
                </Button>
              </Panel>
            ))}
          </Collapse>
        )}
      </Content>
    </Layout>
      <BottomFooter /></>
  );
};

export default ProductImageUpload;
