import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  barbers: [],
  products: [],
  services: []
};

const salonCartSlice = createSlice({
  name: "salonCart",
  initialState,
  reducers: {
    addServiceToCart: (state, action) => {
      state.services.push(action.payload);
      state.totalPrice += action.payload.productPrice * action.payload.quantity;
      state.subTotal = state.totalPrice / (1 + state.tax / 100);
    },
    addProductToCart: (state, action) => {
      state.products.push(action.payload);
      state.totalPrice += action.payload.productPrice * action.payload.quantity;
      state.subTotal = state.totalPrice / (1 + state.tax / 100);
    },
    removeServiceFromCart: (state, action) => {
      state.services = state.services.filter(
        (item) => item.id !== action.payload.id
      );
      state.totalPrice -= action.payload.productPrice * action.payload.quantity;
      state.subTotal = state.totalPrice / (1 + state.tax / 100);
    },
    removeProductFromCart: (state, action) => {
      state.products = state.products.filter(
        (item) => item.id !== action.payload.id
      );
      state.totalPrice -= action.payload.productPrice * action.payload.quantity;
      state.subTotal = state.totalPrice / (1 + state.tax / 100);
    },
    clearSalonCart: (state) => {
      state.services = [];
      state.products = [];
      state.totalPrice = 0;
      state.subTotal = 0;
      state.tax = 0;
    },
  }
});

export const {
  addServiceToCart,
  addProductToCart,
  removeServiceFromCart,
  removeProductFromCart,
  clearSalonCart,
} = salonCartSlice.actions;

export default salonCartSlice.reducer;
