import React from "react";
import { Row, Col, Card } from "antd";
import { useNavigate } from "react-router";
import { isMobile } from "react-device-detect";
import { EnvironmentFilled } from "@ant-design/icons";
import barberLogo from "../../assets/logo.webp";
import BottomFooter from "../Footer/BottomFooter";

const SalonList = () => {
  let navigate = useNavigate();
  return (
    <>
      <Row style={{ marginTop: "64px" }} className="p-3">
        {isMobile && (
          <Col span={24} className="mobileCardRest mb-3 position-relative ">
            {" "}
            <Card
              className="border-radius-10 restCard p-2"
              disabled={true}
              title=""
              bordered={false}
              onClick={() => navigate("/salonAppointment")}
            >
              <Row>
                <Col span={8}>
                  {" "}
                  <img
                    alt="barberLogo"
                    src={barberLogo}
                    className="width-100 height-100"
                    style={{ borderRadius: "10px 0 0 10px " }}
                  />
                </Col>
                <Col span={14} className="pt-3 pb-2">
                  <div
                    className="productDescription"
                    style={{ paddingLeft: "10px" }}
                  >
                    <div className="title">
                      <h3 className="font-bold mb-0 d-flex font-20 align-items-center ">
                        Fredrikstad Barbershop
                      </h3>
                      <p className="mb-0"></p>
                      <p className="d-flex align-items-end  font-400 mb-0">
                        <EnvironmentFilled />
                        Brogata 12, 1608 Fredrikstad
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        )}
      </Row>
      <BottomFooter />
    </>
  );
};

export default SalonList;
