// import React, { useEffect, useState } from "react";
// import { Card, Row, Col, Descriptions, Divider } from "antd";
// import { AiOutlineCheckCircle, AiOutlineClose } from "react-icons/ai";
// import { NavLink, useSearchParams } from "react-router-dom";
// import { checkOrderStatus } from "../../store/actions/orderAction";
// import { useDispatch, useSelector } from "react-redux";

// // Import for Language translater
// import { useTranslation } from "react-i18next";
// export const SalonPaymentSummary = () => {
//   //For Language Translater
//   const { t, i18n } = useTranslation(); // Hook to access translations

//   const [searchParams] = useSearchParams();
//   let root = useSelector((state) => state.rootReducer);
//   let dispatch = useDispatch();

//   let productFinal = root.orderDetails?.items;
//   let [stateLoading, setStateLoading] = useState(true);
//   let [paymentDone, setPaymentDone] = useState(false);
//   let [resWebsite, setResWebsite] = useState("");

//   let paymentType = "Dintero";

//   let responseType = searchParams.get("responseType");
//   let responseCode = searchParams.get("responseCode");
//   let transaction_id = responseType
//     ? searchParams.get("transactionId")
//     : searchParams.get("transaction_id");

//   let transactionObj = {};

//   ///Check if response type is sale & response code is A01
//   ///A01 = success
//   if (responseType && responseCode == "A01") {
//     let card_num = searchParams.get("accountNumberMasked");
//     let expiry = searchParams.get("accountAccessory");

//     paymentType = "TDIPay";

//     transactionObj = {
//       status: "AUTHORIZED",
//       account_id: searchParams.get("accountId"),
//       transaction_id: transaction_id,
//       vendorCode: "47001", ///this should be dynamic
//       vendorName: "TDIPay",
//       card: {
//         masked_pan: card_num,
//         expiry_date: expiry,
//         brand: "Card"
//       }
//     };
//   }
//   let vipps = searchParams.get("vipps");
//   let order_id = searchParams.get("order_id");

//   ///Check for Vipps
//   if (order_id && vipps == "true") {
//     paymentType = "tdVipps";

//     transactionObj = {
//       status: "AUTHORIZED",
//       branch_id: searchParams.get("branch_id"),
//       order_id: searchParams.get("order_id"),
//       vendorCode: searchParams.get("msn"),
//       vendorName: "tdVipps"
//     };
//   }

//   useEffect(() => {
//     let timer = setTimeout(() => {
//       if (root.orderId && transaction_id) {
//         dispatch(
//           checkOrderStatus(
//             root.orderId,
//             transaction_id,
//             paymentType,
//             transactionObj
//           )
//         )
//           .then(() => {
//             setStateLoading(false);
//           })
//           .catch(() => {
//             setStateLoading(false);
//           });
//       } else if (!root.orderId && paymentType == "TDIPay" && transaction_id) {
//         dispatch(
//           checkOrderStatus("", transaction_id, paymentType, transactionObj)
//         )
//           .then(() => {
//             setStateLoading(false);
//           })
//           .catch(() => {
//             setStateLoading(false);
//           });
//       } else if (vipps && order_id) {
//         dispatch(checkOrderStatus(order_id, "", "tdVipps", transactionObj))
//           .then(() => {
//             setStateLoading(false);
//           })
//           .catch(() => {
//             setStateLoading(false);
//           });
//       } else {
//         order_id = searchParams.get("merchant_reference");
//         if (order_id) {
//           dispatch(
//             checkOrderStatus(
//               order_id,
//               transaction_id,
//               paymentType,
//               transactionObj
//             )
//           )
//             .then(() => {
//               setStateLoading(false);
//             })
//             .catch(() => {
//               setStateLoading(false);
//             });
//         } else {
//           setStateLoading(false);
//         }
//       }
//     }, 2000);
//     return () => {
//       clearTimeout(timer);
//     };
//   }, []);

//   useEffect(() => {
//     if (root.orderOtp && root.paymentDone) {
//       setPaymentDone(true);
//     }
//   }, [root.orderOtp, root.paymentDone]);

//   const onFinishFailed = (errorInfo) => {};
//   return (
//     <>
//       <div className="formWrapper" id="payment-status">
//         <Row className="formRow mt-5 px-3">
//           <Col span={24}>
//             <div className="site-card-border-less-wrapper">
//               <Card bordered={false} className="border-radius-10">
//                 <div className="formLeft">
//                   {stateLoading && (
//                     <Row>
//                       <Col
//                         span={24}
//                         style={{
//                           textAlign: "center",
//                           padding: "10px",
//                           color: "white"
//                         }}
//                       >
//                         <h3>{t("LoadingWait")}</h3>
//                       </Col>
//                     </Row>
//                   )}
//                   {paymentDone && !stateLoading && (
//                     <>
//                       {resWebsite ? (
//                         <Row className="justify-content-start p-3 flex-column">
//                           <div className="py-3 d-flex justify-content-start align-items-center">
//                             {" "}
//                             <AiOutlineCheckCircle
//                               style={{
//                                 width: "50px",
//                                 height: "50px",
//                                 color: "#0066CC",
//                                 marginRight: "10px"
//                               }}
//                             />
//                             <h4 className="mb-0">{t("SummeryOrderPlaced")}</h4>
//                           </div>
//                           <div>
//                             <p>
//                               <b>
//                                 {t("YourOTP")}: {root.orderOtp}
//                               </b>
//                               .
//                             </p>
//                             <p style={{ fontSize: "14px" }}>
//                               <i>{t("SummaryFoodOrder")}</i>
//                             </p>
//                           </div>
//                           <div className="py-3 goBackBtn">
//                             <div className="py-3 goBackBtn">
//                               <a
//                                 href={resWebsite}
//                                 className="orderBtn width-100 text-center "
//                               >
//                                 {t("SummaryBackWebsitePage")}
//                               </a>
//                             </div>
//                             <div className="py-3 goBackBtn">
//                               <NavLink
//                                 to="/checkout"
//                                 className="orderBtn text-center width-100"
//                               >
//                                 {t("SummaryBackWebsitePage")}
//                               </NavLink>
//                             </div>
//                           </div>
//                         </Row>
//                       ) : (
//                         <Row className="justify-content-start p-3 flex-column">
//                           <div className="py-3 d-flex justify-content-start align-items-center">
//                             {" "}
//                             <AiOutlineCheckCircle
//                               style={{
//                                 width: "50px",
//                                 height: "50px",
//                                 color: "#0066CC",
//                                 marginRight: "10px"
//                               }}
//                             />
//                             <h4 className="mb-0">{t("SummeryOrderPlaced")}</h4>
//                           </div>
//                           <div>
//                             <p>
//                               <b>
//                                 {t("YourOTP")}: {root.orderOtp}
//                               </b>
//                               .
//                             </p>
//                             <p style={{ fontSize: "14px" }}>
//                               <i>{t("SummaryFoodOrder")}</i>
//                             </p>
//                           </div>
//                           <div className="py-3 goBackBtn">
//                             <NavLink to="/home" className="orderBtn">
//                               {t("SummaryExploreMore")}
//                             </NavLink>
//                           </div>
//                         </Row>
//                       )}
//                     </>
//                   )}

//                   {!paymentDone && !stateLoading && (
//                     <>
//                       {resWebsite ? (
//                         <Row className="justify-content-start p-3 flex-column">
//                           <div className="pb-3 d-flex justify-content-start flex-column align-items-center">
//                             <AiOutlineClose
//                               className="height100px width100px"
//                               style={{
//                                 color: "red",
//                                 marginRight: "10px"
//                               }}
//                             />
//                             <h4 className="mb-0">{t("TFLOrderFailed")}</h4>
//                           </div>
//                           <div>
//                             <p
//                               style={{ fontSize: "14px" }}
//                               className="text-center"
//                             >
//                               <i>{t("SummaryOrderFailedText")}</i>
//                             </p>
//                           </div>
//                           <div className="d-flex flex-column justify-content-around">
//                             <div className="py-3 goBackBtn">
//                               <a
//                                 href={resWebsite}
//                                 className="orderBtn width-100 text-center "
//                               >
//                                 {t("SummaryInformation")}
//                               </a>
//                             </div>
//                             <div className="py-3 goBackBtn">
//                               <NavLink
//                                 to="/checkout"
//                                 className="orderBtn text-center width-100"
//                               >
//                                 {t("SummaryBackWebsitePage")}
//                               </NavLink>
//                             </div>
//                           </div>
//                         </Row>
//                       ) : (
//                         <Row className="justify-content-start p-3 flex-column">
//                           <div className="pb-3 d-flex justify-content-start flex-column align-items-center">
//                             <AiOutlineClose
//                               className="height100px width100px"
//                               style={{
//                                 color: "red",
//                                 marginRight: "10px"
//                               }}
//                             />
//                             <h4 className="mb-0">{t("TFLOrderFailed")}</h4>
//                           </div>
//                           <div>
//                             <p
//                               style={{ fontSize: "14px" }}
//                               className="text-center"
//                             >
//                               <i>{t("SummaryOrderFailedText")}</i>
//                             </p>
//                           </div>
//                           <div className="d-flex flex-column justify-content-around">
//                             <NavLink
//                               to="/restaurants"
//                               className="orderBtn text-center"
//                             >
//                               {t("SummaryExploreMore")}
//                             </NavLink>
//                             <br />
//                             <NavLink
//                               to="/checkout"
//                               className="orderBtn text-center"
//                             >
//                               {t("SummaryBackWebsitePage")}
//                             </NavLink>
//                           </div>
//                         </Row>
//                       )}
//                     </>
//                   )}
//                 </div>
//               </Card>
//             </div>
//           </Col>
//         </Row>
//       </div>
//     </>
//   );
// };
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Row, Col, Descriptions, Table } from "antd";
import { AiOutlineCheckCircle, AiOutlineClose } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { apiUrl } from "../../helper/endpoint";
// Import for Language translater
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { clearSalonCart } from "../../store/slices/salonSlices"; // Import Redux action

export const SalonPaymentSummary = () => {
  const dispatch = useDispatch(); // Redux dispatch hook
  //For Language Translater
  const { t } = useTranslation();
  const paramUrl = window.location.href;
  const encUrl = new URL(paramUrl);
  const params = new URLSearchParams(encUrl.search);

  const orderId = params.get("orderId");
  let merchant_reference = params.get("merchant_reference");
  let transaction_id = params.get("transaction_id");
  let [paymentFailed, setPaymentFailed] = useState(false);
  let [resData, setResData] = useState("");
  let [productsData, setProductsData] = useState([]);

  const checkVendorPayment = async (data) => {
    try {
      let res = await axios.post(
        `${apiUrl}/api/v1/barber/order/orderPaymentCheckBarber`,
        data
      );
      if (res && res.status === 200) {
        setResData(res.data.data);
        setProductsData(res.data.data.items);
        setPaymentFailed(false);
      } else {
        setPaymentFailed(true);
      }
    } catch (error) {
      setPaymentFailed(true);
    }
  };

  const deleteDataFromLocal = () => {
    dispatch(clearSalonCart()); // Redux action call
    deleteDataFromLocal(); // Local storage clear
  };

  useEffect(() => {
    let data = {
      id: merchant_reference,
      transaction_id
    };
    checkVendorPayment(data);
  }, []);

  const OrderColumns = () => [
    {
      title: "Product",
      width: "37%",
      key: "name",
      dataIndex: "name"
    },
    {
      title: "Total Quantity",
      width: "37%",
      key: "quantity",
      render: (text, record) => {
        return record.availQuantity
          ? `${record.quantity}pkt X ${record.availQuantity}${record.unitType}`
          : `${record.quantity}`;
      }
    },
    {
      title: `Amount(Kr.) `,
      width: "26%",
      key: "name",
      render: (text, record) => {
        return `Kr.${record.totalAmount}`;
      }
    }
  ];

  return (
    <div className="formWrapper pt-5" id="vendoStatus">
      <Col span={12}>
        <Card bordered={false} className="cardContainer">
          {!paymentFailed ? (
            <Row className="justify-content-center p-3 flex-column">
              <div className="py-3 d-flex justify-content-center align-items-center">
                <AiOutlineCheckCircle
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#0066CC",
                    marginRight: "10px"
                  }}
                />
                {/* <h4 className="text-white mb-0">{t("SummeryOrderPlaced")}</h4> */}
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <p>
                  <b>
                    {t("YourOTP")}: {resData.otp}
                  </b>
                  .
                </p>
                {/* <Descriptions
                  title={t("SummaryOrderInformation")}
                  className="mt-3"
                />
                <Table
                  id="tablePrint"
                  columns={OrderColumns()}
                  dataSource={productsData}
                  striped
                  bordered
                  hover
                />
                <Descriptions className="pt-5">
                  <Descriptions.Item label="Total">
                    <b> Kr. {resData.totalAmount}</b>
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions title="User Info" className="mt-3">
                  <Descriptions.Item label="Address">
                    {resData?.customerDetail?.address}
                  </Descriptions.Item>
                </Descriptions> */}
              </div>
              <div className="py-3 goBackBtn">
                <NavLink
                  className="orderBtn"
                  to="/home"
                  onClick={deleteDataFromLocal}
                >
                  Home
                </NavLink>
              </div>
            </Row>
          ) : (
            <Row className="justify-content-start p-3 flex-column">
              <div className="py-3 d-flex justify-content-start flex-column align-items-center">
                <AiOutlineClose
                  className="height100px width100px"
                  style={{ color: "red", marginRight: "10px" }}
                />
                <h4 className="mb-0">{t("TFLOrderFailed")}</h4>
              </div>
              <Row className="py-3 goBackBtn">
                <NavLink
                  to="/salonAppointment"
                  className="orderBtn width-100 text-center"
                >
                  {t("TFLOrderBack")}
                </NavLink>
                <NavLink
                  to="/home"
                  className="orderBtn width-100 text-center mt-3"
                  onClick={deleteDataFromLocal}
                >
                  {" "}
                  Home
                </NavLink>
              </Row>
            </Row>
          )}
        </Card>
      </Col>
    </div>
  );
};
