import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Row, Col, Select, message } from "antd";
import {
  switchManagerBranch,
  fetchManagerDetails
} from "../../../store/actions/managerAction.js";
import { logout } from "../../../store/slices/managerslices.js";
import { Outlet } from "react-router-dom";

export default function ManagerNav() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [filteredBranches, setFilteredBranches] = useState([]);

  const managerData = useSelector((state) => state.managerReducer);
  const branchListFull = managerData?.info?.branchList || [];
  const [selectedBranch, setSelectedBranch] = useState("");
  useEffect(() => {
    const fullBranchList = localStorage.getItem("fullBranchList");
    const parsedData = fullBranchList ? JSON.parse(fullBranchList) : [];

    // ❌ Remove null branches
    const validBranches = parsedData.filter(
      (item) => item.branch && item.branch !== null
    );

    // ✅ Remove duplicates based on `_id`
    const uniqueBranches = Array.from(
      new Map(
        validBranches.map((branch) => [branch.branch._id, branch])
      ).values()
    );

    setFilteredBranches(uniqueBranches);
    console.log("Filtered Unique Branch List:", uniqueBranches);

    if (uniqueBranches.length > 0) {
      setSelectedBranch(uniqueBranches[0]?.branch?._id || ""); // ✅ Default first valid branch
    }
  }, []);
  useEffect(() => {
    console.log("filteredBranches List from LocalStorage:", filteredBranches);
  }, [filteredBranches]); // ✅ Jab bhi filteredBranches update hoga, yeh chalega

  // ✅ Redux se Manager Details Fetch Karo
  useEffect(() => {
    if (managerData?.token) {
      dispatch(fetchManagerDetails(managerData?.token));
      console.log("managerData:----------", managerData.info._id);
      console.log("Redux Branch List:", branchListFull);
    }
  }, [dispatch, managerData?.token]);

  // ✅ Switch branch function
  const switchBranch = async (newBranchId) => {
    if (newBranchId === selectedBranch) return;

    try {
      await dispatch(
        switchManagerBranch(
          newBranchId,
          // selectedBranch,
          managerData?.info?._id,
          managerData?.token // ✅ Fix: Correct token use kiya
        )
      );
      localStorage.setItem("aBoardResName", managerData?.info?.name);
      message.success("Branch switched successfully!");
      setSelectedBranch(newBranchId); // ✅ State update
      navigate("/aboardDashboard");
    } catch (error) {
      console.error("Branch switch error:", error);
      message.error("Failed to switch branch!");
    }
  };

  // ✅ Logout function
  const handleLogout = () => {
    dispatch(logout());
    setTimeout(() => navigate("/managerLogin"), 100);
  };

  return (
    <div>
      <header>
        <Row className="px-3 pt-2" gutter={[16, 16]}>
          <Col span={16}>
            <Select
              className="width-100"
              showSearch
              placeholder="Select a branch"
              optionFilterProp="children"
              value={selectedBranch || filteredBranches[0]?.branch?._id}
              onChange={switchBranch}
              // onChange={(newBranchId) => setSelectedBranch(newBranchId)}
              filterOption={(input, option) =>
                option?.label?.toLowerCase().includes(input.toLowerCase())
              }
              options={filteredBranches.map((branch) => ({
                key: branch.branch._id, // ✅ Unique Key Added
                value: branch.branch._id,
                label: branch.branch.name
              }))}
            />
          </Col>
          <Col span={8} className="text-right">
            <button className="btn btn-danger" onClick={handleLogout}>
              {t("ProfileLogoutButton")}
            </button>
          </Col>
        </Row>
      </header>
      <main>
        <Outlet /> {/* ✅ Child Routes Render Karega */}
      </main>
    </div>
  );
}
