import { Row, Col, Divider } from "antd";
import { useTranslation } from "react-i18next";

const TotalTaxCalculatin = (props) => {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations
  return (
    <>
      <Divider plain className="pt-3">
        {t("TotalBillDetails")}
      </Divider>
      <Row>
        <Col span={12}>
          <h6 className="itemTotal">
            {t("TotalSubtotal")}
          </h6>
        </Col>
        <Col span={12} className="text-right">
          <h6 className="itemTotal billText">
            {(
              props.cart.totalPrice -
              (props.cart.totalPrice -
                (100 * props.cart.totalPrice) / (100 + 15))
            ).toFixed(2)}
          </h6>
        </Col>
      </Row>

      <Row className="pt-1">
        <Col span={12}>
          <h6 className="itemTotal">
            {t("TotalTaxTitle")}
          </h6>
        </Col>
        <Col span={12} className="text-right">
          <h6 className="itemTotal billText">
            {(
              props.cart.totalPrice -
              (100 * props.cart.totalPrice) / (100 + 15)
            ).toFixed(2)}
          </h6>
        </Col>
      </Row>

      {props.cart.discountPrice === 0 && props.cart.discountPercent === 0 ? (
        <></>
      ) : (
        <Row className="pt-1">
          <Col span={12}>
            <h6 className="itemTotal">
              {t("TotalDiscount")}
              ({props.cart.discountPercent + "%"})
            </h6>
          </Col>
          <Col span={12} className="text-right">
            <h6 className="itemTotal billText">
              - {props.cart.discountPrice.toFixed(2)}
            </h6>
          </Col>
        </Row>
      )}

      <Row className="pt-1">
        <Col span={12}>
          <h6 className="itemTotal mb-0">Total</h6>
        </Col>
        <Col span={12} className="text-right">
          <h6 className="itemTotal mb-0 billText">
            {(props.cart.totalPrice - props.cart.discountPrice).toFixed(2)}
          </h6>
        </Col>
      </Row>
    </>
  );
};

export default TotalTaxCalculatin;
