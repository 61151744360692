import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: "",
  info: {},
  loading: false,
  error: null
};

const managerSlice = createSlice({
  name: "manager",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      console.log("Login Success:", action.payload); // ✅ Debugging ke liye
      state.token = action.payload.token;
      state.info = action.payload.info; // ✅ Sirf required fields store honge
      state.loading = false;
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    logout: (state) => {
      // Reset the state directly to initialState values
      state.token = "";
      state.info = {};
      state.loading = false;
      state.error = null;
    },
  }
});

export const { loginStart, loginSuccess, loginFailure, logout } =
  managerSlice.actions;
export default managerSlice.reducer;
