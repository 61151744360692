import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Typography,
  Row,
  Col,
  Select,
  message
} from "antd";
import { InputGroup } from "react-bootstrap";
import { BsClock } from "react-icons/bs";
import { CalendarOutlined, LeftCircleOutlined } from "@ant-design/icons";
import BottomFooter from "../Footer/BottomFooter";
import { isMobile } from "react-device-detect";
import moment from "moment";
import "./form.css";
import { apiUrl } from "../../helper/endpoint";

// Import for Language translater
import { useTranslation } from "react-i18next";

const { Option } = Select;
const countryCodes = [
  { code: "NO", dial_code: "+47" },
  { code: "IN", dial_code: "+91" }
];

const GeneralForm = (props) => {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [time, setTime] = useState("");

  const serviceTitle = location.state?.serviceTitle;

  const onFinish = async (values) => {
    const {
      firstName,
      lastName,
      email,
      phone,
      countryCode,
      date,
      queryMessage
    } = values;

    const payload = {
      firstName,
      lastName,
      subject: serviceTitle,
      email,
      countryCode,
      phone,
      date: date.format("DD-MM-YYYY"),
      time: time, // Using the selected time from state
      message: queryMessage,
      platform: isMobile ? "EzyGo IOS" : "EzyGo Web"
    };
    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/treeDrive/treedriveemail/digitalServicesForm`,
        payload
      );

      if (response.status === 200) {
        console.log("response of digitalServicesForm", response);
        message.success(t("GeneralFormSubmit")); // Now this should work
        form.resetFields();
        setTime(""); // Reset time field
        navigate("/digitalServices");
      } else {
        message.error(t("GeneralFormSubmitError"));
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      message.error(t("GeneralFormSubmit")); // This should work now
    }
  };
  const content = () => {
    return (
      <Form
        className="height-auto p-1 border-radius-10"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{ countryCode: "+47" }}
      >
        <Row className="justify-content-between mb-3">
          <Col className="d-flex align-items-center">
            <h3 className="mb-0 font-22">{serviceTitle}</h3>
          </Col>
          <Col>
            <LeftCircleOutlined
              onClick={() => navigate("/digitalServices")}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "30px"
              }}
            />
          </Col>
        </Row>

        <Form.Item
          name="firstName"
          rules={[{ required: true, message: t("GeneralEnterName") }]}
        >
          <Input placeholder={t("QueryFirstName")} size="large" />
        </Form.Item>
        <Form.Item name="lastName">
          <Input placeholder={t("QueryLastName")} size="large" />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: t("ValidName")
            }
          ]}
        >
          <Input placeholder={t("QueryEmail")} size="large" />
        </Form.Item>
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item
              name="countryCode"
              rules={[{ required: true, message: t("CountryCode") }]}
            >
              <Select size="large">
                {countryCodes.map(({ code, dial_code }) => (
                  <Option key={code} value={dial_code}>
                    {`${code} (${dial_code})`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: t("PhoneNumber")
                },
                {
                  pattern: /^[0-9]*$/, // ✅ Only numbers allowed
                  message: t("NumberError")
                },
                {
                  max: 10,
                  message: t("TenDigits")
                }
              ]}
            >
              <Input
                size="large"
                placeholder={t("OrderMobileNumber")}
                maxLength={10}
                onChange={(e) => {
                  const onlyNums = e.target.value.replace(/\D/g, "");
                  form.setFieldsValue({ phone: onlyNums });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Typography.Text>{t("QuerySelectDateTime")}</Typography.Text>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="date">
              <DatePicker
                placeholder={t("SelectDate")}
                className="width-100"
                suffixIcon={<CalendarOutlined />}
                getPopupContainer={(trigger) => trigger.parentElement}
                disabledDate={(current) =>
                  current && current < moment().startOf("day")
                }
                size="large"
                inputReadOnly
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <InputGroup>
              <InputGroup.Text>
                <BsClock />
              </InputGroup.Text>
              <input
                type="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                className="form-control"
              />
            </InputGroup>
          </Col>
        </Row>
        <Form.Item name="queryMessage">
          <Input.TextArea
            placeholder={t("QueryMessage")}
            rows={3}
            size="large"
          />
        </Form.Item>
        <Row justify="space-between" className="mt-3">
          <Button
            type="primary"
            htmlType="submit"
            className="submit-button width-100 border-radius-5"
          >
            {t("QueryButton")}
          </Button>
        </Row>
      </Form>
    );
  };
  return (
    <>
      {isMobile ? (
        <Row className="vh90andScroll p-3">{content()}</Row>
      ) : (
        <div
          style={{ marginTop: "64px" }}
          className="mission profilePages d-flex align-items-center justify-content-center"
        >
          <Row className="align-items-center justify-content-center">
            <Col lg={9} className="commonShadowAndRound my-5 bg-white p-5">
              {content()}
            </Col>
          </Row>
        </div>
      )}
      <BottomFooter />
    </>
  );
};

export default GeneralForm;
