import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Card, Row, Col } from "antd";
import BottomFooter from "../Footer/BottomFooter";
import doll from "../../assets/Doll Web/taraProfie.webp";
import aryan from "../../assets/aryan.webp";
import profileIcon from "../../assets/profileFallBack.webp";
import potali from "../../assets/mobile/profileCoin.webp";
import contactUs from "../../assets/Doll Web/Tara (4).png";
import { s3url } from "../../helper/endpoint";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

export default function ABoard() {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  const navigate = useNavigate();

  let user = useSelector((state) => state.userReducer);
  let userprofileImage = user.profileImage;

  const profileImageSrc =
    userprofileImage == "" ? profileIcon : `${s3url}/${userprofileImage}`;

  useEffect(() => {
    if (!user || !user.token) {
      navigate("/login");
    }
  }, [user]);

  return (
    <>
      {isMobile && (
        <div className="p-3" id="arkivAboard">
          <h1 className="text-center text-white pt-2 pb-5 font-35">
            {t("AboardArchive")}
          </h1>
          <Row gutter={[16, 16]} justify="center" className="mt-2 mb-5 pb-5">
            <Col span={12}>
              <Card
                className="border-radius-10"
                hoverable
                onClick={() => navigate("/profileInAboard")}
                cover={
                  <img
                    style={{
                      border: "1px solid rgb(153, 153, 153)"
                    }}
                    className="border-radius-100 width100px height100px"
                    src={profileImageSrc}
                    alt={`${user.firstName}`}
                    loading="lazy"
                  />
                }
              >
                <p
                  className="font-700 font-14 text-center"
                  style={{ color: "#004AAD" }}
                >
                  {t("AboardPersonalProfile")}
                </p>
              </Card>
            </Col>
            <Col span={12}>
              <Card
                className="border-radius-10"
                hoverable
                onClick={() => navigate("/tflCoinExchange")}
                cover={
                  <img src={potali} className="width100px" loading="lazy" />
                }
              >
                <p
                  className="font-700 font-14 text-center"
                  style={{ color: "#004AAD" }}
                >
                  {t("TFLDigitalCoins")}
                </p>
              </Card>
            </Col>
            <Col span={12}>
              <Card
                className="border-radius-10"
                hoverable
                onClick={() => navigate("/agentProfile")}
                cover={<img src={doll} className="width100px" loading="lazy" />}
              >
                <p
                  className="font-700 font-14 text-center"
                  style={{ color: "#004AAD" }}
                >
                  {t("AboardMobileAgent")}
                </p>
              </Card>
            </Col>
            <Col span={12}>
              <Card
                className="border-radius-10"
                hoverable
                onClick={() => navigate("/managerLogin")}
                cover={
                  <img src={aryan} className="width100px" loading="lazy" />
                }
              >
                <p
                  className="font-700 font-14 text-center"
                  style={{ color: "#004AAD" }}
                >
                  A-Board
                </p>
              </Card>
            </Col>
          </Row>
        </div>
      )}
      {!isMobile && (
        <div
          className="  mb-5 mb-5  mission profilePages d-flex align-items-center justify-content-center"
          style={{ marginTop: "64px" }}
          id="aBoardWebPage"
        >
          <Row className="width-100 align-items-center">
            <Col xl={4} md={1}></Col>
            <Col xl={5} md={6}>
              {" "}
              <img src={contactUs} alt="contactUs" width={"100%"} />
            </Col>
            <Col md={14} xl={12}>
              <h1 className="text-center font-35">{t("AboardArchive")}</h1>
              <Row gutter={[16, 16]} justify="center" align="center">
                <Col span={12}>
                  <Card
                    className="border-radius-10 d-flex align-items-center px-3 py-2"
                    hoverable
                    onClick={() => navigate("/profileInAboard")}
                    cover={
                      <img
                        className="border-radius-100 width100px height100px"
                        src={profileImageSrc}
                        alt={`${user.firstName}`}
                        style={{
                          border: "1px solid rgb(153, 153, 153)"
                        }}
                      />
                    }
                  >
                    <p className="font-700 font-18 mb-0">
                      {t("AboardPersonalProfile")}
                    </p>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card
                    className="border-radius-10 d-flex align-items-center px-3 py-2"
                    hoverable
                    onClick={() => navigate("/tflCoinExchange")}
                    cover={<img src={potali} className="width100px" />}
                  >
                    <p className="font-700 font-18 mb-0">
                      {t("AboardTFLCoins")}
                    </p>
                  </Card>
                </Col>
              </Row>
              <Row
                gutter={[16, 16]}
                justify="center"
                className="mt-3"
                align="center"
              >
                <Col span={12}>
                  <Card
                    className="border-radius-10  d-flex align-items-center px-3 py-2"
                    hoverable
                    onClick={() => navigate("/agentProfile")}
                    cover={
                      <img src={doll} className="width100px" loading="lazy" />
                    }
                  >
                    <p className="font-700 font-18 mb-0">
                      {t("AboardAgentProfile")}
                    </p>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card
                    className="border-radius-10  d-flex align-items-center px-3 py-2"
                    hoverable
                    onClick={() => navigate("/managerLogin")}
                    // onClick={() =>
                    //   (window.location.href = "https://ezygo.no/mobile/")
                    // }
                    cover={
                      <img src={aryan} className="width100px" loading="lazy" />
                    }
                  >
                    <p className="font-700 font-18 mb-0">A-BOARD</p>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}
      <BottomFooter />
    </>
  );
}
