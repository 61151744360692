import { Layout, Row, Tag, Button } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LeftCircleOutlined } from "@ant-design/icons";
import { FaShoppingCart } from "react-icons/fa";
import logo from "../../assets/landscapelogo.webp";
import maplocationImg from "../../assets/mobile/maplocationImg.webp";
import ConfirmOrderModal from "../../containers/Modal/ConfirmModal";
import { rootAction } from "../../store/slices/rootSlices";
import { isMobile } from "react-device-detect";
import { PoweroffOutlined, EditOutlined } from "@ant-design/icons";
import { userAction } from "../../store/slices/userslices";
import {
  revertCart,
  revertDis,
  revertLocation,
  revertProduct,
  revertRestaurant,
  revertRoot,
  revertCou
} from "../../store/actions/rootaction";
// Import for Language translater
import { useTranslation } from "react-i18next";

const { Header, Content } = Layout;
const HeaderLayout = (props) => {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  const navigate = useNavigate();
  let address = useSelector((state) => state.locationReducer);
  let cartLength = useSelector((state) => state.cartReducer.cartItems.length);
  let cartData = useSelector((state) => state.cartReducer);
  let [locationPath, setLocationPath] = useState("");
  let [prevLocation, setPrevLocation] = useState("");
  let dispatch = useDispatch();
  let [showConfirmModal, setShowConfirmModal] = useState(false);
  let location = useLocation();
  let [isRestaurant, setisRestaurant] = useState(false);
  let root = useSelector((state) => state.rootReducer);
  let user = useSelector((state) => state.userReducer);
  let [resWebsite, setResWebsite] = useState("");
  const resName = localStorage.getItem("resName");
  let [resSlug, setResSlug] = useState();
  let [ifSlugIsThere, setIfSlugIsThere] = useState();
  let res = useSelector((state) => state.restaurantReducer.selectedRestaurant);
  const uniqueItems = new Map();

  cartData.cartItems.forEach((item) => {
    const instructionKey = item.instruction
      ? JSON.stringify(item.instruction)
      : "no-instruction";
    const key = `${item._id}-${instructionKey}`;

    if (!uniqueItems.has(key)) {
      uniqueItems.set(key, item);
    }
  });

  const cartCount = uniqueItems.size; // Unique product count based on id & instruction

  useEffect(() => {
    setIfSlugIsThere(localStorage.getItem("resName"));
    setResSlug(localStorage.getItem("restaurantSlug"));
    setResWebsite(localStorage.getItem("restaurantWebsite"));
  }, []);
  const getRestaurantNameCity = () => {
    let resNameCity = "";
    if (res && Object.keys(res).length > 0) {
      resNameCity = res.name;
      // resNameCity = res.name + ", " + res.address.city;
    }
    return resNameCity;
  };
  const ifTreeDriveOn = () => {
    return (
      !location.pathname.includes("/restaurant/") && (
        <>
          {ifSlugIsThere && location.pathname == "/checkout" ? (
            ""
          ) : (
            <img
              src={logo}
              alt="logo"
              className="logoSize border-radius-5"
              height={"60px"}
            />
          )}
        </>
      )
    );
  };

  useEffect(() => {
    setLocationPath(location.pathname);

    let restaurant = location.pathname.split("/");
    restaurant = restaurant[1] == "restaurant" ? true : false;
    setisRestaurant(restaurant);
    if (location.pathname == "/home") {
      dispatch(revertCart());
      dispatch(revertDis());
      dispatch(revertLocation());
      dispatch(revertProduct());
      dispatch(revertRestaurant());
      dispatch(revertRoot());
    }
    if (location.pathname == "/restaurants") {
      dispatch(revertCart());
      dispatch(revertDis());
      dispatch(revertProduct());
      dispatch(revertRestaurant());
      dispatch(revertCou());
    }

    if (window?.innerWidth > 720) {
      setPrevLocation("/home");
    } else if (
      location.pathname == "/profile" ||
      location.pathname == "/login" ||
      location.pathname == "/signup"
    ) {
      setPrevLocation("/home");
    } else {
      setPrevLocation("/restaurants");
    }
  }, [location.pathname]);

  const onConfirmOrderModal = () => {
    setShowConfirmModal(true);
  };
  const onCancelConfrimModal = () => {
    setShowConfirmModal(false);
  };

  let getHeaderlayout = () => {
    let selOptions = [];
    let isSelected = "";

    if (res && Object.keys(res).length > 0) {
      console.log("res in if header", res);
      const orderTypes = [
        {
          key: "Drive Out",
          allowed: res.isTreeDriveDriveOutAllowed,
          rest: res.isRestDriveOut,
          // label: "Utkjøring",
          label: t("HeaderDriveOut")
        },
        {
          key: "Take Away",
          allowed: res.isTreeDrivePickUpAllowed,
          rest: res.isRestPickup,
          label: t("HeaderPickup")
          // label: "Henting"
        }
      ];

      orderTypes.forEach(({ key, allowed, rest, label }) => {
        if (allowed && rest) {
          selOptions.push({
            optValue: key,
            label,
            isSelected: root.orderType === key ? "selected" : ""
          });
        }
      });
    }
    let layout = "";
    if (
      locationPath == "/restaurants" ||
      locationPath == "/reserveTable" ||
      locationPath == "/retailerList" ||
      locationPath == "/inviteList" ||
      locationPath == "/tflMarketCart"
    ) {
      layout = (
        <div className="search-location width-100 d-flex">
          <a
            className="top-bar locBtn d-flex align-items-center align-items-center"
            onClick={props.onChangeLocationHandler}
          >
            <span className="locSpan text-white d-flex align-items-center">
              {t("SelectLocation")}
              &nbsp;
              <u>
                {`${address.locationAddress.slice(0, isMobile ? 20 : 28)}...`}{" "}
                &nbsp;
              </u>
              {/* {isMobile ? (
                <u>{`${address.locationAddress.substr(0, 20)}...`} &nbsp;</u>
              ) : (
                <u>{`${address.locationAddress.substr(0, 28)}...`} &nbsp;</u>
              )} */}
              &nbsp;
              <img alt="maplocationImg" src={maplocationImg} />
            </span>
          </a>
          {locationPath == "/tflMarketCart" && isMobile && (
            <LeftCircleOutlined
              onClick={() => navigate("/tflMarket")}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "30px"
              }}
            />
          )}
        </div>
      );
    } else if (locationPath == "/products" || isRestaurant) {
      layout = (
        <>
          {res?.availability?.status == null ? <></> : <></>}
          {res?.availability?.status === true ? (
            <div className="d-flex justify-content-end width-100">
              {res?.isTreeDriveDriveOutAllowed === "true" &&
              res?.isTreeDrivePickUpAllowed === "true" ? (
                <select
                  className="dropSelect"
                  onChange={(e) => {
                    dispatch(rootAction.setOrderType(e.target.value));
                  }}
                >
                  <option
                    value="Take Away"
                    selected={root.orderType == "Take Away" ? "selected" : ""}
                  >
                    {t("HeaderPickup")}
                  </option>
                  <option
                    value="Drive Out"
                    selected={root.orderType == "Drive Out" ? "selected" : ""}
                  >
                    {t("HeaderDelivery")}
                  </option>
                </select>
              ) : (
                <p className="rootOrderType mb-0 d-flex align-items-center justify-content-center">
                  <select
                    className="dropSelect border-radius-50 text-black"
                    onChange={(e) => {
                      dispatch(rootAction.setOrderType(e.target.value));
                    }}
                  >
                    {selOptions.map((e) => {
                      return (
                        <option value={e.optValue} selected={e.isSelected}>
                          {e.label}
                        </option>
                      );
                    })}
                  </select>
                </p>
              )}
              <div
                className="cartLogo positionRelative d-flex align-items-center justify-content-center"
                onClick={onConfirmOrderModal}
              >
                <FaShoppingCart className="cartIcon text-white" />

                <Badge bg="secondary" className="cartBadge">
                  {cartLength ? cartCount : ""}
                </Badge>
              </div>
            </div>
          ) : (
            <Tag color="#f50">{t("ProductClosed")}</Tag>
          )}
        </>
      );
    }

    return layout;
  };

  let getCityAndName = () => {
    let layout1 = "";
    if (locationPath == "/restaurants") {
      layout1 = <div></div>;
    }

    if (
      locationPath == "/products" ||
      locationPath == "/checkout" ||
      isRestaurant
    ) {
      layout1 = (
        <div className="d-flex width-100 justify-content-between">
          <p
            className="mb-0 font-18 text-white fw-bold text-capitalize"
            style={{
              display: "inline-block"
            }}
          >
            &nbsp; &nbsp;&nbsp;({getRestaurantNameCity()})
          </p>
          {locationPath == "/checkout" ? (
            <LeftCircleOutlined
              onClick={() =>
                navigate(resWebsite ? `/restaurant/${resSlug}` : "/products")
              }
              className="border-radius-100 d-flex align-items-center justify-content-center text-white"
              style={{
                fontSize: "30px"
              }}
            />
          ) : (
            <></>
          )}
        </div>
      );
    }
    return layout1;
  };
  const pageNames = {
    "/terms": t("TermsConditions"),
    "/privacy": t("PrivacyPolicy"),
    "/agentProfile": t("AboardAgentProfile")
  };

  const currentPath = window.location.pathname;
  const pageTitle = pageNames[currentPath] || "";
  const refreshAfterLogout = () => {
    dispatch(userAction.removeUser());

    // Ensure Redux state is updated before navigating
    setTimeout(() => {
      navigate("/login");
    }, 100); // Small delay to allow Redux state update
  };
  return (
    <Header className="other-nav mainBgColor width-100 positionFixed zIndex-99 d-flex align-items-center justify-content-between">
      <div className="navWrapper d-flex width-100 ">
        {!isMobile && (
          <div className="d-flex align-items-center width-100 justify-content-between">
            <NavLink to="/home" className="nav-logo">
              {ifTreeDriveOn()}
            </NavLink>
            {window.location.pathname == "/profileInAboard" && (
              <>
                {" "}
                <Button
                  icon={<PoweroffOutlined />}
                  onClick={refreshAfterLogout}
                  className="border-radius-50 font-700 px-3 d-flex align-items-center spreadedShadow"
                  style={{
                    border: "none",
                    backgroundColor: "#FDDC2E ",
                    cursor: "pointer"
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = "#ffc2c2")
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = "#ffd1d1")
                  }
                >
                  {t("ProfileLogoutButton")}
                </Button>
              </>
            )}
            {getCityAndName()}
          </div>
        )}
        {isMobile && (
          <>
            {window.location.pathname == "/checkout" ? (
              <>
                <LeftCircleOutlined
                  onClick={() =>
                    navigate(
                      resWebsite ? `/restaurant/${resSlug}` : "/products"
                    )
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "30px"
                  }}
                />
                {resName}
              </>
            ) : (
              <>
                {["/terms", "/privacy", "/agentProfile"].includes(
                  currentPath
                ) ? (
                  <div className="px-3 d-flex justify-content-between width-100">
                    <h3 className="mb-0 font-700 font-24 text-white">
                      {pageTitle}
                    </h3>
                    <LeftCircleOutlined
                      onClick={() => window.history.back()}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "30px"
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
            {window.location.pathname == "/products" ? (
              <h3 className="textWhite font-16 mb-0 font-700">{resName}</h3>
            ) : (
              <></>
            )}
          </>
        )}

        {getHeaderlayout()}
      </div>
      {showConfirmModal && cartLength > 0 && (
        <ConfirmOrderModal
          onCancelConfrimModal={onCancelConfrimModal}
        ></ConfirmOrderModal>
      )}
    </Header>
  );
};
export default HeaderLayout;
