// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      // Common
      EmbarkJourneyLine1: "Embark on your EzyGo journey with Master Agent Tara",
      EmbarkJourneyLine2: "Log in now and unlock a world of benefits",
      WelcomeToEzyGo:
        "Welcome to EzyGo,<br/>Master agent TARA at your service.",
      ExploreGrowConnect: "Explore, Grow and Connect",
      DownloadOurApp: "Download our app now",
      RestaurantsWord: "Restaurants",
      ChooseLang: "Choose Language",
      AmountText: "Amount",
      GiftedByAdmin: "GIFTED BY ADMIN",
      GiveLocAccess: "Please give EzyGo access to your location.",
      NotSendInvitation: "Could not send invitation. Please try again.",
      AlreadyInvited: "Already invited",
      InvitationSent: "Invitation sent",
      MobileText: "Mobile",
      SelectDate: "Select Date",
      ChangeLanguage: "Change Language",
      YourOTP: "Your OTP",
      Extras: "Extras",
      Spiciness: "Spiciness",
      BestillBord: "Book a table",
      Invite: "Invite",
      Invited: "Invited",
      Days: "Days",
      AgentWorldWideLaunch: "Will be available worldwide in",
      TFLDigitalCoins: "TFL Agent Coins",
      EmptyCart: "Empty Cart",
      NoSlotsAvailable: "No slots available",
      LoginToOrder: "Login First to Order",
      ClearCart: "Clear",
      EzyGoShopLink: "ezygo.no",
      EnterOTP: "Enter OTP",
      SendOTP: "Send OTP",
      DeliveredText: "DELIVERED",
      InProcessText: "IN-PROCESS",
      PendingText: "PENDING",
      CancelText: "CANCEL",
      Max50Allowed: "Maximum 50 allowed",
      OfferDate: "Offer Date",
      BrowserDoNotSupportVideo: "Your browser does not support the video tag.",
      MonthlyBalanceExhausted:
        "Your balance is fully used this month. Stay tuned – new benefits are coming next month!",
      AgentCardList: "Agent card list",
      NoCardsBought: "You haven't bought any Agent cards yet!",
      BuyNow: "Buy now",
      NotEnoughCoins: "You do not have enough points to make a purchase.",
      NoDataAvailable: "No data available",
      PaymentFailedDueToNet: "Payment failed due to network issues.",
      CouponCopies: "Coupon code copied to clipboard",
      SoldText: "Sold",
      UseCode: "Use Code",

      //Messages Pop up
      AllMandatoryFields: "All fields are mandatory!",
      VerifyPleaseWait: "Please wait...",
      ConfirmNewPassword: "Please confirm your new password.",
      PasswordIsUpdated: "The password is updated, please log in again!",
      ProfileIsUpdated: "The profile was updated.",
      SomeThingWentWrong: "Something went wrong please try again.",
      NoFileIsSelected: "No file is selected!",
      ProfileImageUpdated: "Profile image updated.",
      FileShouldNotExceed5Mb:
        "The file size exceeds the 5 MB limit. Upload a smaller image.",
      ImageUploadFailed: "Image upload failed!",
      SelectedTimeExceed: "The selected time exceeds the closing time.",
      LoggedInRedirecting: "Logged in successfully, redirects to your profile.",
      InvalidInfoAdded:
        "Error: Invalid information entered, please check the information before you submit the form.",
      FillAllRequiredFields: "Please fill all the required fields!",
      OTPSentOnMobileNo:
        "OTP is sent to your registered mobile number for password recovery.",
      PasswordResetSuccess: "Password reset successfully.",
      ImageUploadedSuccessfully: "Image uploaded successfully!",
      ShareYourLoc: "Share your location with EzyGo",
      // Navbar English Translation
      // NavEzygo: "Ezygo",
      NavAgent: "Agent Card",
      NavHstory: "History",
      NavArchive: "Archive",
      NavShare: "Share",
      NavContact: "Contact",
      NavRegister: "Register Now",
      NavHome: "Home",

      // Salonlist Page English Translation
      GetBestTitle: "Get the best",
      HairStyleTitle: "Hairstyles here",
      ComingText: "Coming Soon",
      SalonInviteYellowBoxText:
        "Is your favorite salon in your<br/> city not yet listed?",
      InviteButton: "Invite here",

      // Digital Services Page English Translation
      ContactButton: "Contact Us",
      IndividualsTitle: "For individuals",
      BusinessTitle: "For Business",
      IndvidualWebdesignTitle: "Web Designing",
      IndvidualWebdesignText:
        "Create stunning, user-friendly websites that elevate your online presence.",
      IndividualLogoTitle: "Logo Designing",
      IndividualLogoText:
        "Craft a unique logo that defines your brand identity and vision.",
      IndividualAppsTitle: "Apps",
      IndividualAppsText:
        "Streamline your transactions with custom PoS apps tailored to your business needs.",
      IndividualDigitalTitle: "Digital Marketing",
      IndividualDigitalText:
        "Boost your reach and engagement with tailored digital marketing strategies.",
      IndividualSocialTitle: "Social Media Management and Postings",
      IndividualSocialText:
        "Let us handle your social media to keep your audience engaged and growing.",
      IndividualBusinessTitle: "Business Consultation",
      IndividualBusinessText:
        "Expert advice to optimize your digital business for success.",
      IndividualMenuTitle: "Menu Designing",
      IndividualMenuText:
        "Impress your customers with professionally designed, easy-to-read menus.",
      // Digital Services Page Business English Translation
      BusinessProjectTitle: "Project Management",
      BusinessProjectText:
        "Efficiently manage your projects with our expert project management solutions.",
      BusinessAppTitle: "Apps",
      BusinessAppText:
        "Streamline your transactions with custom PoS apps tailored to your business needs.",
      BusinessMenuTitle: "Menu Designing",
      BusinessMenuText:
        "Impress your customers with professionally designed, easy-to-read menus.",
      BusinessWebdesignTitle: "Web Designing",
      BusinessWebdesignText:
        "Create stunning, user-friendly websites that elevate your online presence.",
      BusinessLogodesignTitle: "Logo Designing",
      BusinessLogodesignText:
        "Craft a unique logo that defines your brand identity and vision.",
      BusinessPosterTitle: "Poster Designing",
      BusinessPosterText:
        "Eye-catching posters that capture attention and promote your business effectively.",
      BusinessMarketTitle: "Digital Marketing",
      BusinessMarketText:
        "Boost your reach and engagement with tailored digital marketing strategies.",
      BusinessSocialMediaTitle: "Social Media Management and Postings",
      BusinessSocialMediaText:
        "Let us handle your social media to keep your audience engaged and growing.",
      BusinessConsultationTitle: "Business Consultation",
      BusinessConsultationText:
        "Expert advice to optimize your digital business for success.",
      BusinessMobileTitle: "Mobile App Development",
      BusinessMobileText:
        "Build feature-rich mobile apps to enhance your business on-the-go.",
      // Contact Page English Translation
      KnowMoreTitle: "Reach Out – Your Answers Are Just a Message Away.",
      // KnowMoreTitle: "To Know More <br/> about us",
      QueryLink: "Your Queries, Our Solutions – Connect with Us",
      VisitText: "Powered by Ezy System As — Discover more at",
      SubmitButton: "Submit request",
      PrivacyPolicyText: "Privacy Policy",
      GeneralText: "General T&C",
      SaleText: "Sale T&C",
      FraudAlertText: "Fraud alert",
      DisclaimerText: "Disclaimer",
      // Login Page English Translation
      LoginFoodBeautyShopping: "Food | Beauty | Shopping",
      FormLogin: "Login",
      LoginSimplified: "Simplified For You!",
      LoginForgetPassword: "Forgot your password?",
      LoginNewUser: "New user",
      LoginSignup: "Sign up!",
      LoginContinue: "By continuing, you agree to our",
      TermsConditions: "Terms & Conditions",
      PrivacyPolicy: "Privacy Policy",
      GuestUser: "Continue as a guest user!",

      // Reasturant Page English Translation
      RestaurantExplore: "Also Explore",
      RestaurantReserve: "Reserve Table",
      RestaurantFind:
        "We can't find where you are, to show<br/> you nearest restaurants<br/> please select your location",
      SelectLocation: "Select Location : ",
      RestaurantInviteYellowBoxText:
        "Is your favorite restaurant in your<br/> city not listed yet?",

      // TFL Market Page English Translation
      TflAdd: "Add",
      ShoppingCart: "Shopping cart",
      TFLHide: "Hide",
      TFLShow: "Show",
      TFLMarketProceedButton: "Proceed",

      // Reset Page English Translation
      ForgetPassword: "Forgot password?",
      MobilePLaceholder: "Mobile number",
      ResetNewUser: "New user?",
      ResetRegister: "Register",
      ResetBack: "Back to",

      // Signup Page English Translation
      SignupProfile: "Create your profile",
      SignupFirstName: "First Name*",
      SignupLastName: "Last Name*",
      SignupEmail: "E-mail*",
      SignupMobilePLaceholder: "Mobile number*",
      ReferCodeText: "Referral Code (if any)",
      SignupPassword: "Password*",
      SignupConfirmPassword: "Confirm password*",
      SignupCreateProfile:
        "By clicking the Create Profile button, you agree to EzyGo's",
      SignupTerms: "Terms and Conditions",
      SignupExistingUser: "Existing user, please",
      SignupCreateButton: "Create",

      // Query Page English Translation
      QueryFirstName: "First Name",
      QueryLastName: "Last Name",
      QueryEmail: "E-mail",
      QueryNumber: "Mobile Number",
      QuerySelectDateTime:
        "Select the most convenient date and time for your call.",
      QueryMessage: "Message",
      QueryButton: "Send in",

      // TFLGiftCard Page English Translation
      TFLAgentCardTitle: "Agent Card",
      TFLExperienceText:
        "Experience personalized convenience with the Agent Card, crafted to match your desires. Access it globally, anytime, anywhere. Enjoy this card offering 10%-90% added value, usable across our network and partner businesses. Convert unused balances into coins or donate to global initiatives, ensuring every penny counts toward a better world!",
      PreValueText: "Pre- Value-Added-Agent Cards",
      TFLSecureProfile: "Secure yourself <br/> an agent card now!",
      TFLGIftAgentCard: "Gift an agent card <br/> to someone special!",
      TFLViewButton: "View Agent Card",
      TFLSomeGiftText:
        "Some gifts are big, others small, but those given from the heart are the greatest.",
      ReadLess: "Read less",
      ReadMore: "Read more",

      // Order History Page English Translation
      OrderFoodHistory: "Food History",
      OrderProductHistory: "Product History",
      OrderType: "Order Type",
      OrderDate: "Order Date",
      OrderTime: "Order Time",
      OrderItemNumber: "Item Number",
      OrderTotal: "Total",
      OrderRestaurantDetails: "Restaurant Details",
      OrderDetailsButton: "Order Details",
      CustomerDetails: "Customer Details",
      OrderName: "Name",
      OrderEmail: "Email",
      OrderMobileNumber: "Mobile Number",
      OrderAddress: "Address",
      ProductsDetail: "Products Detail",
      OrderProductsName: "Products Name",
      OrderQuantity: "Qty",
      OrderPrice: "Price",
      OrderItemName: "Item Name",
      OrderDriver: "Driver's name",
      OrderDriverNumber: "The driver's contact number",
      OrderNoYet: "You have not ordered yet!",
      OrderButton: "Order",
      OrderProduct: "Total Product Price:",
      OrderTax: "Tax :",
      OrderFee: "App Fee :",
      OrderTotalToPay: "Total to pay :",
      OrderVat: "App off. VAT:",
      OrderCharge: "Driveout Charge :",
      OrderDeliveryVat: "Delivery VAT:",
      OrderDiscount: "Discount:",
      OrderAmount: "Total amount",

      // A-Board Page English Translation
      AboardArchive: "Archive",
      AboardPersonalProfile: "Personal Profile",
      AboardTFLCoins: "TFL Coins",
      AboardAgentProfile: "Agent Profile",
      AboardMobileAgent: "Your Agent",

      //Profile in A-Board Page English Translation
      ProfileEditProfile: "Edit Profile +",
      ProfilePreferences: "Preferences",
      ProfileChangePassword: "Change password",
      ProfilePrivacyPolicy: "Privacy Policy",
      ProfileTermsConditions: "Terms & Conditions",
      ProfileLogoutButton: "Log Out",

      //Edit Profile
      ChooseGender: "Choose Gender",
      SexMale: "Man",
      SexFemale: "Woman",
      SexOther: "Other",

      //TFL Coin Exchange Page English Translation
      TFLExchangeGift: "Get Agent cards with your TFL coins!",
      ExchangeAvailableCoins: "Available Coins",
      ExchangeUsedCoins: "Used Coins",
      ExchangeTotalCoins: "Total coins earned",
      ExchangeCoinsUpdate:
        "*Your coins will be updated within one hour of order confirmation",
      ExchangeRedemptionScheme: "Redemption Scheme",
      ExchangeGiftCard: "GiftCard in NOK",
      ExchangeNumberCoins: "No. Of Coins",
      ExchangeAction: "Action",
      ExchangeRedeem: "Redeem",
      ExchangePleaseVisit: "For more info please visit",
      MoreEarnCollect: "The more you collect, the more you earn!",
      ExchangePopUp1: "Would you like to redeem",
      ExchangePopUp2: "coins for",
      ExchangePopUp3: "NOK Agent Card",
      ExchangeMessage:
        "This number has already purchased a maximum of 3 Agent cards.",

      //Edit Profile Page English Translation
      EditUploadPicture: "Upload picture",
      EditDateBirth: "Date of Birth*",
      EditGender: "Gender*",
      UpdateProfile: "Update Profile",

      //Change Password Page English Translation
      ChangePasswordTitle: "Change Password",
      OldPassword: "Old Password*",
      NewPassword: "New Password*",
      ConfirmNewPassword: "Confirm New Password*",
      UpdatePassword: "Update password",
      ConfirmYourPassword: "Please confirm your password!",

      // Share Referal Page English Translation
      ShareInviteFriendsTitle: "Invite friends to EzyGo!",
      ShareInviteFriendsText:
        "Invite your friends to EzyGo and get coins credited after placing place their first order. And let them earn coins on SignUp.",
      ShareRefferalCode: "Your referral code above",
      ShareReferEarn: "The more you refer the more you earn!",
      ShareFriends:
        "You know you love us, your friends will too. <br/> Share it and share the fun.",

      //Product card Page English Translation
      ProductOpen: "Opens",
      ProductDiscount: "Discount Upto:",
      ProductClosed: "Closed",

      //  Card order button page english Translation
      CardMoreButton: "Add more",
      CartOrderProceed: " Fortsett",

      //  Card order button page English Translation
      TotalBillDetails: "Bill Details",
      TotalSubtotal: "Subtotal",
      TotalTaxTitle: "Tax",
      TotalDiscount: "Discount",

      // Footer page English Translation
      FooterSaveProceed: "SAVE AND PROCEED",

      // ComingSoon page English Translation
      ComingDownload: "Download the app for more benefits!",

      // ShortCut to all page English Translation
      ShortCutOrders: "Orders",
      ShortCutEditProfile: "Edit Profile",
      ShortCutAgentProfile: "Agent Profile",
      ShortCutFoodOrder: "Food Order History",
      ShortCutOrderHistory: "Product Order History",
      ShortCutBuy: "Buy for Yourself",
      ShortCutBuyOthers: "Buy for Others",
      ShortCutSee: "See Agent Card",
      ShortCutInformation: "More Information",
      ShortCutPrivacy: "Privacy Policy",
      ShortCutTerms: "Terms & Conditions",

      // TFL Agent Card to MySelf page English Translation
      TFLGiftName: "Name",
      TFLGiftEmail: "Email",
      TFLGiftMobile: "Mobile number",
      TFLGiftAmount: "Choose an amount* (NOK)",
      TFLGiftBuying: "Buying Amount",
      TFLGiftReceiving: "Receiving Amount",
      TFLGiftMonthly: "Monthly Usage (%)",
      TFLGiftValidity: "Validity (Months)",
      TFLGiftBuyButton: "Buy",
      TFLLimitReached3:
        "You've reached the limit – three agent cards have already been purchased!",
      CouponSent: "The coupon was sent!",
      StartYourJourney: "Start your journey with Agent cards!",
      CollectMoreCard: "Why stop here? Collect more!",
      YouHave2Card: "You have 2 Agent cards!",
      YouHave3Card: "Congratulations! You have 3 Agent cards!",
      SelectValidCardValue: "Please select a valid Agent card value.",

      // TFL Agent Card for others page English Translation
      TFLOthersRecipients: "Recipient's Name",
      TFLOthersMobile: "Recipient's Mobile Number",
      TFLOtherEmail: "Recipient's Email",
      TFLOtherYourEmail: "Your Email",
      TFLOtherYourMobile: "Your Mobile Number",
      CouponSubmittedSuccess: "Coupon submitted successfully!",
      TFLOtherLimitReached3:
        "Your loved one already has 3 Agent cards. Please try again later!",
      YouHaveReachedLimit: "You have reached the limit - no more can be added!",
      LoginTo: "Log in to",

      // Show TFL Agent Card page English Translation
      ShowTFLHide: "Hide code",
      ShowTFLView: "View Code",
      ShowTFLValid: "VALID TILL",
      ShowTFLMonth: "MONTH",
      ShowTFLLimit: "LIMIT",
      ShowTFlUsed: "USED",
      ShowTFLBalance: "BALANCE",
      ShowTFLCoins: "Coins Converted",

      // Invite List page English Translation
      InviteSalonList: "Salon List",
      InviteNearby: "Near by",
      InviteSalon: " Salon",
      InviteNoData: "No data found",
      InviteName: "Name",
      InviteAction: "Action",
      InviteCommonRestBtn: "Start inviting Restaurants now",
      InviteCommonSalonBtn: "Start inviting Salons now",
      InviteCommonQuestion: "Missing your favorite",
      InviteCommonLine1: "Download EzyGo and check it out!",
      InviteCommonLine2:
        "Be quick - invite, and let your agent earn rewards for you!",
      InviteCommonLine3Start: "When you help your",
      InviteCommonLine3End:
        "get listed in our Agent platform, you will be rewarded from your Agent with real value Coins.",
      InviteCommonPoint1:
        "Your Agent earns Coins for each successful invitation after approved transaction.",
      InviteCommonPoint2:
        "Easily track your Agent's earnings in your digital wallet.",
      InviteCommonPoint3:
        "Bigger and better rewards for unique invitations and actions.",
      InviteCommonPoint4: "Exciting and tempting bonuses are coming soon!",
      InviteCommonLastLine:
        "Start inviting now, and turn your recommendations into real rewards through your agent.",

      // Reserve Table page English Translation
      ReserveExplore: "Also Explore",
      ReserveDrivePickup: "Driveout / Pickup",

      // TFL Market Cart page English Translation
      TFLMarketCloseButton: "Close",
      TFLMarketReview: "Review Your Order",
      TFLMarketOffers: "Offers and Benefits",
      TFLMarketApplyCard: "Apply Agent card",
      TFLMarketBill: "Bill Details",
      TFLMarketShipping: "Shipping Price",
      TFLMarketPrice: "Price",
      TFLMarketAmount: "Agent Card Amount",
      TFLMarketNoRegister:
        "There is no agent card <br/> registered on your account.",
      TFLMarketSteps: "Steps to buy Agent cards:",
      TFLMarketGoAgent: "Go to agent card section.",
      TFLMarketExplore:
        "Explore the options and choose the agent card you want.",
      TFLMarketComplete:
        "Complete the payment process to complete the purchase of an agent card.",
      TFLMarketTransaction:
        "Once the transaction is complete, your prepaid agent card will be updated and ready for use in all parts of the EzyGo network.",

      // TFL Order Summary page English Translation
      TFLOrderFailed: "Oh no, the order failed",
      TFLOrderBack: "Back to shopping cart",
      TFLOrderExplore: "Explore more products",

      // App Navbar page English Translation
      AppNavbarUs: "Us",
      AppNavbarPartner: "Collaboration partners",
      AppNavGiftCard: "TFL Agent Card",

      // Cards page English Translation
      CardsRestaurantExplore: "Restaurants to explore",
      CardsWhatinMind: "What’s on your mind?",

      // Order Products Page English Translation
      OrderProductInstructions: "Instructions for use : ",

      // Drive Out Form Page English Translation
      DriveOutAdrress: "Address",
      DriveOutInput: "Please input your Address",
      DriveFormCity: "City",
      DriveOutCity: "Please enter your city",
      DriveOutState: "State",
      DriveOutEnterState: "Please input your state",
      DriveOutEnterPin: "Please input your pin!",
      DriveOutCountry: "Country",
      DriveOutEnterCountry: "Please input your Country",

      // Form Main Detail Page English Translation
      FormMainName: "Please input your Name!",
      FormMainLastName: "Enter your last name!",
      FormMainEmail: "Please enter your email!",
      FormMainNumber: "Mobile Number",

      // Form Main Detail Page English Translation
      MapFormVaerifyButton: "Verify",
      SideCartPayment: "Payment",
      SideCartOrder: "Edit Order",
      SideCartReview: "Review Your Order",
      SideCartCharge: "Driveout Charge",
      SideCartBillPay: "Bill to pay",
      SideCartAlertMessage:
        "Due to the unavailability of the Driver, we cannot proceed with Drive Out Order.",
      SideCartDeleteCoupon: "Delete coupon",
      SlideCartOrderButton: "Click the Process Order button to order.",
      SideCartUnavalaible:
        "Due to the unavailability of the Driver, we cannot proceed with Drive Out Order.",
      ProcessOrderButton: "Click the Process Order button to order.",
      SideCartPosition: "Your position is too far for delivery!",
      ClickProcessOrder: "Click Process Order to order.",
      MinimumOrderMustBe: "The minimum order must be",
      AddMoreItemToPlaceOrder: "Add more items to place your order.",

      // Business Form Page English Translation
      BusinessGetButton: "Get in touch with us",
      BusinessRestaurantName: "Restaurant Name",
      BusinessRestaurantType: "Restaurant Type",
      BusinessWebsite: "Website (if any)",
      BusinessPosition: "Your Position",
      BusinessCountry: "County",
      BusinessAddress: "Enter your address",
      BusinessMessage: "Write your message/additional information here",
      BusinessAgree: "By clicking I agree and agree",
      BusinessPrivacy: "Privacy",
      CancelButton: "Cancel",
      BusinessContacting: "Thank you very much for contacting us!",

      // Contact Form Page English Translation
      ContactMandatory: "This field is mandatory",
      FormCurrentPosition: "Current Position",
      FormExperience: "Experience (in years)",
      FormOrgNumber: "Org number (if any)",
      FormJobRole: "Job role",
      FormDriver: "Driver",
      FormCook: "Cook",
      FormWaiter: "Waiter",
      FormSeller: "Seller (For our company)",
      FormAdministration: "Administration (For our company)",
      FormOtherPosition: "Hvilken andre stillinger du henser å Søke",

      // General Form Page English Translation
      GeneralFormSubmit: "Submitted!",
      GeneralFormUnexpected: "An unexpected error occurred. Try again.",
      GeneralFormSubmitError: "Failed to submit the form. Please try again.",
      GeneralEnterName: "Please enter your first name.",
      ValidName: "Please enter a valid email",
      CountryCode: "Please select a country code.",
      PhoneNumber: "Please enter your mobile number.",
      NumberError: "The mobile number can only contain numbers.",
      TenDigits: "The mobile number cannot have more than 10 digits.",

      //Suceess Coupon Purchase Page English Translation
      LoadingWait: "Loading Please wait....",
      GIftCard: "The Agent card has been created.",
      ThankYouMessage:
        "Thank you Your Agent card has been generated. Please check the Email or SMS sent to your Mobile Number for confirmation.",
      GiftNotCreated: "A Agent card has not been created.",
      SorryGIftNotCreated:
        "We are sorry. but your Agent card has NOT been generated",
      TryAgain: "Please try again",
      //Header Page Norwegian Translation
      HeaderDriveOut: "Driveout",
      HeaderPickup: "Pickup",
      HeaderDelivery: "Delivery",

      // Top Bar Page English Translation
      SomethingWrong: "Something Went Wrong!",

      //Apply Coupon Modal Page English Translation
      InvalidCode: "The code is invalid or expired",
      GiftCardCode: "Please enter a Agent card code",
      CouponSomethingWrong: "Something went wrong. Try again later.",
      MonthlyBalance:
        "Your balance is fully used this month. Stay tuned—new perks arrive next month!",
      ApplyUseCode: "Use code",
      AgentCardRegistration:
        "There is no agent card <br/> registered on your account.",
      ApplyBuyAgentCard: "To buy an agent card (register)",
      ApplyContinue: "Continue here",
      "Transaction Complete":
        "Once the transaction is complete, your prepaid agent card will be updated and ready for use in all parts of the EzyGo network.",

      // Location Modal Page English Translation
      OkButton: "Ok",

      //Modal Page English Translation
      FollowAlong: "Follow along",
      ModalComingSoon: "We are coming soon, to your city!",

      // Modal1 Slide Page English Translation
      ModalActivateTitle: "Activate Your EzyGo AI Agent",
      ModalRegister: "Register yourself on the EzyGo Network.",
      ModalSetup:
        "Set up your personal AI Agent to start earning automatically.",
      ModalActivate:
        "Activate your agent and let it work for you without any problems",
      ModalAgentWork: "Let Your Agent Work for You",
      ModalExplore: "Explore the EzyGo Network with your AI Agent.",
      ModalAccess: "Access services and collaborate with other EzyGo Agents.",
      ModalEarnTFL: "Earn TFL Agent Coins for every action and collaboration.",
      ModalEarnCoins: "Earn coins for every action",
      ModalCollectCoins: "Collect TFL coins for each task you complete.",
      ModalStayActive: "Stay active to maximize your earnings.",
      ModalSaveCoins:
        "Save coins up to the redemption limit to fulfill your needs and wants.",
      ModalBuyGift: "Buy or Gift Pre-Value E-Cards",
      ModalPurchase:
        "Purchase Pre-Value Added Agent E-Cards for extra benefits.",
      ModalGiftAgent: "Gift agent e-cards to others and enjoy shared savings.",
      ModalSpend:
        "Spend wisely and earn extra coins on every transaction with your Agent E Card.",
      ModalHold: "Hold and Redeem for Bigger Rewards",
      ModalLonger: "The longer you hold your coins, the greater the rewards.",
      ModalRedeem: "Redeem saved coins for exclusive offers and benefits",
      ModalCollecting:
        "Keep collecting, keep redeeming, and enjoy lifetime benefits!",
      ModalID: "Invalid modal ID",
      CloseButton: "Close",

      // Online Order New Page Norwegian Translation
      OnlineBrowser: "Your browser does not support the video codec!",
      OnlineHello: "Hello",
      WelcomeTitle: "Welcome to EzyGo!",
      OnlineBenefits: "For more benefits",
      OnlineYou: "you or",
      OnlineEarnCoins: "Earn coins for every action",
      OnlineService: "E-service",
      // OnlineFood: "Order food",
      OnlineMarket: "E-Market",
      OnlineDiscover: "Discover More with EzyGo!!",

      //Confirm Product Modal Page English Translation
      AddButton: "ADD",
      SeeDescription: "See Description",
      ConfirmIngredients: "Ingredients",
      AddComment: "Add comment (optional)",

      //Confirm Product Page English Translation
      ConfirmNotOpen: "We are not open yet!",

      //Product Component Page English Translation
      ProductDescription: "Description",

      //Order now Button Page English Translation
      OrderButtonNow: "Order Now",

      //Order Summary Page English Translation
      SummeryOrderPlaced: "The order was placed",
      SummaryFoodOrder:
        "We are happy to confirm your recent food order. You will receive the order confirmation by e-mail and SMS containing the OTP and enter this OTP on the receipt. DO NOT share this one-time code with anyone else.",
      SummaryInformation: "User information",
      SummaryBackWebsite: "Back to the website",
      SummaryOrderInformation: "Order information",
      SummaryBackWebsitePage: "Back to the payment page",
      SummaryExploreMore: "Explore more restaurants",
      SummaryOrderFailedText:
        "We regret to inform you that there was a problem with your recent food order through Tree Drive Name of platform/restaurant. We apologize for any inconvenience this may have caused.",

      //Success Coupon Page English Translation
      SuccessThankYou:
        "Thank you for the order. Your order is complete. Check your email or message sent to your mobile number.",
      SuccessThankYouOrder:
        "Thank you for the order. Your order is complete. Check your email or message sent to your mobile number.",
      SuccessReturnHome: "Return to the home page",
      SuccessOrderPlaced: "Your order has not been placed",

      // Verify Page English Translation
      VerifyMobileNumber: "Mobile number and verification code required.",
      VerifyAccount: "Verify your account",
      VerifyEnterCode:
        "Please enter the verification code you have received on your registered mobile number.",
      VerifyAccountButton: "Verify account",

      // Info Modal Page English Translation
      InfoValidValue: "Please enter a valid value",
      InfoValidName: "Please enter a valid recipient name..",
      InfoValidEmail: "Please enter a valid e-mail address",
      VerifyEmailMatch: "Email does not match",
      InfoValidNumber: "Please enter a valid mobile number.",
      InfoConfirmNumber: "Confirm Mobile number",
      InfoNumberMatch: "Mobile number does not match",
      InfoEzyGoApp:
        "Buy a card via the EzyGo app or website with the desired amount.",
      InfoBuyers:
        "The buyer receives an e-mail and SMS with a unique code, and at the same time can see the card in the app.",
      InfoLoaded:
        "It is loaded with the amount you pay or more depending on the offer at the time.",
      InfoCardCode:
        "Use the card code when ordering food from one of A-Board's member restaurants or on Tree drive platforms (app or website)",
      InfoBuyCard: "Buy Agent cards",
      InfoTreeGift:
        "* Tree for life (TFL) Agent cards are a way to buy food yourself or give a gift to your loved ones. It is pre-loaded with the desired amount of money. The card can be used when you order online or at our member restaurants.",

      // Location Modal Page Norwegian Translation
      LocationConfirmButton: "Confirm address"
    }
  },

  no: {
    translation: {
      // Common
      EmbarkJourneyLine1: "Bli med på EzyGo-reisen med Master Agent Tara",
      EmbarkJourneyLine2: "Logg på nå og lås opp en verden av fordeler",
      WelcomeToEzyGo: "Velkommen,<br/> Mester agent TARA til din tjeneste.",
      ExploreGrowConnect: "Utforsk, voks og koble deg til.",
      DownloadOurApp: "Last ned appen vår nå",
      RestaurantsWord: "Restauranter",
      ChooseLang: "Velg Språk",
      AmountText: "Mengde",
      GiftedByAdmin: "BEGAVET AV ADMIN",
      GiveLocAccess: "Vennligst gi EzyGo tilgang til posisjonen din.",
      NotSendInvitation: "Kunne ikke sende invitasjon. Vennligst prøv igjen.",
      AlreadyInvited: "Allerede invitert",
      InvitationSent: "Invitasjon sendt",
      MobileText: "Mobil",
      SelectDate: "Velg Dato",
      ChangeLanguage: "Endre språk",
      YourOTP: "Din OTP",
      Extras: "Ekstra",
      Spiciness: "Krydret",
      BestillBord: "Bestill bord",
      Invited: "Invitert",
      Days: "Dager",
      AgentWorldWideLaunch: "Vil være tilgjengelig over hele verden i",
      TFLDigitalCoins: "TFL Agent coins",
      EmptyCart: "Tom handlevogn",
      NoSlotsAvailable: "Ingen ledige spilleautomater",
      LoginToOrder: "Logg inn først for å bestille",
      ClearCart: "Klar",
      EzyGoShopLink: "ezygo.no",
      DeliveredText: "LEVERT",
      InProcessText: "I prosess",
      PendingText: "I Vent",
      CancelText: "KANSELLERET",
      EnterOTP: "Skriv inn OTP",
      SendOTP: "Send OTP",
      Max50Allowed: "Maks 50 tillatt",
      OfferDate: "Tilbudsdato",
      BrowserDoNotSupportVideo: "Nettleseren din støtter ikke video-taggen.",
      MonthlyBalanceExhausted:
        "Saldoen din er fullt brukt denne måneden. Følg med – nye fordeler kommer neste måned!",
      AgentCardList: "Agentkortliste",
      NoCardsBought: "Du har ikke kjøpt noen Agentkort ennå!",
      BuyNow: "Kjøp nå",
      NotEnoughCoins: "Du har ikke nok poeng til å gjøre et kjøp.",
      NoDataAvailable: "Ingen data tilgjengelig.",
      PaymentFailedDueToNet: "Betaling feilet grunnet nettverk tilkobling.",
      CouponCopies: "Kupongkode kopiert til utklippstavlen",
      SoldText: "Solgt",
      UseCode: "Bruk kode",

      //Messages Pop up
      AllMandatoryFields: "Alle felt er obligatoriske!",
      VerifyPleaseWait: "Vennligst vent...",
      ConfirmNewPassword: "Vennligst bekreft ditt nye passord.",
      PasswordIsUpdated: "Passordet er oppdatert, vennligst logg på igjen!",
      ProfileIsUpdated: "Profilen ble oppdatert.",
      SomeThingWentWrong: "Noe gikk galt, vennligst prøv igjen.",
      NoFileIsSelected: "Ingen fil er valgt!",
      ProfileImageUpdated: "Profilbilde oppdatert.",
      FileShouldNotExceed5Mb:
        "Filstørrelsen overskrider grensen på 5 MB. Last opp et mindre bilde.",
      ImageUploadFailed: "Bildeopplasting mislyktes",
      SelectedTimeExceed: "Den valgte tiden overstiger stengetid.",
      LoggedInRedirecting: "Logget på vellykket, omdirigerer til profilen din.",
      InvalidInfoAdded:
        "Feil: Ugyldig informasjon angitt, vennligst sjekk informasjonen din før innsending av skjemaet.",
      FillAllRequiredFields: "Vennligst fyll alle de nødvendige feltene!",
      OTPSentOnMobileNo:
        "OTP sendes til ditt registrerte Mobilnummer for gjenoppretting av passord.",
      PasswordResetSuccess: "Passord tilbakestilt.",
      ImageUploadedSuccessfully: "Bildeopplasting vellykket! ",
      ShareYourLoc: "Del posisjonen din med EzyGo",

      // Navbar Norwegian Translation
      EzyGo: "Ezygo",
      NavAgent: "Agentkort",
      NavHstory: "Historie",
      NavArchive: "Arkiv",
      NavShare: "Dele",
      NavContact: "Kontakt",
      NavRegister: "Registrer NÅ",
      NavHome: "Hjem",

      // Salonlist Page Norwegian Translation
      GetBestTitle: "Få det beste",
      HairStyleTitle: "Frisyrer her",
      ComingText: "Kommer snart",
      Invite: "Inviter",
      InviteButton: "Inviter her",
      SalonInviteYellowBoxText:
        "Er favoritt salongen i din by,<br/>enda ikke oppført?",

      // Digital Services Page Norwegian Translation
      ContactButton: "Kontakt Oss",
      IndividualsTitle: "For enkeltpersoner",
      BusinessTitle: "For Business",
      IndvidualWebdesignTitle: "Webdesign",
      IndvidualWebdesignText:
        "Lag fantastiske, brukervennlige nettsteder som hever din online tilstedeværelse.",
      IndividualLogoTitle: "Design av logo",
      IndividualLogoText:
        "Lag en unik logo som definerer din merkevareidentitet og visjon.",
      IndividualAppsTitle: "Apper",
      IndividualAppsText:
        "Strømlinjeform transaksjonene dine med tilpassede PoS-apper som er skreddersydd for bedriftens behov.",
      IndividualDigitalTitle: "Digital markedsføring",
      IndividualDigitalText:
        "Øk rekkevidden og engasjementet med skreddersydde digitale markedsføringsstrategier.",
      IndividualSocialTitle: "Sosiale medier administrasjon og innlegg",
      IndividualSocialText:
        "La oss håndtere dine sosiale medier for å holde publikumet ditt engasjert og vokse.",
      IndividualBusinessTitle: "Bedriftskonsultasjon",
      IndividualBusinessText:
        "Ekspertråd for å optimalisere din digitale virksomhet for suksess.",
      IndividualMenuTitle: "Menydesign",
      IndividualMenuText:
        "Imponer kundene dine med profesjonelt utformede, lettleste menyer.",

      // Digital Services Page Business Norwegian Translation
      BusinessProjectTitle: "Prosjektledelse",
      BusinessProjectText:
        "Administrer prosjektene dine effektivt med våre ekspertløsninger for prosjektstyring.",
      BusinessAppTitle: "Apper",
      BusinessAppText:
        "Strømlinjeform transaksjonene dine med tilpassede PoS-apper som er skreddersydd for bedriftens behov.",
      BusinessMenuTitle: "Menydesign",
      BusinessMenuText:
        "Imponer kundene dine med profesjonelt utformede, lettleste menyer.",
      BusinessWebdesignTitle: "Webdesign",
      BusinessWebdesignText:
        "Lag fantastiske, brukervennlige nettsteder som hever din online tilstedeværelse.",
      BusinessLogodesignTitle: "Design av logo",
      BusinessLogodesignText:
        "Lag en unik logo som definerer din merkevareidentitet og visjon.",
      BusinessPosterTitle: "Plakatdesign",
      BusinessPosterText:
        "Iøynefallende plakater som fanger oppmerksomhet og promoterer virksomheten din effektivt.",
      BusinessMarketTitle: "Digital markedsføring",
      BusinessMarketText:
        "Øk rekkevidden og engasjementet med skreddersydde digitale markedsføringsstrategier.",
      BusinessSocialMediaTitle: "Sosiale medier administrasjon og innlegg",
      BusinessSocialMediaText:
        "La oss håndtere dine sosiale medier for å holde publikumet ditt engasjert og vokse.",
      BusinessConsultationTitle: "Bedriftskonsultasjon",
      BusinessConsultationText:
        "Ekspertråd for å optimalisere din digitale virksomhet for suksess.",
      BusinessMobileTitle: "Utvikling av mobilapper",
      BusinessMobileText:
        "Bygg funksjonsrike mobilapper for å forbedre virksomheten din mens du er på farten.",

      // Contact Page  Norwegian Translation
      KnowMoreTitle: "Ta kontakt – svarene dine er bare en melding unna",
      // KnowMoreTitle: "For å vite mer <br/> om oss",
      VisitText: "Drevet av Ezy System As — Oppdag mer på",
      QueryLink: "Dine spørsmål, våre løsninger – kontakt oss",
      SubmitButton: "Send inn forespørsel",
      PrivacyPolicyText: "Personvern Politikk",
      GeneralText: "General T&C",
      SaleText: "Salg T&C",
      FraudAlertText: "Svindelvarsel",
      DisclaimerText: "Ansvarsfraskrivelse",

      // Login Page Norwegian Translation
      LoginFoodBeautyShopping: "Mat | Skjønnhet | Shopping",
      FormLogin: "Logg Inn",
      LoginSimplified: "Forenklet for deg!",
      LoginForgetPassword: "Glemt passordet?",
      LoginNewUser: "Ny bruker",
      LoginSignup: "Registrer deg!",
      LoginContinue: "Ved å fortsette godtar du vår",
      TermsConditions: "Vilkår og betingelser",
      PrivacyPolicy: "Personvernerklæring",
      GuestUser: "Fortsett som gjestebruker!",

      // Reasturant Page Norwegian Translation
      RestaurantExplore: "Utforsk også",
      RestaurantReserve: "Bordreservasjon",
      RestaurantFind:
        "Vi finner ikke hvor du er, for å vise<br/> deg nærmeste restauranter<br/> vennligst velg din posisjon",
      SelectLocation: "Velg Sted : ",
      RestaurantInviteYellowBoxText:
        "Er favoritt restauranten i din by,<br/>enda ikke oppført?",

      // TFL Market Page Norwegian Translation
      TflAdd: "Legge til",
      ShoppingCart: "Handlevogn",
      ForgetPassword: "Glemt passord",
      TFLHide: "Gjemme",
      TFLShow: "Vise",
      TFLMarketProceedButton: "Fortsett",

      // Reset Page Norwegian Translation
      MobilePLaceholder: "Mobilnummer",
      ResetNewUser: "Ny bruker?",
      ResetRegister: "Registrer",
      ResetBack: "Tilbake til",

      // Signup Page Norwegian Translation
      SignupProfile: "Opprett din profil",
      SignupFirstName: "Fornavn*",
      SignupLastName: "Etternavn*",
      SignupEmail: "E-post*",
      SignupMobilePLaceholder: "Mobilnummer*",
      ReferCodeText: "Henvisningskode (hvis noen)",
      SignupPassword: "Passord*",
      SignupConfirmPassword: "Bekreft passord*",
      SignupCreateProfile:
        "Ved å klikke på Opprett profil -knappen godtar du EzyGo's",
      SignupTerms: " Vilkår og betingelser",
      SignupExistingUser: " Eksisterende bruker, vennligst",
      SignupCreateButton: "Oppdrett",

      // Query Page Norwegian Translation
      QueryFirstName: "Fornavn",
      QueryLastName: "Etternavn",
      QueryEmail: "E-post",
      QuerySelectDateTime:
        "Velg den mest praktiske datoen og klokkeslettet for samtalen.",
      QueryMessage: "Beskjed",
      QueryButton: " Send inn",

      // TFLGiftCard Page Norwegian Translation
      TFLAgentCardTitle: "Agentkort",
      TFLExperienceText:
        "Opplev personlig bekvemmelighet med agentkortet, laget for å matche dine ønsker. Få tilgang til det globalt, når som helst og hvor som helst. Nyt dette kortet som tilbyr 10–90 % merverdi, som kan brukes på tvers av nettverket vårt og partnerbedrifter. Konverter ubrukte saldoer til coins eller doner til globale initiativer, og forsikre deg om at hver krone teller mot en bedre verden!",
      PreValueText: "Forhånds-Agent-kort",
      TFLSecureProfile: "Sikre deg selv <br/> et agent kort nå!",
      TFLGIftAgentCard: "Gi et agentkort <br/> til noen spesiell!",
      TFLViewButton: "Se agentkort",
      TFLSomeGiftText:
        "Noen gaver er store, andre små, men de som gis fra hjertet er de aller største.",
      ReadLess: "Les mindre",
      ReadMore: "Les mer",

      // Order History Page Norwegian Translation
      OrderFoodHistory: "Mat historie",
      OrderProductHistory: "Produkt historie",
      OrderType: "Bestillings Type",
      OrderDate: "Bestillings Dato",
      OrderTime: "Bestillings Tid",
      OrderItemNumber: "Vare nummer",
      OrderTotal: "Totalt",
      OrderRestaurantDetails: "Restaurant Detaljer",
      OrderDetailsButton: "Bestillingsdetaljer",
      CustomerDetails: "Kundedetaljer",
      OrderName: "Navn",
      OrderEmail: "E-Post",
      OrderMobileNumber: "Mobilnummer",
      OrderAddress: "Adresse",
      ProductsDetail: "Produktdetaljer",
      OrderProductsName: "Produktnavn",
      OrderQuantity: "Antall",
      OrderPrice: "Pris",
      OrderItemName: "Gjenstandsnavn",
      OrderDriver: "Førerens navn:",
      OrderDriverNumber: "Sjåførens kontaktnummer",
      OrderNoYet: "Du har ikke bestilt ennå!",
      OrderButton: "BESTILL",
      OrderProduct: "Total produktpris:",
      OrderTax: "MVA :",
      OrderFee: "App-avgift :",
      OrderTotalToPay: "Totalt å betale :",
      OrderVat: "App-av. mva:",
      OrderCharge: "Utkjøring Pris:",
      OrderDeliveryVat: "Utkjøring mva:",
      OrderDiscount: "Rabatt:",
      OrderAmount: "Totalt beløp",

      // A-Board Page Norwegian Translation
      AboardArchive: "Arkiv",
      AboardPersonalProfile: "Personlig Profil",
      AboardTFLCoins: "TFL Coins",
      AboardAgentProfile: "AgentProfil",
      AboardMobileAgent: "Agenten din",

      //Profile in A-Board Page Norwegian Translation
      ProfileEditProfile: "Rediger profil +",
      ProfilePreferences: "Preferanser",
      ProfileChangePassword: "Endre passord",
      ProfilePrivacyPolicy: "Personvernerklæring",
      ProfileTermsConditions: "Vilkår og betingelser",
      ProfileLogoutButton: "Logg ut",

      //Edit Profile
      ChooseGender: "Velg kjønn",
      SexMale: "Mann",
      SexFemale: "Kvinne",
      SexOther: "Annen",

      //TFL Coin Exchange Page Norwegian Translation
      TFLExchangeGift: "Få Agentkort med TFL-Coins dine!",
      ExchangeAvailableCoins: "Tilgjengelige Coins",
      ExchangeUsedCoins: "Brukte Coins",
      ExchangeTotalCoins: "Totalt opptjente coins",
      ExchangeCoinsUpdate:
        "*Coins dine vil bli oppdatert innen én time etter ordrebekreftelse",
      ExchangeRedemptionScheme: "Innløsningsordning",
      ExchangeGiftCard: "Agentkort i NOK",
      ExchangeNumberCoins: "Antall Coins",
      ExchangeAction: "Handling",
      ExchangeRedeem: "Løs inn",
      ExchangePleaseVisit: "For mer info besøk",
      MoreEarnCollect: "Jo mer du samler. Jo mer tjener du!",
      ExchangePopUp1: "Vil du løse inn",
      ExchangePopUp2: "coins for",
      ExchangePopUp3: "NOK Agentkort",
      ExchangeMessage:
        "Dette nummeret har allerede kjøpt maksimalt 3 Agentkort.",

      //Edit Profile Page Norwegian Translation
      EditUploadPicture: "Last opp bilde",
      EditDateBirth: "Velg fødselsdato*",
      EditGender: "Kjønn*",
      UpdateProfile: "Oppdater profil",

      //Change Password Page Norwegian Translation
      ChangePasswordTitle: "Bytt passord",
      OldPassword: "Gammelt Passord*",
      NewPassword: "Nytt passord*",
      ConfirmNewPassword: "Bekrefte Nytt Passord*",
      UpdatePassword: "Oppdater passord",
      ConfirmYourPassword: "Vennligst bekreft passordet ditt!",

      // Share Refferal Page Norwegian Translation
      ShareInviteFriendsTitle: "Inviter venner til EzyGo!",
      ShareInviteFriendsText:
        "Inviter vennene dine til EzyGo og få coins kreditert etter å ha lagt inn sin første bestilling. Og la dem tjene coins på SignUp.",
      ShareRefferalCode: "Din henvisningskode er. ",
      ShareReferEarn: "Jo mer du henviser, jo mer tjener du!",
      ShareFriends:
        "Du vet at du elsker oss, det vil vennene dine også gjøre. <br/> Del det og del moroa.",

      //Change Password Page Norwegian Translation
      ProductOpen: "Åpner",
      ProductDiscount: "Rabatt opp til:",
      ProductClosed: "Stengt",

      //  Card order button page Norwegian Translation
      CardMoreButton: "Legg til mer",
      CartOrderProceed: " Fortsett",

      //  Total Tax Card page Norwegian Translation
      TotalBillDetails: "Fakturadetaljer",
      TotalSubtotal: "Subtotal",
      TotalTaxTitle: "Mva",
      TotalDiscount: "Rabatt",

      // Footer page Norwegian Translation
      FooterSaveProceed: "Lagre og fortsett",

      // ComingSoon page Norwegian Translation
      ComingDownload: "Last ned appen for flere fordeler!",

      // ShortCut to all page Norwegian Translation
      ShortCutOrders: "Bestillinger",
      ShortCutEditProfile: "Rediger profil",
      ShortCutAgentProfile: "Agentprofil",
      ShortCutFoodOrder: "Matbestilling Historie",
      ShortCutOrderHistory: " Produkt Bestillingshistorikk",
      ShortCutBuy: "Kjøp til deg selv",
      ShortCutBuyOthers: "Kjøp for andre",
      ShortCutSee: "Se Agent Kort",
      ShortCutInformation: "Mer informasjon",
      ShortCutPrivacy: "Personvernerklæring",
      ShortCutTerms: "Vilkår og betingelser",

      // TFL Agent Card to MySelf page Norwegian Translation
      TFLGiftName: "Navn",
      TFLGiftEmail: "E-post",
      TFLGiftMobile: "Mobilnummer",
      TFLGiftAmount: "Velg et beløp* (NOK)",
      TFLGiftBuying: "Kjøpsbeløp",
      TFLGiftReceiving: "Mottakbeløp",
      TFLGiftMonthly: "Månedlig bruk (%)",
      TFLGiftValidity: "Gyldighet (måneder)",
      TFLGiftBuyButton: "Kjøp",
      TFLLimitReached3:
        "Du har nådd grensen – tre agentkort er allerede kjøpt!",
      CouponSent: "Kupongen ble sendt inn!",
      StartYourJourney: "Start reisen med Agentkort!",
      CollectMoreCard: "Hvorfor stoppe her? Samle mer!",
      YouHave2Card: "Du har 2 Agentkort!",
      YouHave3Card: "Gratulerer! Du har 3 Agentkort!",
      SelectValidCardValue: "Vennligst velg en gyldig Agentkort verdi.",

      // TFL Agent Card for others page Norwegian Translation
      TFLOthersRecipients: "Mottakers navn",
      TFLOthersMobile: "Mottakers mobilnummer",
      TFLOtherEmail: "Mottakers e-post",
      TFLOtherYourEmail: "Din e-post",
      TFLOtherYourMobile: "Ditt Mobilnummer",
      CouponSubmittedSuccess: "Kupongen ble sendt inn!",
      TFLOtherLimitReached3:
        "Din kjære har allerede 3 Agentkort. Vennligst prøv igjen senere!",

      YouHaveReachedLimit: "Du har nådd grensen – ikke flere kan legges til!",
      LoginTo: "Logg inn for å",

      // Show TFL Agent Card page Norwegian Translation
      ShowTFLHide: "Skjul kode",
      ShowTFLView: "Se Kode",
      ShowTFLValid: "GJELDER TIL",
      ShowTFLMonth: "MÅNED",
      ShowTFLLimit: "BEGRENSE",
      ShowTFlUsed: "BRUKT",
      ShowTFLBalance: "BALANSE",
      ShowTFLCoins: "COINS KONVERTERT",

      // Invite List page Norwegian Translation
      InviteSalonList: "Salong List",
      InviteNearby: "I nærheten",
      InviteSalon: " Salong",
      InviteNoData: "Ingen data funnet",
      InviteName: "Navn",
      InviteAction: "Handling",
      InviteCommonRestBtn: "Begynn å invitere Restaurants nå",
      InviteCommonSalonBtn: "Begynn å invitere Salons nå",
      InviteCommonQuestion: "Mangler din favoritt",
      InviteCommonLine1: "Last ned EzyGo, og sjekk den ut!",
      InviteCommonLine2:
        "Vær rask - inviter, og la Agenten din inntjene belønninger for deg!",
      InviteCommonLine3Start: "Når du hjelper din",
      InviteCommonLine3End:
        "med å bli oppført i vår Agent-plattform, så vil du bli belønnet fra din Agent med Coins av ekte verdi.",
      InviteCommonPoint1:
        "Agenten din tjener inn coins for hver vellykket invitasjon etter godkjent transaksjon.",
      InviteCommonPoint2:
        "Spor Agentens inntekter enkelt i den digitale lommeboken.",
      InviteCommonPoint3:
        "Større og bedre belønninger for unike invitasjoner og handlinger.",
      InviteCommonPoint4: "Spennende og fristende bonuser vil komme snart!",
      InviteCommonLastLine:
        "Begynn invitering nå, og gjør om anbefalingene dine til ekte belønninger gjennom din agent.",

      // Reserve Table page Norwegian Translation
      ReserveExplore: "Utforsk også",
      ReserveDrivePickup: "Utkjøring/ Henting",

      // TFL Market Cart page Norwegian Translation
      TFLMarketCloseButton: "Lukke",
      TFLMarketReview: "Se gjennom bestillingen din",
      TFLMarketOffers: "Tilbud og fordeler",
      TFLMarketApplyCard: "Bruk Agentkort",
      TFLMarketBill: "Fakturadetaljer",
      TFLMarketShipping: "Frakt Pris",
      TFLMarketPrice: "Pris",
      TFLMarketAmount: "Agentkortbeløp",
      TFLMarketNoRegister:
        "Det er ikke registrert noe <br/> agentkort på kontoen din.",
      TFLMarketSteps: "Trinn for å kjøpe Agentkort:",
      TFLMarketGoAgent: "Gå til agentkort-seksjon.",
      TFLMarketExplore: "Utforsk alternativene og velg agentkortet du ønsker.",
      TFLMarketComplete:
        "Fullfør betalingsprosessen for å gjennomføre kjøp av agentkort.",
      TFLMarketTransaction:
        "Når transaksjonen er fullført, vil det forhåndsbetalte agentkortet ditt bli oppdatert og klart til bruk i alle deler av EzyGo-nettverket.",

      // TFL Order Summary page Norwegian Translation
      TFLOrderFailed: "Å nei, bestillingen mislyktes",
      TFLOrderBack: "Tilbake til handlekurven",
      TFLOrderExplore: "Utforsk flere produkter",

      // App Navbar page Norwegian Translation
      AppNavbarUs: "Oss",
      AppNavbarPartner: "Samarbeidspartnere",
      AppNavGiftCard: "TFL Agentkort",

      // Cards page Norwegian Translation
      CardsRestaurantExplore: "Restauranter å utforske",
      CardsWhatinMind: "Hva tenker du på?",

      // Order Products Page Norwegian Translation
      OrderProductInstructions: "Bruksanvisning : ",

      // Drive Out Form Page Norwegian Translation
      DriveOutAdrress: "Adresse",
      DriveOutInput: "Vennligst skriv inn adressen din",
      DriveFormCity: "By",
      DriveOutCity: "Vennligst skriv inn din by",
      DriveOutState: "Stat",
      DriveOutEnterState: "Vennligst skriv inn staten din",
      DriveOutEnterPin: "Skriv inn PIN-koden din!",
      DriveOutCountry: "Country",
      DriveOutEnterCountry: "Vennligst skriv inn landet ditt!",

      // Form Main Detail Page Norwegian Translation
      FormMainName: "Skriv inn ditt Fornavn!",
      FormMainLastName: "Skriv inn etternavnet ditt!",
      FormMainEmail: "Vennligst skriv inn e-posten din!",
      FormMainNumber: "Mobil",

      // Form Main Detail Page Norwegian Translation
      MapFormVaerifyButton: "Bekrefte",
      SideCartPayment: "Innbetaling",
      SideCartOrder: "Rediger rekkefølge",
      SideCartReview: "Se gjennom bestillingen din",
      SideCartCharge: "Utkjøring Pris",
      SideCartBillPay: "Regning å betale",
      SideCartAlertMessage:
        "På grunn av at sjåføren ikke er tilgjengelig, kan vi ikke fortsette med Utkjørings Ordrer.",
      SideCartDeleteCoupon: "Slett kupong",
      SlideCartOrderButton:
        "Klikk på Behandle bestilling-knappen for å bestille.",
      SideCartUnavalaible:
        "På grunn av at sjåføren ikke er tilgjengelig, kan vi ikke fortsette med Utkjørings Ordrer.",
      ProcessOrderButton:
        "Klikk på Behandle bestilling-knappen for å bestille.",
      SideCartPosition: "Posisjonen din er for langt for levering!",
      ClickProcessOrder: "Klikk Behandle bestilling for å bestille.",
      MinimumOrderMustBe: "Minimumsbestillingen må være",
      AddMoreItemToPlaceOrder:
        "Legg til flere varer for å legge inn bestillingen.",

      // Business Form Page Norwegian Translation
      BusinessGetButton: "Ta kontakt med oss",
      BusinessRestaurantName: "Restaurantnavn",
      BusinessRestaurantType: "Restauranttype",
      BusinessWebsite: "Nettsted (hvis noen)",
      BusinessPosition: "Din stilling",
      BusinessCountry: "Fylke",
      BusinessAddress: "Skriv adressen din",
      BusinessMessage: "Skriv din melding/tilleggsinformasjon her",
      BusinessAgree: "Ved å klikke godtar og godtar jeg ",
      BusinessPrivacy: "Personvern",
      CancelButton: "Avbryt",
      BusinessContacting: "Tusen takk for at du kontakter oss!",

      // Contact Form Page Norwegian Translation
      ContactMandatory: "Dette feltet er obligatorisk",

      // Form Page Norwegian Translation
      FormCurrentEmployer: "Nåværende arbeidsgiver",
      FormCurrentPosition: "Nåværende posisjon",
      FormExperience: "Erfaring (i år)",
      FormOrgNumber: "Org nummer (hvis noen)",
      FormJobRole: "Jobbrolle",
      FormDriver: "Sjåfør",
      FormCook: "Kokk",
      FormWaiter: "Servitør",
      FormSeller: "Selger(For vårt selskap)",
      FormAdministration: "Administrasjon (For vårt selskap)",
      FormOtherPosition: "Hvilken andre stillinger du henser å Søke",

      // General Form Page Norwegian Translation
      GeneralFormSubmit: "Sendt inn!",
      GeneralFormUnexpected: "En uventet feil oppstod. Prøv igjen.",
      GeneralFormSubmitError:
        "Kunne ikke sende inn skjemaet. Vennligst prøv igjen.",
      GeneralEnterName: "Vennligst skriv inn fornavnet ditt.",
      ValidName: "Skriv inn en gyldig e-post",
      CountryCode: "Vennligst velg en landskode.",
      PhoneNumber: "Vennligst skriv inn ditt mobilnummer.",
      NumberError: "Vennligst skriv inn ditt mobilnummer.",
      TenDigits: "Mobilnummeret kan ikke være lengre enn 10 sifre.",

      //Suceess Coupon Purchase Page Norwegian Translation
      LoadingWait: "Laster Vennligst vent....",
      GIftCard: "Agentkortet er opprettet.",
      ThankYouMessage:
        "Takk Agentkortet ditt er generert. Vennligst sjekk E-post eller SMS sendt til Mobilnummeret ditt for bekreftelse.",
      GiftNotCreated: "Et Agentkort er ikke opprettet.",
      SorryGIftNotCreated:
        "Vi er lei oss. men Agentkortet ditt er IKKE generert",
      TryAgain: "Vennligst prøv igjen",

      //Header Page Norwegian Translation
      HeaderDriveOut: "Utkjøring",
      HeaderPickup: "Henting",
      HeaderDelivery: "Levering",

      // Top Bar Page Norwegian Translation
      SomethingWrong: "Noe gikk galt!",

      //Apply Coupon Modal Page Norwegian Translation
      InvalidCode: "Koden er ugyldig eller utløpt",
      GiftCardCode: "Vennligst skriv inn en Agentkortkode",
      CouponSomethingWrong: "Noe gikk galt. Prøv igjen senere.",
      MonthlyBalance:
        "Saldoen din er fullt brukt denne måneden. Følg med – nye fordeler kommer neste måned!",
      ApplyUseCode: "Use code",
      AgentCardRegistration:
        "Det er ikke noe agentkort <br/> registrert på din konto.",
      ApplyBuyAgentCard: "For å kjøpe et agentkort (registrer deg)",
      ApplyContinue: "Fortsett her",
      "Transaction Complete":
        "Når transaksjon er fullført, blir ditt forhåndsbetalte agentkort oppdatert og klart til bruk i alle deler av EzyGo-nettverket.",

      // Location Modal Page Norwegian Translation
      OkButton: "Greit",

      //Modal Page Norwegian Translation
      FollowAlong: "Følg med",
      ModalComingSoon: "Vi kommer snart, til din by!",

      // Modal1 Slide Page Norwegian Translation
      ModalActivateTitle: "Aktiver EzyGo AI Agent",
      ModalRegister: "Registrer deg på EzyGo-nettverket.",
      ModalSetup:
        "Sett opp din personlige AI-agent for å begynne å tjene automatisk.",
      ModalActivate:
        "Aktiver agenten din og la den fungere for deg uten problemer",
      ModalAgentWork: "La agenten din jobbe for deg",
      ModalExplore: "Utforsk EzyGo-nettverket med AI-agenten din.",
      ModalAccess:
        "Få tilgang til tjenester og samarbeid med andre EzyGo-agenter.",
      ModalEarnTFL: "Tjen TFL Agent Coins for hver handling og samarbeid.",
      ModalEarnCoins: "Tjen Coins for hver handling",
      ModalCollectCoins: "Samle TFL-Coins for hver oppgave du fullfører.",
      ModalStayActive: "Hold deg aktiv for å maksimere inntektene dine.",
      ModalSaveCoins:
        "Spar Coins opp til innløsningsgrensen for å oppfylle dine behov og ønsker.",
      ModalBuyGift: "Kjøp eller gi e-kort med forhåndsverdi",
      ModalPurchase: "Kjøp agent-e-kort med forhåndsverdi for ekstra fordeler.",
      ModalGiftAgent: "Gaveagent e-kort til andre og nyt delte besparelser.",
      ModalSpend:
        "Bruk klokt og tjen ekstra Coins på hver transaksjon med Agent E-kortet ditt.",
      ModalHold: "Hold og løs inn for større belønninger",
      ModalLonger:
        "Jo lenger du holder på the Coins, desto større blir belønningene.",
      ModalRedeem: "Løs inn lagrede Coins for eksklusive tilbud og fordeler",
      ModalCollecting:
        "Fortsett å samle inn, fortsett å løse inn og nyt livstidsfordeler!",
      ModalID: "Ugyldig modal ID",
      CloseButton: "Lukke",

      // Online Order New Page Norwegian Translation
      OnlineBrowser: "Nettleseren din støtter ikke videokoden!",
      OnlineHello: "Hallo",
      WelcomeTitle: "Velkommen til EzyGo!",
      OnlineBenefits: "For flere fordeler",
      OnlineYou: "deg eller",
      OnlineEarnCoins: "Tjen coins for hver handling",
      OnlineService: "E-tjeneste",
      // OnlineFood: "Bestill mat",
      OnlineMarket: "E-marked",
      OnlineDiscover: "Oppdag mer med EzyGo!!",

      //Confirm Product Modal Page Norwegian Translation
      AddButton: "Legg til",
      SeeDescription: "Se Beskrivelse",
      ConfirmIngredients: "Ingredienser",
      AddComment: "Legg til kommentar (valgfritt)",

      //Confirm Product Page Norwegian Translation
      ConfirmNotOpen: "Vi er ikke åpne ennå!",

      //Product Page Norwegian Translation
      ProductDescription: "Beskrivelse",

      //Order now Button Page Norwegian Translation
      OrderButtonNow: "Bestill Nå",

      //Order Summary Page Norwegian Translation
      SummeryOrderPlaced: "Bestillingen ble lagt inn",
      SummaryFoodOrder:
        "Vi bekrefter gjerne din nylige matbestilling. Du vil motta ordrebekreftelsen på e-post og SMS som inneholder OTP og angi denne OTP på kvittering. IKKE del denne engangskoden med noen andre.",
      SummaryInformation: "Brukerinformasjon",
      SummaryBackWebsite: "Tilbake til nettsiden",
      SummaryOrderInformation: "Bestillingsinformasjon",
      SummaryBackWebsitePage: "Tilbake til betalingssiden",
      SummaryExploreMore: "Utforsk flere restauranter",
      SummaryOrderFailedText:
        "Vi beklager å måtte informere deg om at det var et problem med din nylige matbestilling gjennom Tree Drive Navn på plattform/restaurant. Vi beklager evt ulempe dette kan ha medført.",

      //Success Coupon Page Norwegian Translation
      SuccessThankYou:
        "Takk for bestillingen. Bestillingen din er fullført. Sjekk e-posten din eller meldingen sendt til mobilnummeret ditt.",
      SuccessThankYouOrder:
        "Du må angi OTP på restauranten for å hente bestillingen. Vi har også sendt engangsbestillingen til din e-postadresse.",
      SuccessReturnHome: "Gå tilbake til startsiden",
      SuccessOrderPlaced: "Din bestilling er ikke plassert",

      // Verify Page Norwegian Translation
      VerifyMobileNumber: "Mobilnummer og bekreftelseskode kreves.",
      VerifyAccount: "Bekreft kontoen din",
      VerifyEnterCode:
        "Vennligst skriv inn bekreftelseskoden du har mottatt på ditt registrerte mobilnummer.",
      VerifyAccountButton: "Verifiser konto",

      // Info Modal Page Norwegian Translation
      InfoValidValue: "Vennligst skriv inn en gyldig verdi",
      InfoValidName: "Vennligst skriv inn gyldig mottaker navn.",
      InfoValidEmail: "Vennligst skriv inn en gyldig e-mail adresse",
      VerifyEmailMatch: "E-post samsvarer ikke",
      InfoValidNumber: "Vennligst oppgi et gyldig mobilnummer.",
      InfoConfirmNumber: "Bekreft Mobilnummer",
      InfoNumberMatch: "Mobilnummer samsvarer ikke",
      InfoEzyGoApp:
        "Kjøp et kort via EzyGo-appen eller nettstedet med ønsket beløp.",
      InfoBuyers:
        "Kjøperen mottar en e-post og sms med en unik kode, samtidig kan se kortet i appen.",
      InfoLoaded:
        "Den er lastet med beløpet du betaler eller mer avhengig av tilbudet på det tidspunktet.",
      InfoCardCode:
        "Bruk kort-koden når du bestiller matvarer fra en av A-Boards medlemsrestauranter eller på Tree drive-plattformer (app eller nettstedet)",
      InfoBuyCard: "Kjøp Agentkort",
      InfoTreeGift:
        "* Tree for life (TFL) Agentkort er en måte å kjøpe matvarer til deg selv eller gi en gave til dine kjære. Den er forhåndslastet med ønsket pengebeløp. Kortet kan brukes når du bestiller online eller hos våre medlemsrestaurantene.",

      // Location Modal Page Norwegian Translation
      LocationConfirmButton: "Bekreft adresse"
    }
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: "no", // default language
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
