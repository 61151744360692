import { createSlice } from "@reduxjs/toolkit";

// Initial state for the categories
const initialState = {
    categories: [],  // This will store the categories
    loading: false,  // Loading state for fetching categories
    error: null,     // Error state if fetching fails
};

const aBoardProductSlice = createSlice({
    name: "aBoardProduct",  // Name of the slice
    initialState,
    reducers: {
        // Action when categories are being fetched
        fetchCategoriesStart: (state) => {
            state.loading = true;
            state.error = null;  // Reset previous error
        },
        // Action when categories are successfully fetched
        fetchCategoriesSuccess: (state, action) => {
            state.categories = action.payload;  // Store the fetched categories
            state.loading = false;  // Set loading to false
        },
        // Action when fetching categories fails
        fetchCategoriesFailure: (state, action) => {
            state.loading = false;  // Stop loading
            state.error = action.payload;  // Store the error message
        },
    },
});

// Export the actions
export const {
    fetchCategoriesStart,
    fetchCategoriesSuccess,
    fetchCategoriesFailure,
} = aBoardProductSlice.actions;

// Export the reducer to be added in the store
export default aBoardProductSlice;
