import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import { isMobile } from "react-device-detect";
import { Row, Col, message } from "antd";
import { Button, Form, FloatingLabel } from "react-bootstrap";
import axios from "axios";
import { userAction } from "../../store/slices/userslices";
import BottomFooter from "../Footer/BottomFooter";
import contactUs from "../../assets/Doll Web/Tara (4).png";

import { LeftCircleOutlined } from "@ant-design/icons";

// Import for Language translater
import { useTranslation } from "react-i18next";
export default function ChangePass() {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  const navigate = useNavigate();
  let [alertMsg, setAlertMsg] = useState("");
  let dispatch = useDispatch();
  let user = useSelector((state) => state.userReducer);
  const validateForm = (valid_inputs) => {
    for (let i = 0; i < valid_inputs.length; i++) {
      if (valid_inputs[i] == "") {
        return false;
      }
    }
    return true;
  };
  const updatePassword = async (e) => {
    e.preventDefault();

    let oldPassword = e.target.inputOldPassword.value;
    let newPassword = e.target.inputNewPassword.value;
    let conPassword = e.target.inputConPassword.value;

    let inputFields = [oldPassword, newPassword, conPassword];

    if (!validateForm(inputFields)) {
      // setAlertMsg("Alle felt er obligatoriske.");
      message.error(t("AllMandatoryFields"));
    } else {
      if (newPassword != conPassword) {
        message.warning(t("ConfirmNewPassword"));
        return;
      }

      message.warning(t("VerifyPleaseWait"));

      let formData = {
        oldPassword: oldPassword,
        newPassword: newPassword,
        userId: user._id
      };

      await axios
        .post(`${apiUrl}${tDriveEndpoint}appuser/updatepassword`, formData)
        .then((response) => {
          if (response.data.status == 200) {
            message.success(t("PasswordIsUpdated")); // Now this should work
            // setAlertMsg("Passordet er oppdatert, vennligst logg på igjen!");
            refreshAfterLogout();
            window.location.reload();
          } else {
            message.warning(response.data.message);
            // setAlertMsg(response.data.message);
          }
        })
        .catch((error) => {
          console.log("---error user---", error.response.data.message);
        });
    }
  };
  const refreshAfterLogout = () => {
    dispatch(userAction.removeUser());

    // Ensure Redux state is updated before navigating
    setTimeout(() => {
      navigate("/login");
    }, 100); // Small delay to allow Redux state update
  };
  const content = () => {
    return (
      <Form noValidate onSubmit={updatePassword}>
        <Form.Group className="row mb-3" controlId="formBasicPassword">
          <Col>
            <FloatingLabel
              controlId="floatingInputGrid"
              label={t("OldPassword")}
            >
              <Form.Control required type="password" name="inputOldPassword" />
            </FloatingLabel>
          </Col>
        </Form.Group>
        <Form.Group className="row mb-3" controlId="formBasicPassword">
          <Col sm={24}>
            <FloatingLabel
              controlId="floatingInputGrid"
              label={t("NewPassword")}
            >
              <Form.Control required type="password" name="inputNewPassword" />
            </FloatingLabel>
          </Col>
        </Form.Group>
        <Form.Group className="row mb-3" controlId="formBasicPassword">
          <Col sm={24}>
            <FloatingLabel
              controlId="floatingInputGrid"
              label={t("ConfirmNewPassword")}
            >
              <Form.Control
                required
                type="password"
                placeholder=""
                name="inputConPassword"
              />
            </FloatingLabel>
          </Col>
        </Form.Group>

        <Row>
          <span style={{ color: "#333" }}>{alertMsg}</span>
        </Row>

        <Row className="justify-content-center mt-2">
          <Button variant="primary" type="submit" className="width-100">
            {t("UpdatePassword")}
          </Button>
        </Row>
      </Form>
    );
  };
  return (
    <>
      {isMobile ? (
        <Row className="vh90andScroll flex-column px-3 ">
          <Row className="justify-content-between  my-3">
            <h3 class="font-24 font-700 mb-0">{t("ChangePasswordTitle")}</h3>
            <LeftCircleOutlined
              onClick={() => navigate("/profileInAboard")}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "30px"
              }}
            />
          </Row>
          {content()}
        </Row>
      ) : (
        <section
          className="mission profilePages d-flex align-items-center justify-content-center webLoginSec"
          style={{ padding: "100px 5% 50px", minHeight: "80vh" }} // Adjusted height for balance
        >
          <Row
            className="px-5 width-100"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%"
            }}
          >
            {/* Left Side - Text and Image */}
            <Col
              sm={12}
              lg={10}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center"
              }}
            >
              <img
                src={contactUs}
                alt="contactUs"
                style={{
                  width: "80%",
                  maxWidth: "400px",
                  height: "auto",
                  objectFit: "contain"
                }}
              />
            </Col>

            {/* Right Side - Login Form */}
            <Col
              sm={12}
              lg={10}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
              className="width-100"
            >
              <div className="commonShadowAndRound p-5 width-100">
                <Row className="justify-content-between mb-5">
                  <h3 class="font-24 font-700 mb-0">
                    {t("ChangePasswordTitle")}
                  </h3>
                  <LeftCircleOutlined
                    onClick={() => navigate("/profileInAboard")}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "30px"
                    }}
                  />
                </Row>{" "}
                {content()}
              </div>
            </Col>
          </Row>
        </section>
      )}
      <BottomFooter />
    </>
  );
}
