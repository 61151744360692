import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  message,
  Modal,
  Form,
  Select,
  DatePicker,
  Button,
  List,
  Divider,
  Spin,
  InputNumber
} from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { DeleteOutlined } from "@ant-design/icons";
import { ShoppingCartOutlined, CloseOutlined } from "@ant-design/icons";
import { apiUrl, s3url } from "../../helper/endpoint";
import moment from "moment";
import BottomFooter from "../Footer/BottomFooter";
import { useSelector } from "react-redux";
import "./salon.css";

export default function Salons() {
  const { Meta } = Card;
  const { Option } = Select;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  let user = useSelector((state) => state.userReducer);
  const branchId = localStorage.getItem("branchId")?.replace(/^"|"$/g, "");
  const userID = user._id;

  const [staffOptions, setStaffOptions] = useState([]);
  const [serviceProductOptions, setServiceProductOptions] = useState([]);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [selectedInnerProduct, setSelectedInnerProduct] = useState(null);
  const [productOptions, setProductOptions] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [selectedStaffId, setSelectedStaffId] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [serviceCartItems, setServiceCartItems] = useState([]);
  const [productCartItems, setProductCartItems] = useState([]);
  const [staffAvailability, setStaffAvailability] = useState([]);
  const [totalProductPrice, setTotalProductPrice] = useState(0);
  const [totalServicePrice, setTotalServicePrice] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [taxValue, setTaxValue] = useState(0);
  const [doNotCHangeTax, setDoNotCHangeTax] = useState(0);
  const [barbers, setBarbers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  useEffect(() => {
    const savedServiceCart = localStorage.getItem("serviceCartItems");
    const savedProductCart = localStorage.getItem("productCartItems");
    if (savedServiceCart) {
      setServiceCartItems(JSON.parse(savedServiceCart));
    }

    if (savedProductCart) {
      setProductCartItems(JSON.parse(savedProductCart));
    }
  }, []);
  useEffect(() => {
    if (selectedDate) {
      const filteredSlots = staffAvailability.filter((availability) =>
        moment(availability.date).isSame(selectedDate, "day")
      );
      const slots = filteredSlots.length > 0 ? filteredSlots[0].timeSlots : [];

      setAvailableSlots(slots);
    }
  }, [selectedDate, staffAvailability]);
  useEffect(() => {
    const fetchBarbers = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/api/v1/barber/barberList`);
        setBarbers(response.data);
        setLoading(false);
        localStorage.setItem(
          "branchId",
          JSON.stringify(response.data.data[0]._id)
        );
        localStorage.setItem("branchName", response.data.data[0].name);
      } catch (error) {
        message.error(error.message || "Kunne ikke laste inn barberlisten.");
        setLoading(false);
      }
    };
    if (!barbers.length) {
      fetchBarbers();
    }
  }, []);

  useEffect(() => {
    const fetchStaffAndServices = async () => {
      setIsLoading(true);
      const payload = { branchId };

      try {
        const response = await axios.post(
          `${apiUrl}/api/v1/barber/productAndServices`,
          payload
        );

        const empData = response.data.data.employeeList;
        const tax = response.data.data.tax;
        const productData = response.data.data.productList || [];
        setStaffOptions(
          empData.map((barber) => ({
            firstName: barber.firstName,
            availabilityStatus: barber.availabilityStatus,
            availability: barber.availability,
            id: barber.id,
            imageUrl: barber.profileImage,
            services: barber.services || []
          }))
        );
        setProductOptions(productData);
        setDoNotCHangeTax(tax);
        setTaxValue(tax);
      } catch (error) {
        message.error("Failed to fetch staff. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };
    if (branchId) {
      fetchStaffAndServices();
    }
  }, [branchId]);

  const handleStaffSelect = (staff) => {
    setSelectedStaff(staff.firstName);
    setSelectedStaffId(staff.id);
    setStaffAvailability(staff.availability);
    setServiceProductOptions(
      staff.services.map((service) => service.categoryName)
    );

    setIsModalOpen(true);
  };

  const handleServiceChange = (serviceName) => {
    const selected = staffOptions
      .flatMap((staff) => staff.services)
      .find((service) => service.categoryName === serviceName);

    if (selected && selected.products) {
      setServiceProductOptions(selected.products);
      setSelectedProducts();
      setTotalPrice(0);
    } else {
      setProductOptions([]);
    }

    setSelectedService(serviceName);
    form.setFieldsValue({ date: null });
  };

  const handleServiceProductChange = (selectedProducts) => {
    const data = selectedProducts.map((product) => ({
      id: product?._id,
      productName: product?.name,
      productPrice: product?.priceInc,
      quantity: 1,
      categoryId: product?.categoryId._id,
      prodTax: product?.taxPercentage || "",
      unitType: product?.unitType || "",
      availQuantity: "1",
      offerPrice: product?.offer?.offerPrice || "",
      prodHasOffer: product?.prodHasOffer || false,
      price: product?.priceInc
    }));

    setSelectedProducts(data);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const handleProductChange = (product, action) => {
    if (!product || !product._id || !product.name || !product.priceInc) {
      console.error("Invalid product:", product);
      return;
    }

    let updatedCartItems = [...productCartItems];

    // Find existing product
    const existingProductIndex = updatedCartItems.findIndex(
      (item) => item.id === product._id
    );

    if (existingProductIndex !== -1) {
      let updatedProduct = { ...updatedCartItems[existingProductIndex] };
      if (action === "increase") {
        updatedProduct.quantity += 1;
        updatedCartItems[existingProductIndex] = updatedProduct;
      } else if (action === "decrease") {
        updatedProduct.quantity -= 1;
        if (updatedProduct.quantity <= 0) {
          updatedCartItems.splice(existingProductIndex, 1);
        } else {
          updatedCartItems[existingProductIndex] = updatedProduct;
        }
      }
    } else {
      if (action === "increase") {
        updatedCartItems.push({
          id: product._id,
          productName: product.name,
          productPrice: product.priceInc,
          quantity: 1,
          categoryId: product.categoryId,
          prodTax: product.taxPercentage || "",
          unitType: product.unitType,
          availQuantity: "1",
          offerPrice: "",
          prodHasOffer: product.prodHasOffer || false,
          price: product.priceInc
        });
      }
    }

    console.log("After update:", updatedCartItems);
    setProductCartItems([...updatedCartItems]);
    calculateTotals();
    localStorage.setItem("productCartItems", JSON.stringify(updatedCartItems));
  };

  const openInnerProductModal = (product) => {
    setSelectedInnerProduct({
      products: product.products,
      categoryName: product.name
    });
    setIsModalVisible(true);
  };

  // **Reusable Function for Total Calculation**
  const calculateTotal = (items) => {
    return items.reduce(
      (sum, item) => sum + (item.productPrice * item.quantity || 0),
      0
    );
  };

  const calculateTotals = () => {
    const productCartItems =
      JSON.parse(localStorage.getItem("productCartItems")) || [];
    const serviceCartItems =
      JSON.parse(localStorage.getItem("serviceCartItems")) || [];

    const allItems = [...productCartItems, ...serviceCartItems];

    const totalPrice =
      allItems.reduce((sum, item) => {
        const price = item.productPrice || 0; // Default 0 if undefined
        const quantity = item.quantity || 1; // Default 1 if undefined
        return sum + price * quantity;
      }, 0) || 0;

    const taxRate = 15; // 15% Tax Rate
    const subTotal = (totalPrice / (1 + taxRate / 100)).toFixed(2);
    const tax = (totalPrice - subTotal).toFixed(2);

    setTotalPrice(totalPrice);
    setSubTotal(parseFloat(subTotal)); // Ensure it's a number
    setTaxValue(parseFloat(tax));

    const totalProduct = calculateTotal(productCartItems);
    const totalService = calculateTotal(serviceCartItems);

    setTotalProductPrice(totalProduct);
    setTotalServicePrice(totalService);
    setGrandTotal(totalProduct + totalService);
  };

  const handleAddToCart = (cartItem) => {
    const serviceItemsToCart = cartItem.selectedProducts;

    const updatedCartItems = [...cartItems, ...serviceItemsToCart];
    setCartItems(updatedCartItems);
    setServiceCartItems(serviceItemsToCart);
    localStorage.setItem("serviceCartItems", JSON.stringify(updatedCartItems));
    calculateTotals();
    setIsModalOpen(false);
  };

  const closeProductInnerModal = () => {
    setIsModalVisible(false);
    setSelectedInnerProduct(null);
  };

  const handleOrderNow = async () => {
    if (!serviceCartItems.length && !productCartItems.length) {
      return message.error("Your cart is empty.");
    }
    if (!userID) {
      return message.error("User ID is required.");
    }
    if (serviceCartItems.length && !selectedStaffId) {
      return message.error("Please select a staff member for the service.");
    }

    const formattedDate = selectedDate ? selectedDate.format("YYYY-MM-DD") : "";
    const selectedTimeSlotsValues = selectedTimeSlots.map(
      (slot) => slot.timeSlot
    );

    const orderData = {
      services: serviceCartItems,
      products: productCartItems,
      totalPrice: totalPrice,
      subTotal: subTotal,
      tax: taxValue,
      branchId: branchId,
      appointmentTime: selectedTimeSlotsValues,
      appointmentDate: formattedDate,
      isWebOrder: true,
      paymentMode: "Dintero",
      orderFor:
        productCartItems.length && serviceCartItems.length
          ? "BOTH"
          : serviceCartItems.length
          ? "SERVICES"
          : "PRODUCTS",
      employeeId: selectedStaffId || null,
      customerId: userID || null,
      orderFrom: "WEB",
      orderOfferApplied: false
    };

    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/barber/order/createBarberOrder`,
        orderData
      );
      const paymentUrl = response.data.data.paymentUrl?.url;
      if (paymentUrl) {
        window.location.href = paymentUrl;
      } else {
        message.error("Failed to retrieve payment URL.");
      }
    } catch (error) {
      message.error("Order creation failed.");
      navigate("/paymentfailure", {
        state: { errorDetails: t("PaymentFailedDueToNet") }
      });
    }
  };

  const clearCart = () => {
    setServiceCartItems([]);
    setProductCartItems([]);
    setSubTotal(0);
    setTaxValue(0);
    setTotalPrice(0);
    localStorage.removeItem("productCartItems");
    localStorage.removeItem("serviceCartItems");
    message.success("Cart has been cleared.");
    setCartVisible(false);
  };

  const handleTimeSlotChange = (selectedSlots) => {
    const selectedTimeSlotsDetails = availableSlots.filter((slot) =>
      selectedSlots.includes(slot.timeSlot)
    );

    setSelectedTimeSlots(selectedTimeSlotsDetails);
  };
  useEffect(() => {
    calculateTotals();
  }, [serviceCartItems, productCartItems]);
  const [view, setView] = useState("staff");
  const [cartVisible, setCartVisible] = useState(false);

  const [cartItems, setCartItems] = useState([]);

  const toggleView = () => setView(view === "staff" ? "products" : "staff");
  const salonNav = () => {
    return (
      <Row justify="space-between" align="middle" className="px-3 pt-3">
        <Button onClick={toggleView}>
          {view === "staff" ? "View Products" : "View Staff"}
        </Button>
        <Button
          icon={<ShoppingCartOutlined />}
          onClick={() => setCartVisible(!cartVisible)}
        >
          Cart ({serviceCartItems.length + productCartItems.length})
        </Button>
      </Row>
    );
  };
  const staffList = () => {
    return (
      <>
        {isLoading ? (
          <div className="loading-container">
            <Spin size="large" />
          </div>
        ) : (
          <>
            {staffOptions.map((staff, index) => (
              <Card
                className="d-flex mb-3"
                key={index}
                hoverable
                cover={
                  <img
                    alt={staff.firstName}
                    src={`${s3url}/${staff.imageUrl}`}
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover"
                    }}
                    className=" border-radius-5"
                  />
                }
                onClick={() => {
                  handleStaffSelect(staff);
                }}
              >
                <Meta title={staff.firstName} />
                <p>
                  {staff.availabilityStatus
                    ? "Available Today"
                    : "Not Available Today"}
                </p>
              </Card>
            ))}
          </>
        )}
      </>
    );
  };
  const staffModal = () => {
    return (
      <Modal
        title={`Book Appointment with ${selectedStaff}`}
        visible={isModalOpen}
        onCancel={handleModalCancel}
        footer={null}
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Select Service">
            <Select placeholder="Select Service" onChange={handleServiceChange}>
              {serviceProductOptions.map((service, index) => (
                <Option key={index} value={service}>
                  {service}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Select Products">
            {serviceProductOptions.length > 0 && (
              <Select
                mode="multiple"
                placeholder="Select Products"
                onChange={(e) =>
                  handleServiceProductChange(
                    serviceProductOptions.filter((product) =>
                      e.includes(product._id)
                    )
                  )
                }
              >
                {serviceProductOptions.map((product) => (
                  <Option key={product._id} value={product._id}>
                    {product.name} - Kr.{product.priceInc}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          {selectedService && (
            <Form.Item
              name="appointmentDate"
              label={t("SelectDate")}
              rules={[{ required: true, message: "Please choose a date" }]}
            >
              <DatePicker
                style={{ width: "100%" }}
                disabledDate={(current) =>
                  current && current.isBefore(moment().startOf("day"), "day")
                }
                onChange={handleDateChange}
              />
            </Form.Item>
          )}
          {selectedService && (
            <Form.Item
              label="Select Time Slot"
              name="appointmentTime"
              rules={[
                {
                  required: true,
                  message: "Please choose at least one time slot"
                }
              ]}
            >
              {availableSlots.length > 0 ? (
                <Form.Item
                  name="appointmentTime"
                  label="Select Time Slots"
                  rules={[
                    {
                      required: true,
                      message: "Please select at least one time slot"
                    }
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select Time Slots"
                    value={selectedTimeSlots.map((slot) => slot.timeSlot)}
                    onChange={(selectedSlots) =>
                      handleTimeSlotChange(selectedSlots)
                    }
                  >
                    {availableSlots.map((slot, index) => (
                      <Select.Option
                        key={index}
                        value={slot.timeSlot}
                        disabled={slot.isBooked}
                      >
                        {slot.timeSlot}{" "}
                        {slot.isBooked ? "(Booked)" : "(Available)"}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <p>{t("NoSlotsAvailable")}</p>
              )}
            </Form.Item>
          )}

          <Row justify="space-between" align="middle">
            <Button
              type="primary"
              onClick={() => {
                handleAddToCart({
                  selectedProducts
                });
              }}
            >
              Add Service to Cart
            </Button>
          </Row>
        </Form>
      </Modal>
    );
  };
  const productList = () => {
    return (
      <>
        {isLoading ? (
          <div className="loading-container">
            <Spin size="large" />
          </div>
        ) : (
          <>
            {productOptions.map((product, index) => (
              <Card
                key={index}
                className="service-card d-flex justify-content-between mb-3 border-radius-5"
                hoverable
                onClick={() => openInnerProductModal(product)}
                cover={
                  <img
                    alt={product.name}
                    src={`${s3url}/${product.image}`}
                    className=" border-radius-5"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover"
                    }}
                  />
                }
              >
                <Meta title={product.name} />
              </Card>
            ))}
          </>
        )}
      </>
    );
  };

  const productSelectModal = () => {
    return (
      <Modal
        title={selectedInnerProduct?.categoryName}
        visible={isModalVisible}
        onCancel={closeProductInnerModal}
        footer={null}
      >
        {selectedInnerProduct?.products &&
          selectedInnerProduct?.products.map((innerPro) => (
            <Card
              onClick={() => handleProductChange(innerPro, "increase")}
              key={innerPro._id}
              className="service-card d-flex justify-content-between mb-3"
              hoverable
              cover={
                <img
                  alt={innerPro.name}
                  src={`${s3url}/${innerPro.image}`}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover"
                  }}
                  className=" border-radius-5"
                />
              }
            >
              <Meta title={innerPro?.name} />
              <p>Kr. {innerPro?.priceInc}</p>
            </Card>
          ))}
      </Modal>
    );
  };
  const handleRemoveService = (serviceItem) => {
    const updatedServices = serviceCartItems.filter(
      (item) => item.id !== serviceItem.id
    );

    setServiceCartItems(updatedServices);
    localStorage.removeItem("serviceCartItems");

    message.success(`${serviceItem.productName} removed from cart`);
  };

  const cartList = () => {
    return (
      <List
        className="cardBodyCart"
        dataSource={[...productCartItems, ...serviceCartItems]}
        renderItem={(item) => (
          <List.Item key={`cart-${item?.id}`}>
            <Row className="width-100 justify-content-between">
              <Col span={8}>{item?.productName}</Col>
              <Col span={1}>*{item?.quantity}</Col>

              {/* <Col span={6} className="d-flex align-items-center gap-2">
                    <Button
                      onClick={() => handleProductChange(item, "decrease")}
                    >
                      -
                    </Button>
                    <InputNumber value={item.quantity} readOnly />
                    <Button
                      onClick={() => handleProductChange(item, "increase")}
                    >
                      +
                    </Button>
                  </Col> */}
              <Col span={5} className="text-right">
                Kr. {(item.productPrice * item.quantity).toFixed(2)}
              </Col>
              <Col span={24} className="mt-2">
                {" "}
                {productCartItems.some((prod) => prod.id === item.id) ? (
                  ""
                ) : (
                  <>
                    <Col span={6}>
                      <a
                        onClick={() => handleRemoveService(item)}
                        style={{
                          color: "Red",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px"
                        }}
                      >
                        <DeleteOutlined />
                        Remove Service
                      </a>
                    </Col>
                  </>
                )}
              </Col>
            </Row>
          </List.Item>
        )}
      />
    );
  };
  const cartBill = () => {
    return (
      <>
        {" "}
        {serviceCartItems.length || productCartItems.length ? (
          <div className="cart-footer">
            <Row>
              <a onClick={clearCart} style={{ color: "red" }}>
                <i>
                  <u>Clear Cart</u>
                </i>
              </a>
            </Row>
            <Divider />
            <Row>
              <Col span={12}>{t("TotalSubtotal")}:</Col>
              <Col span={12} className="text-right">
                Kr. {subTotal.toFixed(2)}
              </Col>
              <Col span={12}>
                {t("TotalTaxTitle")} ({doNotCHangeTax}%):
              </Col>
              <Col span={12} className="text-right">
                Kr. {taxValue.toFixed(2)}
              </Col>
              <Col span={12}>{t("OrderTotal")}:</Col>
              <Col span={12} className="text-right">
                Kr. {grandTotal.toFixed(2)}
              </Col>
            </Row>
            <br />
            {/* <Row justify="center">
              <Button style={{ background: "yellow" }} className="width-100">
                Apply Agent Card
              </Button>
            </Row>
            <br /> */}
            <Row justify="center">
              <Button
                type="primary"
                onClick={handleOrderNow}
                className="width-100"
              >
                {t("OrderButtonNow")}
              </Button>
            </Row>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };
  return (
    <>
      {!isMobile ? (
        <Row gutter={16} id="mainLayout" className="parent">
          <Col
            span={8}
            className={`full-height ${
              serviceCartItems.length ? "disabled-col" : ""
            }`}
          >
            <Card
              title={
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <span>Barber</span>
                  <small style={{ color: "#888" }}>
                    (You can select one barber at a time.)
                  </small>
                </div>
              }
              className="overflow-child border-radius-10"
            >
              {staffList()}
            </Card>
          </Col>

          <Col span={8} className="full-height">
            <Card title="Products" className="overflow-child border-radius-10">
              {productList()}
            </Card>
          </Col>
          <Col span={8} className="full-height">
            <Card
              title="Cart"
              extra={
                isMobile && (
                  <Button
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={() => setCartVisible(false)}
                  />
                )
              }
              className="overflow-child border-radius-10 cart-container"
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <div style={{ flexGrow: 1, overflowY: "auto" }}>{cartList()}</div>

              <div style={{ paddingTop: "10px" }}>{cartBill()}</div>
            </Card>
          </Col>
        </Row>
      ) : (
        <div className="vh90andScroll position-relative">
          {salonNav()}
          <Row gutter={16} className="p-3">
            {view === "staff" && (
              <Col
                span={24}
                className={`overflow-child border-radius-10 cart-container p-0 ${
                  (serviceCartItems?.length ?? 0) > 0 ? "disabled-col" : ""
                }`}
              >
                {staffList()}
              </Col>
            )}
            {view === "products" && <Col span={24}>{productList()}</Col>}
          </Row>
          {cartVisible && (
            <div
              className="width-100 height-100 position-fixed cart-overlay"
              style={{
                top: 0,
                zIndex: 99999999999
              }}
            >
              <Card
                title="Cart"
                extra={
                  isMobile && (
                    <Button
                      type="text"
                      icon={<CloseOutlined />}
                      onClick={() => setCartVisible(false)}
                    />
                  )
                }
                className="overflow-child border-radius-10 cart-container p-0"
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <div style={{ flexGrow: 1, overflowY: "auto" }}>
                  {cartList()}
                </div>

                <div style={{ paddingTop: "10px" }}>{cartBill()}</div>
              </Card>
            </div>
          )}
        </div>
      )}
      {staffModal()}
      {productSelectModal()}
      <BottomFooter />
    </>
  );
}
