import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "../../store/slices/userslices";
import { isMobile } from "react-device-detect";
import { Card, Row, Col, Button, Avatar, Divider, Modal } from "antd";
import { s3url } from "../../helper/endpoint";
import { LeftCircleOutlined } from "@ant-design/icons";
import ProfileIcon from "../../assets/profileFallBack.webp";
import changeLang from "../../assets/mobile/changeLang.webp";
import changePass from "../../assets/mobile/changepass.webp";
import privacyIcon from "../../assets/mobile/privacyIcon.webp";
import termsIcon from "../../assets/mobile/termsIcon.webp";
import { PoweroffOutlined, EditOutlined } from "@ant-design/icons";
import BottomFooter from "../Footer/BottomFooter";
// Import for Language translater
import { useTranslation } from "react-i18next";

export default function ProfileInABoard() {
  //For Language Translater
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const { t, i18n } = useTranslation();
  let user = useSelector((state) => state.userReducer);
  let userprofileImage = user.profileImage;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const refreshAfterLogout = () => {
    dispatch(userAction.removeUser());

    // Ensure Redux state is updated before navigating
    setTimeout(() => {
      navigate("/login");
    }, 100); // Small delay to allow Redux state update
  };
  useEffect(() => {
    if (!user || !user.token) {
      navigate("/login");
    }
  }, [user]);

  const content = () => {
    return (
      <div className="width-100">
        <Row className="flex-column align-items-center justify-content-center mb-3  pt-3">
          <Avatar
            size={100}
            src={
              userprofileImage != ""
                ? `${s3url}/${userprofileImage}`
                : ProfileIcon
            }
            style={{
              marginBottom: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              border: "1px solid #999"
            }}
            alt={`${user.firstName}`}
          />
          <h2 className="font-20 mb-0 font-700" style={{ color: "#1890ff" }}>
            {user.firstName} {user.lastName}
          </h2>
          <p style={{ color: "#666", marginBottom: "16px" }}>{user.email}</p>
          <Button
            type="primary"
            className="border-radius-50 font-700 d-flex justify-content-center align-items-center"
            onClick={() => navigate("/editProfile")}
          >
            {t("ProfileEditProfile")}
          </Button>
        </Row>
        <Divider>{t("ProfilePreferences")}</Divider>
        <Row gutter={[8, 8]} className="mt-3">
          <Col xs={12} md={12} xl={12}>
            <Card
              onClick={() => setModal2Open(true)}
              hoverable
              style={{
                textAlign: "center",
                transition: "transform 0.2s ease"
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
              cover={
                <img
                  alt={t("ChooseLang")}
                  src={changeLang}
                  style={{
                    padding: "16px",
                    height: "150px",
                    objectFit: "contain"
                  }}
                />
              }
            >
              <p
                style={{
                  fontWeight: "bold",
                  color: "#1890ff",
                  margin: 0
                }}
              >
                {t("ChooseLang")}
              </p>
            </Card>
            <Modal
              title={t("ChooseLang")}
              centered
              visible={modal2Open}
              onOk={() => setModal2Open(false)}
              onCancel={() => setModal2Open(false)}
              footer={null} // Custom footer taake buttons modal content me ho
            >
              <button
                style={{ backgroundColor: "#F24B17", border: "none" }}
                type="primary"
                className="m-2 width-100 py-3 border-radius-5 font-20 font-700 text-white"
                onClick={() => {
                  i18n.changeLanguage("no"); // Norwegian
                  setModal2Open(false);
                }}
              >
                Norwegian
              </button>
              <button
                style={{ backgroundColor: "#FDDC2E", border: "none" }}
                type="primary"
                className="m-2 width-100 py-3 border-radius-5 font-20 font-700"
                onClick={() => {
                  i18n.changeLanguage("en"); // english
                  setModal2Open(false);
                }}
              >
                English
              </button>
            </Modal>
          </Col>
          <Col xs={12} md={12} xl={12}>
            {" "}
            <Card
              onClick={() => navigate("/changePassword")}
              hoverable
              style={{
                textAlign: "center",
                transition: "transform 0.2s ease"
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
              cover={
                <img
                  alt={t("ProfileChangePassword")}
                  src={changePass}
                  style={{
                    padding: "16px",
                    height: "150px",
                    objectFit: "contain"
                  }}
                />
              }
            >
              <p
                style={{
                  fontWeight: "bold",
                  color: "#1890ff",
                  margin: 0
                }}
              >
                {t("ProfileChangePassword")}
              </p>
            </Card>
          </Col>
          <Col xs={12} md={12} xl={12}>
            {" "}
            <Card
              onClick={() => navigate("/privacy")}
              hoverable
              style={{
                textAlign: "center",
                transition: "transform 0.2s ease"
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
              cover={
                <img
                  alt="Personvernerklæring"
                  src={privacyIcon}
                  style={{
                    padding: "16px",
                    height: "150px",
                    objectFit: "contain"
                  }}
                />
              }
            >
              <p
                style={{
                  fontWeight: "bold",
                  color: "#1890ff",
                  margin: 0
                }}
              >
                {t("ProfilePrivacyPolicy")}
              </p>
            </Card>
          </Col>
          <Col xs={12} md={12} xl={12}>
            {" "}
            <Card
              onClick={() => navigate("/terms")}
              hoverable
              style={{
                textAlign: "center",
                transition: "transform 0.2s ease"
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
              cover={
                <img
                  alt="Vilkår og betingelser"
                  src={termsIcon}
                  style={{
                    padding: "16px",
                    height: "150px",
                    objectFit: "contain"
                  }}
                />
              }
            >
              <p
                style={{
                  fontWeight: "bold",
                  color: "#1890ff",
                  margin: 0
                }}
              >
                {t("ProfileTermsConditions")}
              </p>
            </Card>
          </Col>
        </Row>
      </div>
    );
  };
  useEffect(() => {
    if (!user.token) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      {isMobile ? (
        <Row className="px-3 pb-5" id="profileInABoard">
          <Row
            className="position-fixed width-100 px-3 d-flex justify-content-between"
            style={{ zIndex: "99999999999", left: "0", top: "15px" }}
          >
            <Col>
              {" "}
              <LeftCircleOutlined
                className="text-white"
                onClick={() => window.history.back()}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "30px"
                }}
              />
            </Col>
            <Col>
              {" "}
              <Button
                icon={<PoweroffOutlined />}
                onClick={refreshAfterLogout}
                className="border-radius-50 font-700 px-3 d-flex align-items-center spreadedShadow width-100"
                style={{
                  border: "none",
                  backgroundColor: "#FDDC2E ",
                  cursor: "pointer"
                }}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#ffc2c2")
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "#ffd1d1")
                }
              >
                {t("ProfileLogoutButton")}
              </Button>
            </Col>
          </Row>
          <div
            className="d-flex flex-column justify-content-between align-items-center border-radius-10 mb-4  width-100"
            style={{ marginTop: "70px" }}
          >
            {content()}
          </div>
        </Row>
      ) : (
        <div
          style={{ marginTop: "100px" }}
          className="mission profilePages "
          id="profileInABoard"
        >
          <Row className="align-items-center justify-content-center">
            {/* <Row
              className="position-fixed width-100 px-3 d-flex justify-content-end"
              style={{ zIndex: "99999999999", left: "0", top: "15px" }}
            >
              <Col>
                {" "}
                <Button
                  icon={<PoweroffOutlined />}
                  onClick={refreshAfterLogout}
                  className="border-radius-50 font-700 px-3 d-flex align-items-center spreadedShadow width-100"
                  style={{
                    border: "none",
                    backgroundColor: "#FDDC2E ",
                    cursor: "pointer"
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = "#ffc2c2")
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = "#ffd1d1")
                  }
                >
                  {t("ProfileLogoutButton")}
                </Button>
              </Col>
            </Row> */}
            <Col lg={12} className="commonShadowAndRound p-4">
              <Row className="justify-content-end ">
                {/* <h3 class="font-24 font-700 mb-0">
                  {t("ChangePasswordTitle")}
                </h3> */}
                <LeftCircleOutlined
                  onClick={() => navigate("/a-board")}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "30px"
                  }}
                />
              </Row>
              {content()}
            </Col>
          </Row>
        </div>
      )}
      <BottomFooter />
    </>
  );
}
