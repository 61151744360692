import React from "react";
import MobileNavBar from "./NavbarHeader/MobileNavBar";

const NotFound = () => {
  return (
    <>
      <MobileNavBar />
      <div style={{ textAlign: "center", padding: "50px", marginTop: "50px" }}>
        <img
          src="https://media.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif"
          alt="Page Not Found"
          style={{ width: "300px", borderRadius: "10px" }}
        />
        <br />
        <h2>404 - Page Not Found</h2>
        <br />
        <br />
        <p>The page you are looking for does not exist.</p>
      </div>
    </>
  );
};

export default NotFound;
