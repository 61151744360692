import { Row, Col, Button } from "antd";
import { cartAction } from "../../store/slices/cartslices";
import { useDispatch, useSelector } from "react-redux";
// Import for Language translater
import { useTranslation } from "react-i18next";

const OrderedProducts = (props) => {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  let dispatch = useDispatch();
  let restaurant = useSelector(
    (state) => state.restaurantReducer.selectedRestaurant
  );
  let root = useSelector((state) => state.rootReducer);
  const onRemoveProduct = (id, index) => {
    dispatch(
      cartAction.removeProductFromCart({
        id,
        index,
        restaurant,
        orderType: root.orderType
      })
    );
  };
  const onIncreaseProduct = (product) => {
    dispatch(
      cartAction.addProductToCart({
        ...product,
        restaurant,
        orderType: root.orderType
      })
    );
  };
  const onRemoveExtraItems = (productId, productIndex, extraId, extraIndex) => {
    dispatch(
      cartAction.removeExtraItemFromCart({
        productId,
        productIndex,
        extraId,
        extraIndex
      })
    );
  };
  const mergedCart = props.cart.reduce((acc, item) => {
    let existingProduct = acc.find(
      (p) =>
        p._id === item._id &&
        JSON.stringify(p.instruction) === JSON.stringify(item.instruction) &&
        JSON.stringify(p.extras) === JSON.stringify(item.extras)
    );

    if (existingProduct) {
      existingProduct.quantity += item.quantity;
      existingProduct.totalPrice += item.quantity * item.incPrice;
    } else {
      acc.push({ ...item, totalPrice: item.quantity * item.incPrice });
    }

    return acc;
  }, []);
  return (
    <div id="product-list">
      {mergedCart.length > 0 &&
        mergedCart.map((sinCart, index) => {
          const addedExtras = sinCart.extraItems;
          console.log("addedExtras", addedExtras);
          return (
            <div className="added-product mb-4" key={`${sinCart._id}+${index}`}>
              <Row className="justify-content-between">
                <Col
                  span={1}
                  className="d-flex justify-content-center align-items-center"
                >
                  <p className="mb-1">{index + 1}.</p>
                </Col>
                <Col span={12} className="d-flex align-items-center text-left">
                  <p className="font-700 font-14 mb-1">{sinCart.name}</p>
                </Col>
                <Col
                  span={6}
                  className="d-flex align-items-center justify-content-center"
                >
                  <Row
                    className="increaseBtnBOrders"
                    justify="center"
                    align="middle"
                  >
                    <Col span={6}>
                      <Button
                        className="p-0"
                        type="text"
                        onClick={() => onRemoveProduct(sinCart._id, index)}
                      >
                        -
                      </Button>
                    </Col>
                    <Col span={6} className="text-center">
                      {sinCart.quantity}
                    </Col>
                    <Col span={6}>
                      <Button
                        className="p-0"
                        type="text"
                        onClick={() => onIncreaseProduct(sinCart)}
                      >
                        +
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col span={5} className="text-right">
                  <p className="itemName mb-0">
                    Kr. {sinCart.totalPrice.toFixed(2)}
                  </p>
                </Col>
              </Row>
              <Row className="ps-4 mb-2">
                {Array.isArray(sinCart.instruction) &&
                  sinCart.instruction.length > 0 && (
                    <Col
                      span={24}
                      className="d-flex flex-column justify-content-start"
                    >
                      <p className="mb-0 font-12 fst-italic">
                        {t("OrderProductInstructions")}:
                      </p>
                      <p className="mb-0 font-12  intr-p billText">
                        {sinCart.instruction.join(", ")}
                      </p>
                    </Col>
                  )}
              </Row>
              {addedExtras != "" && (
                <Row className="align-items-center ps-4">
                  <p className="font-12 mb-0 fst-italic">
                    {t("Ekstrautstyr")}:
                  </p>
                </Row>
              )}

              {addedExtras.map((item, extraIndex) => {
                // console.log("sincart inn", item);
                return (
                  <Row
                    key={item._id + index}
                    className="mt-2 justify-content-between ps-4"
                  >
                    <Col span={12} className="d-flex align-items-center">
                      <p className="font-12 mb-1">{item.name}</p>
                    </Col>
                    <Col span={6} className="d-flex justify-content-center">
                      <Button
                        className="p-0"
                        type="text"
                        onClick={() =>
                          onRemoveExtraItems(
                            sinCart._id,
                            index,
                            item._id,
                            extraIndex
                          )
                        }
                      >
                        -
                      </Button>
                    </Col>
                    <Col span={6} className="text-right">
                      <p className="font-12 mb-0">
                        Kr. {item.incPrice.toFixed(2)}
                      </p>
                    </Col>
                  </Row>
                );
              })}
            </div>
          );
        })}
    </div>
  );
};
export default OrderedProducts;
