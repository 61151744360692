import { createSlice } from "@reduxjs/toolkit";
import { revertProduct } from "../actions/rootaction";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

let initialState = {
  superCategory: []
};
let productSlices = createSlice({
  name: "Product",
  initialState,
  extraReducers: (builder) => {
    return builder.addCase(revertProduct, () => {
      storage.removeItem("persist:root");

      return initialState;
    });
  },
  reducers: {
    saveSuperCategory: (state, action) => {
      state.superCategory = action.payload;
    },
    clearSuperCategory: (state) => {
      state.superCategory = []; 
    }
  }
});

export const productActions = productSlices.actions;

export default productSlices.reducer;
