import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Col } from "antd";
import { managerLogin } from "../../../store/actions/managerAction";

const ManagerLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const managerState = useSelector((state) => state?.managerReducer);
  useEffect(() => {
    if (managerState.token) {
      navigate("/aBoardDashboard");
    }
  }, []);
  const loading = managerState?.loading;
  const error = managerState?.error;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email.trim() || !password.trim()) return;

    try {
      await dispatch(managerLogin(email, password));
      navigate("/aBoardDashboard"); // ✅ Success ke baad redirect
    } catch (err) {
      console.error("Login Failed:", err);
    }
  };

  return (
    <section
      className={[
        "d-flex align-items-center justify-content-center",
        isMobile ? "loginBg p-2" : "mission profilePages p-5"
      ].join(" ")}
      style={{ minHeight: isMobile ? "100vh" : "calc(100vh - 64px)" }}
    >
      <div
        className={`width-100 d-flex justify-content-center ${
          !isMobile ? "extra-desktop-class" : "extra-mobile-class"
        }`}
      >
        <Col
          xs={22}
          xl={7}
          className={`shadow-lg rounded-4 bg-white ${
            !isMobile ? "p-5" : "p-4"
          }`}
        >
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                aria-label="Email Address"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                id="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                aria-label="Password"
                required
              />
            </div>

            {error && <p className="text-danger">{error}</p>}

            <button
              className="btn btn-warning w-100 text-white"
              type="submit"
              disabled={loading}
            >
              {loading ? "Logging in..." : "Login"}
            </button>

            <div className="text-center mt-3">
              <span
                className="text-info"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/a-board")}
              >
                Go Back
              </span>
            </div>
          </form>
        </Col>
      </div>
    </section>
  );
};

export default ManagerLogin;
