import * as React from "react";
import { Box, Typography, Modal } from "@mui/material";
import "./modal.css";
// Import for Language translater
import { useTranslation } from 'react-i18next';
export default function BasicModal(props) {
   //For Language Translater
   const { t, i18n } = useTranslation(); // Hook to access translations
  return (
    <div>
      <Modal
        open={props.isOpen}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="boxContainer">
          <div className="alignContent"></div>

          <div className="columnRight">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ color: "#ffffff" }}
            >
              {t("FollowAlong")}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {t("ModalComingSoon")}
            </Typography>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
