import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Button, Collapse, Image, Spin, Upload, message, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "../../../store/actions/managerAction"; // Ensure this action is defined correctly
import { LogoutOutlined } from '@ant-design/icons';
import BottomFooter from "../../Footer/BottomFooter";
import { LeftCircleOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Panel } = Collapse;

const ViewProducts = () => {
    const [productData, setProductData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const managerData = useSelector((state) => state.managerReducer);

    const { token, resInfo } = managerData || {};
    const catId = new URLSearchParams(window.location.search).get('catId');
    const catName = new URLSearchParams(window.location.search).get('catName');

    const fetchCategoryProducts = async () => {
        setLoading(true);
        try {
            const response = await fetch(`https://api.a-board.tech/api/v1/employee/products/getProduct/${catId}`, {
                method: 'GET',
                headers: {
                    'X-Auth-Token': managerData.token,
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();
            if (data.status === 200) {
                setProductData(data.data);
            } else {
                setError(data.message);
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("userData");
        window.location.reload();
    };

    const handleImageUpload = async (file, productId) => {
        const formData = new FormData();
        formData.append("id", productId);
        formData.append("image", file);

        try {
            const response = await fetch("https://api.a-board.tech/api/v1/employee/products/imageUpdate", {
                method: 'PUT',
                headers: {
                    'X-auth-token': token,
                },
                body: formData,
            });

            const data = await response.json();
            if (data.status === 200) {
                message.success("Image updated successfully");
                fetchCategoryProducts(); // Refresh product list after image upload
            } else {
                message.error("Failed to upload image");
            }
        } catch (error) {
            message.error("An error occurred during the upload");
        }
    };

    useEffect(() => {
        if (catId && token) {
            fetchCategoryProducts();
        }
    }, [catId, token]);

    return (
        <>
            <Layout>
                <Row className="justify-content-between  my-3 px-3">
                    <h3 className="font-24 font-700 mb-0">{resInfo?.name} Category: {catName}</h3>
                    <LeftCircleOutlined
                        onClick={() => navigate("/productImageUpload")}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "30px"
                        }}
                    />
                </Row>
                <Content className="px-3" style={{ height: "70vh", overflow: "hidden scroll" }}>
                    {/* <div style={{ textAlign: "center", marginBottom: "20px" }}>
                        <h1>{resInfo?.name}</h1>
                        <h3></h3>
                    </div> */}

                    {/* <Button
                    type="primary"
                    className="width-100"
                    block
                    onClick={fetchCategoryProducts}
                >
                    All Categories
                </Button> */}


                    {loading ? (
                        <Spin
                            size="large"
                            style={{ display: "block", textAlign: "center", marginTop: "20px" }}
                        />
                    ) : error ? (
                        <div style={{ textAlign: "center", color: "red" }}>Error: {error}</div>
                    ) : (
                        <Collapse accordion>
                            {productData.map((item, index) => (
                                <Panel
                                    key={item._id}
                                    header={
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Image
                                                src={`https://pos-stockholm-bucket.s3.eu-north-1.amazonaws.com/${item.image}`}
                                                width={40}
                                            />
                                            <h6 style={{ marginLeft: "20px" }}>{item.name}</h6>
                                        </div>
                                    }
                                >
                                    <div>
                                        <div className="row py-2">
                                            <div className="col-6">
                                                <b>Product Number:</b>
                                            </div>
                                            <div className="col-6">{item.itemNo}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <b>Product Price:</b>
                                            </div>
                                            <div className="col-6">{item.price}</div>
                                        </div>

                                        <Upload
                                            accept=".jpg,.jpeg"
                                            showUploadList={false}
                                            beforeUpload={(file) => {
                                                handleImageUpload(file, item._id);
                                                return false; // Prevent automatic upload
                                            }}
                                        >
                                            <Button type="primary">Update Image</Button>
                                        </Upload>
                                    </div>
                                </Panel>
                            ))}
                        </Collapse>
                    )}
                </Content>
            </Layout>
            <BottomFooter />
        </>
    );
};

export default ViewProducts;
