import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Table, DatePicker, Row, Col, Typography, Spin } from 'antd';
import axios from 'axios';
import 'antd/dist/antd.css';
import moment from 'moment';
import { LeftCircleOutlined } from "@ant-design/icons";
import BottomFooter from '../../Footer/BottomFooter';

export default function ZReport() {
    const { Title } = Typography;
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [reportData, setReportData] = useState(null);
    const [selectedDate, setSelectedDate] = useState(moment());
    const [selectedMonth, setSelectedMonth] = useState(moment());
    const [loading, setLoading] = useState(false);
    const managerData = useSelector((state) => state.managerReducer);

    useEffect(() => {
        // Check if user is logged in
        if (!managerData || !managerData.token) {
            window.location.href = '/aBoardDashboard'; // Redirect to login page
        } else {
            setUserData(managerData);
        }
    }, [managerData]);

    useEffect(() => {
        if (userData) {
            fetchReportData();
        }
    }, [selectedDate, selectedMonth, userData]); // Added selectedMonth and userData as dependencies

    const fetchReportData = async () => {
        setLoading(true);
        const currentDate = moment(); // Get the current date
        const startDate = selectedDate ? selectedDate.startOf('day').format('YYYY-MM-DD') : currentDate.startOf('day').format('YYYY-MM-DD');
        const endDate = selectedDate ? selectedDate.endOf('day').format('YYYY-MM-DD') : currentDate.endOf('day').format('YYYY-MM-DD');
        console.log("managerData.token", managerData.token)
        try {
            const response = await axios.post(
                'https://api.a-board.tech/api/v1/employee/arkiv/group-report',
                { startDate, endDate },
                {
                    headers: {
                        'X-Auth-Token': managerData.token,

                    },
                }
            );
            setReportData(response.data.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const handleDateChange = (date) => {
        setSelectedDate(date || moment());
    };

    const handleMonthChange = (date) => {
        setSelectedMonth(date || moment());
    };

    const renderReportTable = (data) => {
        return (
            <Table
                dataSource={data}
                columns={[
                    { title: 'Type', dataIndex: 'type' },
                    { title: 'Amount', dataIndex: 'amount' },
                ]}
                pagination={false}
                rowKey="type"
            />
        );
    };

    if (!userData) return null;

    const { resInfo } = userData;

    return (
        <>
            <Spin spinning={loading}>
                <Row className="justify-content-between  my-3 px-3">
                    <h3 className="font-24 font-700 mb-0">Z-report</h3>
                    <LeftCircleOutlined
                        onClick={() => navigate("/mReports")}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "30px"
                        }}
                    />
                </Row>
                <Row className="px-3 pt-2" gutter={[16, 16]}>
                    <Title level={1} style={{ textAlign: 'center' }}>
                        {resInfo?.name}
                    </Title>

                    <Row gutter={16} style={{ marginBottom: '20px' }}>
                        <Col span={12}>
                            <DatePicker
                                style={{ width: '100%' }}
                                placeholder="Select Date"
                                onChange={handleDateChange}
                                value={selectedDate}
                            />
                        </Col>
                        <Col span={12}>
                            <DatePicker
                                style={{ width: '100%' }}
                                placeholder="Select Month"
                                onChange={handleMonthChange}
                                value={selectedMonth}
                                picker="month"
                            />
                        </Col>
                    </Row>
                </Row>

                <div style={{ height: "70vh", overflow: "hidden scroll" }} className="px-3">{reportData && (
                    <>
                        <div style={{ marginBottom: '30px' }}>
                            <Title level={3}>Report Summary</Title>
                            {renderReportTable([
                                { type: 'Kontant', amount: reportData.totalCash },
                                { type: 'TD Card', amount: reportData.OrcTotal },
                                { type: 'Card', amount: reportData.totalBank },
                                { type: 'Vipps', amount: reportData.onlineVippsTotal },
                                { type: 'Coupon', amount: reportData.totalCoupan },
                                { type: 'Kredit', amount: reportData.totalCredit },
                                { type: 'Kontant Box Rapport', amount: reportData.totalBoxOpen },
                                {
                                    type: 'Total',
                                    amount: (
                                        Number(reportData.totalBank) +
                                        Number(reportData.totalCash) +
                                        Number(reportData.onlineVippsTotal) +
                                        Number(reportData.onlineTotal) +
                                        Number(reportData.totalCredit) -
                                        Number(reportData.totalTip) +
                                        Number(reportData.treeDriveTotal) +
                                        Number(reportData.treeDriveVippsTotal) +
                                        Number(reportData.OrcTotal) +
                                        Number(reportData.totalCoupan) -
                                        Number(reportData.couponData.totalCouponValue)
                                    ).toFixed(2),
                                },
                            ])}
                        </div>

                        <div style={{ marginBottom: '30px' }}>
                            <Title level={3}>Utkjoring</Title>
                            <Table
                                dataSource={[
                                    {
                                        orders: reportData.driveOutCharges.count,
                                        utkjøringMva: reportData.driveOutCharges.driveoutTax,
                                        utkjøringPris: reportData.driveOutCharges.driveoutCharge,
                                        total: reportData.driveOutCharges.totalDcharge,
                                    },
                                ]}
                                columns={[
                                    { title: 'Orders', dataIndex: 'orders' },
                                    { title: 'Utkjøring MVA', dataIndex: 'utkjøringMva' },
                                    { title: 'Utkjøring Pris', dataIndex: 'utkjøringPris' },
                                    { title: 'Total', dataIndex: 'total' },
                                ]}
                                pagination={false}
                                rowKey="orders"
                            />
                        </div>

                        <div>
                            <Title level={3}>ANSATTE TIP RAPPORT</Title>
                            <Table
                                dataSource={reportData.tips}
                                columns={[
                                    { title: 'Navn', dataIndex: 'firstName' },
                                    { title: 'Email', dataIndex: 'email' },
                                    { title: 'Cash', dataIndex: 'cash' },
                                    { title: 'Bank', dataIndex: 'bank' },
                                    { title: 'Total', dataIndex: 'total' },
                                ]}
                                pagination={false}
                                rowKey="email"
                            />
                            <div style={{ textAlign: 'center', backgroundColor: '#ffc107', padding: '10px' }}>
                                <h4>Kr. {reportData.totalTip}</h4>
                            </div>
                        </div>
                    </>
                )}</div>

            </Spin >
            {/* </Row > */}
            <BottomFooter />
        </>
    );
}
