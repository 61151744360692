import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Card, Col, Spin } from "antd";
import image from "../../../assets/clouds-sky.jpg";
import { LeftCircleOutlined } from "@ant-design/icons";
import BottomFooter from "../../Footer/BottomFooter";

export default function ManagerReports() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  return (
    <><Spin spinning={loading}>
      <Row className="justify-content-between  my-3 px-3">
        <h3 className="font-24 font-700 mb-0">Report's Category</h3>
        <LeftCircleOutlined
          onClick={() => navigate("/aBoardDashboard")}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "30px"
          }}
        />
      </Row>
      <Row className="px-3 pt-2" gutter={[16, 16]}>
        {" "}
        <Col span={24}>
          <Card
            className="border-radius-10"
            hoverable
            onClick={() => navigate("/zReports")}
            cover={<img alt="example" src={image} style={{ width: "100%", height: "100px", borderRadius: "10px 10px 0 0" }} />}
          >
            <Card.Meta title="Z-Reports" />
          </Card>
        </Col>
        <Col span={24}>
          <Card
            className="border-radius-10"
            hoverable
            cover={<img alt="example" src={image} style={{ width: "100%", height: "100px", borderRadius: "10px 10px 0 0" }} />}
          >
            <Card.Meta
              onClick={() => navigate("/dailyReports")}
              title="Daily Reports"
            />
          </Card>
        </Col>
      </Row>
    </Spin>
      <BottomFooter /></>
  );
}
