import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Upload,
  Button,
  Spin,
  DatePicker,
  Row,
  Col,
  Card,
  Space,
  message
} from "antd";
import { InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router";
import { BsClock } from "react-icons/bs";
import { UploadOutlined, LeftCircleOutlined } from "@ant-design/icons";
import { s3url, apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import BottomFooter from "../Footer/BottomFooter";
import axios from "axios";

const AgentProfile = () => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [fieldTypes, setFieldTypes] = useState({});
  const [loading, setLoading] = useState(true);
  const [time, setTime] = useState("");
  const [formName, setFormName] = useState("");
  const [fileList, setFileList] = useState({});
  let navigate = useNavigate("");

  useEffect(() => {
    const fetchFormFields = async () => {
      try {
        const response = await fetch(
          `${apiUrl}${tDriveEndpoint}jobs/getAllJobs`
        );
        if (!response.ok)
          throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();

        if (data?.data?.length) {
          setFields(data.data[0].fields || []);
          setFieldTypes(data.data[0].fieldTypes || {});
          setFormName(data.data[0].name);
        }
      } catch (error) {
        console.error("Fetching jobs failed:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFormFields();
  }, []);

  const onFinish = async (values) => {
    console.log("Form Submitted:", values);

    const formData = new FormData();
    formData.append("name", formName);
    console.log("fomData", formData);
    const filteredValues = { ...values };
    delete filteredValues.image;
    formData.append("data", JSON.stringify(filteredValues));

    if (values.image?.fileList) {
      values.image.fileList.forEach((file) => {
        formData.append("files", file.originFileObj); // Use original file object
      });
    }

    // Debugging FormData entries
    for (let pair of formData.entries()) {
      console.log(pair[0], pair[1]);
    }

    try {
      const response = await axios.post(
        `${apiUrl}${tDriveEndpoint}jobs/saveJobRequests`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      console.log("Success:", response.data);
      message.success("Form submitted successfully!");
      form.resetFields();
      setFileList([]);
    } catch (error) {
      console.error("Form submission failed:", error);
      message.warning("Form submission failed. Please try again.");
    }
  };

  const handleFileChange = (field, info) => {
    const file = info.file.originFileObj;
    if (!file) return;

    const fileURL = URL.createObjectURL(file);
    setFileList((prev) => ({
      ...prev,
      [field]: { file, preview: fileURL }
    }));
  };

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  return (
    <>
      {loading ? (
        <Spin size="large" className="d-flex justify-content-center mt-5" />
      ) : (
        <Row
          className="p-3 align-items-center justify-content-center mission profilePages"
          style={{ minHeight: "calc(100vh - 64px)" }}
        >
          <Col
            xs={24}
            md={12}
            className="d-flex align-items-center justify-content-center"
          >
            <Card
              className="shadow-lg rounded-4"
              style={{ maxWidth: "800px", padding: "20px" }}
            >
              <Row className="justify-content-between mb-5">
                <h3 class="font-24 font-700 mb-0">{formName}'s Form</h3>
                <LeftCircleOutlined
                  onClick={() => navigate("/a-board")}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "30px"
                  }}
                />
              </Row>

              <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={[16, 16]}>
                  {fields.map((field) => {
                    const type = fieldTypes[field];
                    const label = capitalize(field);

                    return (
                      <Col xs={24} lg={12} key={field}>
                        <Form.Item
                          name={field}
                          label={label}
                          className="width-100"
                        >
                          {type === "text" ? (
                            <Input placeholder={`Enter ${label}`} />
                          ) : type === "email" ? (
                            <Input
                              type="email"
                              placeholder={`Enter ${label}`}
                            />
                          ) : type === "password" ? (
                            <Input.Password placeholder={`Enter ${label}`} />
                          ) : type === "number" ? (
                            <Input
                              type="number"
                              placeholder={`Enter ${label}`}
                            />
                          ) : type === "filePdf" || type === "fileImage" ? (
                            <Upload
                              beforeUpload={() => false}
                              onChange={(info) => handleFileChange(field, info)}
                              listType="picture"
                              maxCount={1}
                            >
                              <Button icon={<UploadOutlined />}>
                                Upload {type === "filePdf" ? "PDF" : "Image"}
                              </Button>
                            </Upload>
                          ) : type === "date" ? (
                            <DatePicker
                              style={{ width: "100%" }}
                              placeholder="Select date"
                            />
                          ) : type === "time" ? (
                            <InputGroup>
                              <InputGroup.Text>
                                <BsClock />
                              </InputGroup.Text>
                              <input
                                type="time"
                                value={time}
                                onChange={(e) => setTime(e.target.value)}
                                className="form-control"
                              />
                            </InputGroup>
                          ) : (
                            <Input placeholder={`Enter ${label}`} />
                          )}
                        </Form.Item>
                      </Col>
                    );
                  })}
                </Row>

                <Button type="primary" htmlType="submit" className="w-100 mt-3">
                  Submit
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
      <BottomFooter />
    </>
  );
};

export default AgentProfile;
