import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { LeftCircleOutlined } from "@ant-design/icons";
import { Row, Col, Card, Button, Divider } from "antd";
import { s3url, apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import { isMobile } from "react-device-detect";

import eGiftCard from "../../assets/e-card-logo.webp";
import tflSmallLogo from "../../assets/sponsoredByTFL.webp.webp";
import redBg from "../../assets/BG/RED.webp";
import orangeBg from "../../assets/BG/ORANGE.webp";
import yellowBg from "../../assets/BG/YELLOW.webp";
import greenBg from "../../assets/BG/GREEN.webp";
import dBlueBg from "../../assets/BG/DARK_BLUE.webp";
import sBlueBg from "../../assets/BG/SKY_BLUE.webp";
import purpleBg from "../../assets/BG/PURPLE.webp";
import dPurpleBg from "../../assets/BG/DARK _PURPLE.webp";
import blackBg from "../../assets/BG/BLACK.webp";
// Import for Language translater
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import i18n from "./../../i18n";
const TflMarketCart = (props) => {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  const location = useLocation();
  const dispatch = useDispatch();
  const [couponData, setCouponData] = useState([]);

  const [openCouponModal, setOpenCouponModal] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [totalToPay, setTotalToPay] = useState(0);
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [newDeliveryCharges, setNewDeliveryCharges] = useState(0);
  const [couponValue, setCouponValue] = useState(0);
  const [couError, setCouError] = useState("");
  const [loading, setLoading] = useState(false);
  let address = useSelector((state) => state.locationReducer);
  const user = useSelector((state) => state.userReducer);
  const couponReducer = useSelector((state) => state.couponReducer);
  const [appliedCouponCode, setAppliedCouponCode] = useState(
    localStorage.getItem("appliedCouponCode") || ""
  );
  useEffect(() => {
    const storedCoupon = localStorage.getItem("appliedCouponCode");
    setAppliedCouponCode(storedCoupon); // 🔹 Sync state with localStorage
  }, []);

  const couponRef = useRef();
  let navigate = useNavigate();
  const currentMonth = new Date()
    .toLocaleString("default", { month: "long" })
    .toUpperCase();

  // Close modal
  const handleClose = () => {
    setOpenCouponModal(false); // Close the modal
  };
  // Apply coupon logic
  const onApplyCoupon = async (couponCode) => {
    console.log("Coupon Code Clicked:", couponCode);
    setCouError("");

    if (!couponCode) {
      setCouError("Vennligst skriv inn en gavekortkode");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `${apiUrl}${tDriveEndpoint}tdlcoupon/gettflcouponvalueMarket`,
        {
          coupanCode: couponCode
        }
      );
      const couponData = response.data.data;
      if (couponData && couponData.remAmtForThisMonth) {
        setAppliedCouponCode(couponCode);
        setCouponValue(couponData.remAmtForThisMonth);
        localStorage.setItem("appliedCouponCode", couponCode);
        setOpenCouponModal(false);
      } else {
        setCouError("Koden er ugyldig eller utløpt");
      }
    } catch (error) {
      console.error("Error fetching coupon value:", error);
      setCouError("Noe gikk galt. Prøv igjen senere.");
    } finally {
      setLoading(false);
    }
  };

  const deleteCoupon = () => {
    localStorage.removeItem("appliedCouponCode");
    localStorage.removeItem("couponValue");
    setCouponValue(0);
    setAppliedCouponCode("");
    // console.log("Coupon removed from localStorage");
  };

  // const { totalAmount, totalWeight } = location.state;
  const { totalAmount, totalWeight } = location.state || {};

  // Fetch cart data and calculate delivery charges
  useEffect(() => {
    const data = localStorage.getItem("TFLCartData");
    if (data) {
      setCartData(JSON.parse(data));
    }

    if (location.state && location.state.deliveryCharges) {
      const {
        deliveryCharges,
        totalLength,
        totalBreadth,
        totalHeight,
        totalWeight
      } = location.state;

      let priceList = [];
      let allPrices = [];

      // Iterate through the deliveryCharges and apply the logic to filter valid prices
      for (let i = 0; i < deliveryCharges.length; i++) {
        const currentCharge = deliveryCharges[i];
        allPrices.push(currentCharge.price);

        if (
          totalWeight >= currentCharge.weight.min &&
          totalWeight <= currentCharge.weight.max &&
          totalLength <= currentCharge.dimensions.length &&
          totalBreadth <= currentCharge.dimensions.breadth &&
          totalHeight <= currentCharge.dimensions.height
        ) {
          priceList.push(currentCharge.price);
        }
      }

      // Determine the minimum price from the valid priceList or fallback to allPrices
      let minPrice = 0;
      if (priceList.length > 0) {
        minPrice = Math.min(...priceList); // Get the minimum value from the valid prices
      } else {
        minPrice = Math.max(...allPrices); // Fallback to the maximum from all prices if no valid prices
      }
      console.log("Calculated Minimum Price (minPrice): ", minPrice);
      // Set the calculated delivery charge
      setNewDeliveryCharges(minPrice);
      setDeliveryCharges(minPrice);
      console.log("deliveryCharge: ", deliveryCharges);
    }
  }, [location.state]);

  // Recalculate total amount when coupon or delivery charge changes
  useEffect(() => {
    // Extract delivery charges value (handling array)
    const deliveryChargeValue = Array.isArray(deliveryCharges)
      ? deliveryCharges[0] || 0
      : deliveryCharges;

    let totalAmountToPay = totalAmount + deliveryChargeValue;

    // This is the crucial part:
    const actualCouponValue = Math.min(couponValue, totalAmountToPay);

    let finalAmount = totalAmountToPay - actualCouponValue;

    if (finalAmount < 0) {
      finalAmount = 0;
    }

    setTotalToPay(finalAmount);
    setCouponValue(actualCouponValue); // Update the couponValue state!
  }, [totalAmount, deliveryCharges, couponValue]);

  // Create order payload
  const onCreateOrder = async (cart) => {
    setDisabled(true);
    const orderPayload = {
      items: cart.map((item) => ({
        _id: item._id,
        quantity: item.quantity
      })),
      customerData: {
        address: address?.locationAddress,
        countryCode: user.countryCode,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phone
      },
      appUserId: user._id,
      customerType: "IOS",
      orderFrom: "TDP",
      coupanCode: localStorage.getItem("appliedCouponCode"),
      couponValue: couponValue,
      coinsUsed: 0
    };
    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/treeDrive/treedrivepayment/createordertfl`,
        orderPayload,
        {
          headers: {
            "x-auth-token": `${localStorage.getItem("token")}`
          }
        }
      );
      console.log("response: " + JSON.stringify(response));
      let orderIdOfOrder = response.data.data.data.orderId;
      console.log("orderIdOfOrder: ", response.data.data.data.orderId);
      if (response.status === 200) {
        const orderResponseUrl = response.data.data.data.url;

        window.location.replace(orderResponseUrl);
      }
    } catch (error) {
      console.error("Error creating order:", error);
    } finally {
      setDisabled(false);
    }
  };

  useEffect(() => {
    const { couponValue, isFetched } = couponReducer;

    if (isFetched) {
      if (couponValue) {
        console.log("props", props);
        props.updateCouponValue(couponValue);
        handleClose();
      } else {
        setCouError("Koden er ugyldig eller utløpt");
      }
    }
  }, [couponReducer, props]);

  useEffect(() => {
    const storedCoupon = localStorage.getItem("appliedCouponCode");
    if (storedCoupon) {
      console.log("Previously applied coupon found:", storedCoupon);
    }
  }, []);
  const backgroundImages = [
    redBg, // For amountPaid <= 1000
    orangeBg, // For amountPaid <= 2000
    yellowBg, // For amountPaid <= 4000
    greenBg, // For amountPaid <= 8000
    dBlueBg, // For amountPaid <= 16000
    sBlueBg, // For amountPaid <= 32000
    purpleBg, // For amountPaid <= 64000
    dPurpleBg, // For amountPaid <= 128000
    blackBg // For amountPaid <= 256000
  ];

  const thresholds = [
    10, 1000, 2000, 4000, 8000, 16000, 32000, 64000, 128000, 256000
  ];

  const getBackgroundImage = (amountPaid) => {
    for (let i = thresholds.length - 1; i >= 0; i--) {
      if (amountPaid > thresholds[i]) {
        return backgroundImages[i];
      }
    }
    return backgroundImages[0];
  };

  const getCoupons = async () => {
    const cardHistoryData = {
      customerPhoneNo: user.phone,
      delStatus: "all"
    };

    setLoading(true);
    try {
      const response = await axios.post(
        `${apiUrl}${tDriveEndpoint}appuser/getusercoupons`,
        cardHistoryData
      );

      // Filter coupons to keep only those generated by "ReserveTable"
      const filteredCoupons = response.data.data.coupons.filter(
        (coupon) => coupon.generatedBy != "ReserveTable"
      );

      setCouponData(filteredCoupons);
    } catch (error) {
      console.error("Error fetching coupons:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCoupons();
  }, []);
  const couponCard = () => {
    return (
      <>
        {couponData.map((data, index) => {
          const backgroundImage = getBackgroundImage(data.amountPaid || 0);
          const isExpired =
            data.isExpiredCheck == true ||
            (data?.expirayDate && new Date(data.expirayDate) < new Date());
          return (
            <Card
              className={`giftCardBox width-100 mb-4 ${
                isExpired ? "disabledCard" : ""
              }`}
              key={data._id}
              style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "20px",
                opacity: isExpired ? 0.5 : 1,
                pointerEvents: "auto",
                border: "none",
                boxShadow: "none"
              }}
            >
              <Row className="justify-content-between mb-1 text-white">
                <Col className="d-flex align-items-center">
                  <img alt="eGiftCard" src={eGiftCard} width={"80px"} />
                </Col>
                <Col className="text-right">
                  <h3 className="mb-0 font-16 font-700 text-white">
                    NOK {`${data?.coupanValue.toFixed(2)}`}
                  </h3>
                  <p className="mb-0 font-14">
                    {t("ShowTFLBalance")} {`${data?.reamainigValue.toFixed(2)}`}
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-between mb-2">
                {data?.remAmtForThisMonth <= 0 ? (
                  <span className="text-white font-12">
                    *{t("MonthlyBalanceExhausted")}
                  </span>
                ) : (
                  <>
                    {" "}
                    <Col>
                      <h3 className="font-22 text-white mb-0" ref={couponRef}>
                        {data?.coupanCode}
                      </h3>
                    </Col>
                    <Col>
                      <Button
                        onClick={() => onApplyCoupon(data?.coupanCode)} // Pass coupon code directly
                        autoFocus
                        className="greenAccentBg border-radius-50 font-700"
                      >
                        {t("UseCode")}
                      </Button>

                      {couError && <p className="text-danger">{couError}</p>}
                    </Col>
                  </>
                )}
              </Row>
              <Row className="justify-content-between">
                <Col className="d-flex justify-content-center align-items-center text-white">
                  {t("ShowTFLMonth")} - {currentMonth}
                </Col>
                <Col>
                  <p className="mb-0 greenAccentText text-right">
                    {t("ShowTFLValid")}
                  </p>
                  <p className="mb-0 text-white text-right">
                    {data?.expirayDate
                      ? new Date(data.expirayDate).toISOString().split("T")[0]
                      : "N/A"}
                  </p>
                </Col>
              </Row>
              <Divider />
              <Row className="justify-content-between my-2">
                <Col span={4}>
                  <p className="mb-0 greenAccentText">{t("ShowTFLLimit")}</p>
                  <p className="mb-0 text-white">
                    {data?.cardAmtForThisMonth.toFixed(2)}
                  </p>
                </Col>
                <Col span={4}>
                  <p className="mb-0 greenAccentText">{t("ShowTFlUsed")}</p>
                  <p className="mb-0 text-white">
                    {Math.abs(
                      (Number(data?.cardAmtForThisMonth) || 0) -
                        (Number(data?.remAmtForThisMonth) || 0)
                    ).toFixed(2)}
                  </p>
                </Col>
                <Col span={4}>
                  <p className="mb-0 greenAccentText">{t("ShowTFLBalance")}</p>
                  <p className="mb-0 text-white">
                    {data?.remAmtForThisMonth.toFixed(2)}
                  </p>
                </Col>
                <Col span={6}></Col>
              </Row>
              <Divider />
              <Row className="justify-content-between position-relative mt-3">
                <Col className="d-flex justify-content-center align-items-end">
                  <p className="text-white mb-0">
                    {(() => {
                      if (data.generatedBy === "admin") {
                        return "BEGAVET AV ADMIN";
                      } else if (data.generatedBy === "CoinConversions") {
                        return "COINS KONVERTER";
                      } else {
                        return data?.recipientName?.toUpperCase();
                      }
                    })()}
                  </p>
                </Col>
                {/* <Col
                  className="position-absolute"
                  style={{ bottom: "0", right: "0" }}
                >
                  <img
                    src={tflSmallLogo}
                    alt="sponsored by tfl"
                    width={"50px"}
                  />
                </Col> */}
              </Row>
            </Card>
          );
        })}
      </>
    );
  };
  return (
    <>
      {isMobile ? (
        <>
          <Row className="px-3" style={{ marginTop: "70px" }}>
            <Col
              span={24}
              className="mt-3 border-radius-10"
              style={{
                height: "45vh"
                // overflowY: "auto",
                // boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                // padding: "10px",
                // backgroundColor: "#f9f9f9"
              }}
            >
              {cartData.map((item) => (
                <Card
                  key={item._id}
                  className="mb-3 border-radius-10 tflMarketProCard"
                  style={{
                    border: "1px solid #eaeaea",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                  }}
                >
                  <Row className="d-flex justify-content-between align-items-center border-radius-5">
                    <Col span={6}>
                      <img
                        style={{
                          width: "60px",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)"
                        }}
                        alt={item.name}
                        src={`${s3url}/${item.url}`}
                      />
                    </Col>
                    <Col span={17} className="pl-3">
                      <p className="font-12 font-700 mb-0">
                        {item.productName} &times; {item.quantity}
                      </p>
                      <p style={{ margin: "5px 0 0", color: "#555" }}>
                        {t("TFLMarketPrice")}: {item.amount * item.quantity} Kr.
                      </p>
                    </Col>
                  </Row>
                </Card>
              ))}
            </Col>
          </Row>

          <Row
            className="mt-4 width-100 position-fixed"
            style={{ bottom: "0" }}
          >
            <Col span={24}>
              <Card
                className="order-radius-10"
                style={{
                  border: "1px solid #eaeaea",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
                }}
              >
                <Divider>{t("TFLMarketOffers")}</Divider>
                {!appliedCouponCode ? (
                  <Button
                    type="primary"
                    className="saveBtn  font-14"
                    onClick={() => {
                      setOpenCouponModal(true);
                      console.log("btn hit");
                    }}
                    block
                  >
                    {t("TFLMarketApplyCard")}
                  </Button>
                ) : (
                  <Button
                    type="danger"
                    className="deleteBtn"
                    onClick={deleteCoupon}
                    block
                  >
                    <div className="coupon d-flex justify-content-center">
                      <span style={{ color: "white", fontWeight: "bold" }}>
                        {t("SideCartDeleteCoupon")}
                      </span>
                    </div>
                  </Button>
                )}
                <br /> <Divider>{t("TFLMarketBill")}</Divider>
                <div className="d-flex justify-content-between">
                  <p style={{ margin: 0, color: "#555" }}>Sub-Total</p>
                  <p style={{ margin: 0, fontWeight: "bold" }}>
                    {totalAmount} Kr.
                  </p>
                </div>
                {newDeliveryCharges != 0 && (
                  <div className="d-flex justify-content-between">
                    <p style={{ margin: 0, color: "#555" }}>
                      {t("TFLMarketShipping")}
                    </p>
                    <p style={{ margin: 0, fontWeight: "bold" }}>
                      + {newDeliveryCharges} Kr.
                    </p>
                  </div>
                )}
                {couponValue > 0 && ( // Only show if a coupon was used
                  <div className="d-flex justify-content-between">
                    <p style={{ margin: 0, color: "#555" }}>
                      {t("TFLMarketAmount")}
                    </p>
                    <p style={{ margin: 0, fontWeight: "bold" }}>
                      -{couponValue} Kr.
                    </p>{" "}
                  </div>
                )}
                <br />
                <br />
                <Button
                  type="primary"
                  className="w-100 saveBtn text-white font-700 font-16 border-radius-5"
                  style={{ backgroundColor: "#1890ff" }}
                  block
                  onClick={() => onCreateOrder(cartData)}
                  disabled={disabled}
                >
                  {totalToPay === 0 ? (
                    <>{t("TFLMarketProceedButton")}</>
                  ) : (
                    <>Pay {totalToPay} Kr.</>
                  )}
                </Button>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row
            className="px-3 justify-content-center align-items-start"
            style={{ marginTop: "90px" }}
            gutter={[24, 24]}
          >
            <Col span={8} className="border-radius-10">
              <Row className="width-100 align-items-center justify-content-between">
                <h3 className="font-24 font-700 mb-0">Cart</h3>
                <LeftCircleOutlined
                  onClick={() => navigate("/tflMarket")}
                  style={{ fontSize: "30px", cursor: "pointer" }}
                />
              </Row>
              <Divider>{t("TFLMarketReview")}</Divider>{" "}
              <div
                className="width-100 d-flex align-items-start justify-content-start flex-column mb-3"
                style={{
                  height: "40vh",
                  overflowY: "auto",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                  padding: "10px",
                  backgroundColor: "#f9f9f9"
                }}
              >
                {" "}
                {cartData.map((item) => (
                  <Card
                    key={item._id}
                    className="mb-3 border-radius-10 tflMarketProCard width-100"
                    style={{
                      border: "1px solid #eaeaea",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                    }}
                  >
                    <Row className="d-flex justify-content-between align-items-center border-radius-5 width-100">
                      <Col span={6}>
                        <img
                          style={{
                            width: "60px",
                            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)"
                          }}
                          alt={item.name}
                          src={`${s3url}/${item.url}`}
                        />
                      </Col>
                      <Col span={17} className="pl-3">
                        <p className="font-12 font-700 mb-0">
                          {item.productName} &times; {item.quantity}
                        </p>
                        <p style={{ margin: "5px 0 0", color: "#555" }}>
                          {item.amount * item.quantity} Kr.
                        </p>
                      </Col>
                    </Row>
                  </Card>
                ))}
              </div>
              <Card
                className="order-radius-10"
                style={{
                  border: "1px solid #eaeaea",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
                }}
              >
                <Divider>{t("TFLMarketOffers")}</Divider>
                {!appliedCouponCode ? (
                  <Button
                    type="primary"
                    className="saveBtn font-14"
                    onClick={() => setOpenCouponModal(true)}
                    block
                  >
                    {t("TFLMarketApplyCard")}
                  </Button>
                ) : (
                  <Button
                    type="danger"
                    className="deleteBtn"
                    onClick={deleteCoupon}
                    block
                  >
                    <div className="coupon d-flex justify-content-center">
                      <span style={{ color: "white", fontWeight: "bold" }}>
                        {t("SideCartDeleteCoupon")}
                      </span>
                    </div>
                  </Button>
                )}
                <br /> <Divider>{t("TFLMarketBill")}</Divider>
                <div className="d-flex justify-content-between">
                  <p style={{ margin: 0, color: "#555" }}>
                    {t("TotalSubtotal")}
                  </p>
                  <p style={{ margin: 0, fontWeight: "bold" }}>
                    {totalAmount} Kr.
                  </p>
                </div>
                {newDeliveryCharges != 0 && (
                  <div className="d-flex justify-content-between">
                    <p style={{ margin: 0, color: "#555" }}>
                      {t("TFLMarketShipping")}
                    </p>
                    <p style={{ margin: 0, fontWeight: "bold" }}>
                      +{newDeliveryCharges} Kr.
                    </p>
                  </div>
                )}
                {couponValue <= 0 ? (
                  ""
                ) : (
                  <div className="d-flex justify-content-between">
                    <p style={{ margin: 0, color: "#555" }}>
                      {t("TFLMarketAmount")}
                    </p>
                    <p style={{ margin: 0, fontWeight: "bold" }}>
                      -{couponValue} Kr.
                    </p>
                  </div>
                )}
                <br />
                <button
                  type="primary"
                  className="w-100 saveBtn text-white font-700 py-2 font-16 border-radius-5"
                  style={{ backgroundColor: "#1890ff" }}
                  block
                  onClick={() => onCreateOrder(cartData)}
                  disabled={disabled}
                >
                  {totalToPay === 0 ? (
                    <>{t("TFLMarketProceedButton")}</>
                  ) : (
                    <>Pay {totalToPay} Kr.</>
                  )}
                </button>
              </Card>
            </Col>
          </Row>
        </>
      )}
      {openCouponModal && (
        <>
          {console.log("hello", openCouponModal)}
          <Modal
            show={openCouponModal} // Use show prop and openCouponModal state
            onHide={handleClose} // Use onHide and your handleClose function
            centered
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "black" }}>
                {t("TFLMarketApplyCard")}
              </Modal.Title>
            </Modal.Header>
            <Row
              className="p-3"
              style={{ height: "70vh", overflowY: "scroll" }}
            >
              {!loading ? (
                <Col span={24}>
                  {couponData.length === 0 ? (
                    <div className="d-flex flex-column justify-content-center align-items-center width-100 height-100">
                      <p className="text-dark font-18 font-700">
                        <Trans
                          i18nKey={"TFLMarketNoRegister"}
                          common={"br : <br/>"}
                        />
                      </p>
                      <p>{t("TFLMarketSteps")}</p>
                      <ul className="text-dark">
                        <li>{t("TFLMarketGoAgent")} </li>
                        <li>{t("TFLMarketExplore")}</li>
                        <li>{t("TFLMarketComplete")}</li>
                        <li>{t("TFLMarketTransaction")}</li>
                      </ul>
                    </div>
                  ) : (
                    couponCard()
                  )}
                </Col>
              ) : (
                <Col span={24}>
                  <p>Loading...</p>
                </Col>
              )}
            </Row>
          </Modal>
        </>
      )}
    </>
  );
};

export default TflMarketCart;
