import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Select, Row, Col, Card, Spin, message } from "antd";
import { LeftCircleOutlined } from "@ant-design/icons";
import {
  fetchManagerDetails,
  switchManagerBranch,
  logoutManager
} from "../../../store/actions/managerAction.js";
import { useTranslation } from "react-i18next";
import image from "../../../assets/clouds-sky.jpg";
import { apiUrl } from "../../../helper/endpoint";
import BottomFooter from "../../Footer/BottomFooter.js";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const managerData = useSelector((state) => state.managerReducer);
  const { t, i18n } = useTranslation(); // Hook to access translations

  const [branchListFull, setBranchListFull] = useState(
    managerData.info.branchList
  );
  const [branchInfo, setBranchInfo] = useState(managerData.info);
  const [loading, setLoading] = useState(false);

  // ✅ Fetch manager details if not available
  useEffect(() => {
    if (branchInfo?.token && !branchListFull?.length) {
      console.log("Fetching manager details...");
      dispatch(fetchManagerDetails(branchInfo.token));
    }
  }, [dispatch, branchInfo?.token]);

  // ✅ Default branch set
  useEffect(() => {
    if (branchListFull?.length > 0 && !branchInfo?.branchId) {
      const defaultBranch = branchListFull[0];
      console.log("Setting default branch:", defaultBranch);

      setBranchInfo((prev) => ({
        ...prev,
        branchId: defaultBranch?._id
      }));

      // ✅ Automatically switch to the first branch
      dispatch(
        switchManagerBranch(defaultBranch?._id, null, branchInfo?.token)
      );
    }
  }, [branchListFull]);

  return (
    <>
      {" "}
      <Spin spinning={loading}>
        <Row className="justify-content-between  my-3 px-3">
          <h3 className="font-24 font-700 mb-0">Restaurant's Name</h3>
          <LeftCircleOutlined
            onClick={() => navigate("/a-board")}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "30px"
            }}
          />
        </Row>
        <Row className="px-3 pt-2" gutter={[16, 16]}>
          <Col xs={24} lg={12}>
            <Card
              className="border-radius-10"
              hoverable
              onClick={() => navigate("/productImageUpload")}
              cover={
                <img
                  alt="example"
                  src={image}
                  style={{
                    width: "100%",
                    height: "100px",
                    borderRadius: "10px 10px 0 0"
                  }}
                />
              }
            >
              <Card.Meta
                title="View Menu"
                description="Click the button below to explore the menu."
              />
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <Card
              className="border-radius-10"
              hoverable
              cover={
                <img
                  alt="example"
                  src={image}
                  style={{
                    width: "100%",
                    height: "100px",
                    borderRadius: "10px 10px 0 0"
                  }}
                />
              }
            >
              <Card.Meta
                onClick={() => navigate("/mReports")}
                title="Reports"
                description="Click below to see reports (daily, monthly, and z)."
              />
            </Card>
          </Col>
        </Row>
      </Spin>
      <BottomFooter />
    </>
  );
};

export default Dashboard;
