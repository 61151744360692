import React, { useState, useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import logo from "../../assets/landscapelogo.webp";
import { Col, Modal, Row } from "antd";
import { AiOutlineGlobal } from "react-icons/ai"; // Import language icon
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import "./mobilenavbar.css";
// Import for Language translater
import { useTranslation } from "react-i18next";

import {
  revertCart,
  revertDis,
  revertLocation,
  revertProduct,
  revertRestaurant,
  revertRoot,
  revertCou
} from "../../store/actions/rootaction";

function MobileNavBar(props) {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations
  const [modal2Open, setModal2Open] = useState(false);

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  let dispatch = useDispatch();
  let location = useLocation();

  useEffect(() => {
    if (location.pathname == "/home") {
      dispatch(revertCart());
      dispatch(revertDis());
      dispatch(revertLocation());
      dispatch(revertProduct());
      dispatch(revertRestaurant());
      dispatch(revertRoot());
      dispatch(revertCou());
    }
  }, [location.pathname]);

  let navigate = useNavigate();
  let user = useSelector((state) => state.userReducer);

  return (
    <nav className="navbar p-0">
      <div className="nav-container d-flex justify-content-between align-items-center">
        <Row className="width-100 justify-content-between align-items-center">
          <Col>
            {" "}
            <NavLink to="/home" className="nav-logo col-1">
              <img
                src={logo}
                loading="lazy"
                alt="logo"
                className="logoSize"
                height={"60px"}
              />
            </NavLink>
          </Col>
          <Col>
            <NavLink to="/home" className=" aboutNavLink col-1">
              Ezygo
            </NavLink>
            <NavLink to="/tflgiftcard" className=" aboutNavLink col-1">
              {t("NavAgent")}
            </NavLink>
            <NavLink to="/orderHistory" className=" aboutNavLink col-1">
              {t("NavHstory")}
            </NavLink>
            <NavLink to="/a-board" className=" aboutNavLink col-1">
              {t("NavArchive")}
            </NavLink>
            <NavLink to="/shareReferral" className=" aboutNavLink col-1">
              {t("NavShare")}
            </NavLink>
            <NavLink to="/contact" className=" aboutNavLink col-1">
              {t("NavContact")}
            </NavLink>
            {user.token != "" ? (
              ""
            ) : (
              <NavLink to="/login" className=" aboutNavLink col-1">
                {t("NavRegister")}
              </NavLink>
            )}
            <NavLink
              to="#"
              className="aboutNavLink col-1"
              style={{
                cursor: "pointer",
                fontWeight: "bold",
                color: "#1890ff"
              }}
              onClick={(e) => {
                e.preventDefault(); // Prevent default navigation
                setModal2Open(true);
              }}
            >
              <AiOutlineGlobal size={18} /> {/* Language Icon */}
              {/* {t("ChooseLang")} */}
            </NavLink>

            <Modal
              title={t("ChooseLang")}
              centered
              visible={modal2Open}
              onOk={() => setModal2Open(false)}
              onCancel={() => setModal2Open(false)}
              footer={null} // Custom footer taake buttons modal content me ho
            >
              <button
                style={{ backgroundColor: "#F24B17", border: "none" }}
                type="primary"
                className="m-2 width-100 py-3 border-radius-5 font-20 font-700 text-white"
                onClick={() => {
                  i18n.changeLanguage("no"); // Norwegian
                  setModal2Open(false);
                }}
              >
                Norwegian
              </button>
              <button
                style={{ backgroundColor: "#FDDC2E", border: "none" }}
                type="primary"
                className="m-2 width-100 py-3 border-radius-5 font-20 font-700"
                onClick={() => {
                  i18n.changeLanguage("en"); // english
                  setModal2Open(false);
                }}
              >
                English
              </button>
            </Modal>
          </Col>
        </Row>
      </div>
    </nav>
  );
}

export default MobileNavBar;
