import React, { useState } from "react";
import { Button, Row, Col } from "antd";
import { useNavigate } from "react-router";
import BottomFooter from "../Footer/BottomFooter";

// Import for Language translater
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";

const SalonList = () => {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  let navigate = useNavigate();

  return (
    <>
      <div
        className="salonComingSoon d-flex flex-column justify-content-between"
        style={{ height: "100vh" }}
      >
        <br />
        <h3
          className="font-bold px-3 sansita-black welcome-text"
          style={{ color: "#0066CC " }}
        >
          {t("GetBestTitle")}
          <br />
          {t("HairStyleTitle")}
        </h3>
        <h2 className="text-white text-center font-bold font-45 comingSoonTextMob">
          {t("ComingText")}
        </h2>
        <div className="d-flex align-items-center justify-content-center">
          {" "}
          <button
            className="mainBgColor border-radius-50 text-white px-5 py-2 mt-2"
            onClick={() => navigate("/salonAppointment")}
            style={{
              cursor: "pointer",
              border: "none",
              transition: "all 0.3s ease"
            }}
          >
            Salon Work
          </button>
        </div>
        <Row className="px-3 justify-content-center">
          <Col
            xs={24}
            lg={6}
            className="d-flex justify-content-center border-radius-10 flex-column width-100 align-items-center px-3 py-4 mb-5 mt-3 text-center spreadedShadow"
            style={{
              backgroundColor: "#f4c500 "
            }}
          >
            <h6 className="font-16 font-400">
              <Trans
                i18nKey="SalonInviteYellowBoxText"
                components={{ br: <br /> }}
              />
            </h6>
            <button
              className="mainBgColor border-radius-50 text-white px-5 py-2 mt-2"
              onClick={() =>
                navigate("/invite", {
                  // navigate("/inviteList", {
                  state: { key: "salon" }
                })
              }
              style={{
                cursor: "pointer",
                border: "none",
                transition: "all 0.3s ease"
              }}
            >
              {t("InviteButton")}
            </button>
          </Col>
        </Row>
        <br />
      </div>
      <BottomFooter />
    </>
  );
};

export default SalonList;
