import { Row, Col, Button } from "antd";
import { useNavigate } from "react-router";
// Import for Language translater
import { useTranslation } from 'react-i18next';

const CartOrderButton = (props) => {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  let navigate = useNavigate();
  return (
    <Row
      className="d-flex justify-content-end spreadedShadow p-2"
      style={{ borderRadius: "0 0 14px 14px" }}
      id="action-btn"
    >
      <Col
        span={12}
        className="d-flex align-items-end "
        style={{ color: "#0066CC" }}
      >
        <u>
          {" "}
          <a
            onClick={() => {
              props.handleCancel();
            }}
          >
            {t("CardMoreButton")}
          </a>
        </u>
      </Col>
      <Col span={12} className="d-flex justify-content-end">
        <Button
          className="border-radius-5 px-5"
          type="primary"
          onClick={() => {
            props.handleCancel();
            navigate("/checkout");
          }}
        >
          {t("CartOrderProceed")}
        </Button>
      </Col>
    </Row>
  );
};

export default CartOrderButton;
